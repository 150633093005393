import { useBoolean } from 'react-use'
import { useCallback, useEffect } from 'react'

export const useProfitLossTableCollapseState = () => {
  const [showIncome, toggleShowIncome] = useBoolean(false)
  const [showCostOfSales, toggleShowCostOfSales] = useBoolean(false)
  const [showOperatingCost, toggleShowOperatingCost] = useBoolean(false)
  const [showOtherIncome, toggleShowOtherIncome] = useBoolean(false)
  const [showOtherExpenses, toggleShowOtherExpenses] = useBoolean(false)
  const [uncollapseAll, toggleUncollapseAll] = useBoolean(true)

  const onUncollapseAll = useCallback(() => {
    toggleShowIncome(true)
    toggleShowCostOfSales(true)
    toggleShowOperatingCost(true)
    toggleShowOtherIncome(true)
    toggleShowOtherExpenses(true)
  }, [
    toggleShowIncome,
    toggleShowCostOfSales,
    toggleShowOperatingCost,
    toggleShowOtherIncome,
    toggleShowOtherExpenses,
  ])

  const onCollapseAll = useCallback(() => {
    toggleShowIncome(false)
    toggleShowCostOfSales(false)
    toggleShowOperatingCost(false)
    toggleShowOtherIncome(false)
    toggleShowOtherExpenses(false)
  }, [
    toggleShowIncome,
    toggleShowCostOfSales,
    toggleShowOperatingCost,
    toggleShowOtherIncome,
    toggleShowOtherExpenses,
  ])

  useEffect(() => {
    ;[
      showIncome,
      showCostOfSales,
      showOperatingCost,
      showOtherIncome,
      showOtherExpenses,
    ].some(Boolean)
      ? toggleUncollapseAll(false)
      : toggleUncollapseAll(true)
  }, [
    toggleUncollapseAll,
    showIncome,
    showCostOfSales,
    showOperatingCost,
    showOtherIncome,
    showOtherExpenses,
  ])

  return [
    {
      showIncome,
      showCostOfSales,
      showOperatingCost,
      showOtherIncome,
      showOtherExpenses,
      uncollapseAll,
    },
    {
      toggleShowIncome,
      toggleShowCostOfSales,
      toggleShowOperatingCost,
      toggleShowOtherIncome,
      toggleShowOtherExpenses,
      toggleUncollapseAll,
      onUncollapseAll,
      onCollapseAll,
    },
  ] as const
}
