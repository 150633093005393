import type { MotionBoxProps } from './Motion'
import { MotionBox } from './Motion'
import type { IconProps } from '@chakra-ui/react'
import { Icon, useColorModeValue } from '@chakra-ui/react'
import { FaAngleRight } from 'react-icons/fa'
import React from 'react'

export const CollapseArrow = ({
  open,
  iconProps,
  ...props
}: MotionBoxProps & { open: boolean; iconProps?: IconProps }) => {
  const blue = useColorModeValue('blue.500', 'blue.300')
  return (
    <MotionBox animate={{ rotate: open ? 90 : 0 }} {...props}>
      <Icon as={FaAngleRight} color={blue} h={4} cursor="pointer" {...iconProps} />
    </MotionBox>
  )
}
