import { buildAuthContext } from '@liveflow-io/provider-auth'
import { AuthService } from 'services'
import { buildUrqlProvider } from 'packlets/providers'
import { PersistenceService } from 'packlets/services'

export const {
  AuthProvider,
  useIsLoggedIn,
  useAccessToken,
  useAuth,
  useIdToken,
  useProfile,
} = buildAuthContext(AuthService)

export const UrqlProvider = buildUrqlProvider({
  useAccessToken,
  onError: (error) => {
    if (error.response?.status === 401) {
      AuthService.logout()
      return
    }
    PersistenceService.clearAll()
  },
})
