import React, { Suspense } from 'react'
import { useQuery } from 'urql'
import { HasIntegrations_UserCompanyInfoDocument } from './documents.generated'
import { usePendingNativeAccountingIntegrations } from 'packlets/hooks'
import { useInterval } from 'react-use'
import { isValidResult } from '@liveflow-io/utils-common'
import { GenericError } from '@liveflow-io/component-common'
import { Skeleton } from '@chakra-ui/react'
import { PendingIntegrations } from './PendingIntegrations'
import { NoAccountancyIntegrations } from './NoAccountancyIntegrations'

export const HasIntegrations: React.FC = ({ children }) => {
  const [meResult, refetchMe] = useQuery({
    query: HasIntegrations_UserCompanyInfoDocument,
  })

  const [
    allPendingAccountingIntegrationsResult,
  ] = usePendingNativeAccountingIntegrations()

  useInterval(() => {
    refetchMe({
      requestPolicy: 'network-only',
    })
  }, 5000)

  if (
    !isValidResult(meResult) ||
    !isValidResult(allPendingAccountingIntegrationsResult)
  ) {
    return <GenericError />
  }

  const hasAccountingIntegrations =
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    (meResult.data.me?.company?.companyIntegrationsInfo
      .countOfNativeAccountIntegrations ?? 0) > 0

  const hasPendingIntegrations =
    allPendingAccountingIntegrationsResult.data.integrations.length > 0

  if (hasAccountingIntegrations) {
    return <Suspense fallback={<Skeleton h="600px" />}>{children}</Suspense>
  }
  if (hasPendingIntegrations) {
    return <PendingIntegrations />
  }
  return <NoAccountancyIntegrations />
}
