import type { DateRange } from 'packlets/atoms'
import { persistedDateRangeAtom, persistedJsonAtom } from 'packlets/atoms'
import { utcDayJs } from '@liveflow-io/utils-common'
import {
  FIRST_DATE_RANGE,
  ProfitLossPerDepartmentPersistence,
  SECOND_DATE_RANGE,
} from './persistence'
import { atomFamily } from 'jotai/utils'

const selectedRangeAtomFamily = atomFamily(
  ({ familyName, initialRange }: { familyName: string; initialRange: DateRange }) => {
    return persistedDateRangeAtom(
      ProfitLossPerDepartmentPersistence.periodDateRange.getKey(familyName),
      initialRange,
    )
  },
  (a, b) => a.familyName === b.familyName,
)

export const selectedDepartmentIdAtom = persistedJsonAtom(
  ProfitLossPerDepartmentPersistence.DEPARTMENT_SELECT,
  '',
)

export const selectedCurrencyAtom = persistedJsonAtom(
  ProfitLossPerDepartmentPersistence.CURRENCY_SELECT,
  'USD',
)

const now = utcDayJs()

export const firstDateRangeAtom = selectedRangeAtomFamily({
  familyName: FIRST_DATE_RANGE,
  initialRange: [
    now.subtract(1, 'month').startOf('month'),
    now.subtract(1, 'month').endOf('month'),
  ],
})

export const secondDateRangeAtom = selectedRangeAtomFamily({
  familyName: SECOND_DATE_RANGE,
  initialRange: [now.startOf('month'), now.endOf('month')],
})
