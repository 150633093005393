import type { CommonIntegrations_IntegrationPayloadQuery } from './IntegrationsQuery.generated'
import { CommonIntegrations_IntegrationPayloadDocument } from './IntegrationsQuery.generated'
import type { UseQueryArgs } from 'urql'
import { useQuery } from 'urql'

export type {
  CommonIntegrations_CommonAccountingIntegrationPayloadFragment,
  CommonIntegrations_IntegrationPayloadQuery,
  CommonIntegrations_IntegrationPayloadQueryVariables,
  CommonIntegrations_IntegrationPayloadFragment,
  CommonIntegrations_IntegrationPayloadUnionFragment,
} from './IntegrationsQuery.generated'

export const useIntegrationsQuery = (
  options?: Omit<UseQueryArgs<{}, CommonIntegrations_IntegrationPayloadQuery>, 'query'>,
) =>
  useQuery({
    query: CommonIntegrations_IntegrationPayloadDocument,
    ...(options ?? {}),
  })
