import type { UseRadioProps } from '@chakra-ui/react'
import {
  Box,
  chakra,
  Text,
  useRadio,
  useColorModeValue,
  useId,
  HStack,
} from '@chakra-ui/react'
import * as React from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'

const RadioBox = chakra('div', {
  baseStyle: {
    borderWidth: '2px',
    px: '4',
    py: '3',
    borderRadius: 'md',
    cursor: 'pointer',
    transition: 'all 0.2s',
    _focus: { shadow: 'outline' },
  },
})

const CheckboxIcon = (props: { checked: boolean }) => {
  const uncheckedColor = useColorModeValue('gray.300', 'whiteAlpha.400')
  return (
    <Box fontSize="2xl" color={props.checked ? 'blue.600' : uncheckedColor}>
      {props.checked ? <MdRadioButtonChecked /> : <MdRadioButtonUnchecked />}
    </Box>
  )
}

interface ButtonRadioProps extends UseRadioProps {
  icon: React.ReactElement
  title: string
  rightSide?: string
  description?: string
}

export const ButtonRadio = (props: ButtonRadioProps) => {
  const { description, title, icon, rightSide } = props
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useRadio(props)
  const id = useId()

  const checkedStyles = {
    bg: useColorModeValue('gray.50', 'whiteAlpha.100'),
    borderColor: useColorModeValue('blue.500', 'blue.300'),
  }

  return (
    <label style={{ width: '100%' }} {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox {...getCheckboxProps()} _checked={checkedStyles} id={id}>
        <HStack spacing="4">
          <Box
            data-checked={state.isChecked ? '' : undefined}
            fontSize="2xl"
            _checked={{
              color: useColorModeValue('blue.500', 'blue.300'),
            }}
            color={useColorModeValue('gray.300', 'whiteAlpha.400')}
          >
            <CheckboxIcon checked={state.isChecked} />
          </Box>
          <Box fontSize="3xl">{icon}</Box>
          <Box flex="1">
            <Text fontWeight="bold">{title}</Text>
            {isNotEmptyOrNullish(description) && <Text fontSize="sm">{description}</Text>}
          </Box>
          <Box fontWeight="bold" color={useColorModeValue('blue.600', 'blue.400')}>
            {rightSide}
          </Box>
        </HStack>
      </RadioBox>
    </label>
  )
}
