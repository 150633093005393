import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useToast, useEnhancedMutation } from '@liveflow-io/hooks-common'
import { TrackingService, INTEGRATION_EVENTS } from 'packlets/services'
import { impossibleState, isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { Routes } from 'packlets/constants'
import { QuickBookRedirect_UrlDocument } from './documents.generated'
import { useNavigateBackToWhereFlowInitiated } from 'packlets/hooks'

export const QuickBooksRedirect = () => {
  const [, integrate] = useEnhancedMutation(QuickBookRedirect_UrlDocument)
  const navigateBackToWhereFlowInitiatedOrFallback = useNavigateBackToWhereFlowInitiated()

  const toast = useToast()
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    const state = urlParams.get('state')
    const realmId = urlParams.get('realmId')
    if (
      isNotEmptyOrNullish(code) &&
      isNotEmptyOrNullish(state) &&
      isNotEmptyOrNullish(realmId)
    ) {
      integrate({
        input: {
          code,
          state,
          realmId,
        },
      })
        .then((result) => {
          switch (result.state) {
            case 'error':
            case 'partial':
              toast({
                status: 'error',
                title: 'Oh no, something went wrong!',
                description: 'Unfortunately Quickbooks is not integrated! Try again.',
              })
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_SUCCESS)
              navigateBackToWhereFlowInitiatedOrFallback(Routes.BANK_DASHBOARD)
              break
            case 'done':
              toast({
                title: 'Integrated succesfully!',
                description: 'Your Quickbooks account got integrated!',
              })
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_FAILED)
              navigateBackToWhereFlowInitiatedOrFallback(Routes.INTEGRATIONS)
              break
            default:
              impossibleState(result)
          }
          return result
        })
        .catch(console.error)
    } else {
      TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_FAILED)
      toast({
        status: 'error',
        title: 'Oh no, something went wrong!',
        description: 'Query params were empty!',
      })
      navigateBackToWhereFlowInitiatedOrFallback(Routes.INTEGRATIONS)
    }
  }, [navigateBackToWhereFlowInitiatedOrFallback, integrate, toast])

  return (
    <Flex justifyContent="center" alignItems="center" w="100vw" h="100vh">
      <Spinner size="xl" />
    </Flex>
  )
}
