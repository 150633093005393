import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { upperFirst } from 'lodash'

const LIVEFLOW_PREFIX = 'liveflow'

export const PersistenceService = {
  keys: [] as string[],
  set<T = unknown>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get<T = unknown>(key: string): T | undefined {
    const item = localStorage.getItem(key)
    return isNotEmptyOrNullish(item) ? (JSON.parse(item) as T) : undefined
  },
  clearAll() {
    setTimeout(() => {
      this.keys.forEach((it) => localStorage.removeItem(it))
    }, 0)
  },
  buildPersistenceService<Keys extends { [key: string]: string }, Ext extends object>(
    prefix: string,
    keys: Keys,
    extension: Ext = {} as Ext,
  ) {
    const keyValues = Object.values(keys)
    this.__registerKeys(...keyValues)
    return {
      clearAll: this.clearAll,
      keys: keyValues,
      ...this.__createGetters(prefix, keys),
      ...this.__createSetters(prefix, keys),
      ...keys,
      ...extension,
    }
  },

  __createGetters<Namespace extends string, TObj extends Record<string, string>>(
    namespace: Namespace,
    obj: TObj,
  ): PropGetters<Namespace, TObj> {
    const newObj: any = {}
    for (const key of Object.values(obj)) {
      const pascalCase = key
        .split(`${LIVEFLOW_PREFIX}.${namespace}`)[1]
        .split('.')
        .map((it) => upperFirst(it))
        .join('')
      const getterKey = `get${pascalCase}`
      newObj[getterKey] = <T = unknown>() => this.get<T>(key)
    }
    return newObj
  },

  __createSetters<Namespace extends string, TObj extends Record<string, string>>(
    namespace: Namespace,
    obj: TObj,
  ): PropSetters<Namespace, TObj> {
    const newObj: any = {}
    for (const key of Object.values(obj)) {
      const pascalCase = key
        .split(`${LIVEFLOW_PREFIX}.${namespace}`)[1]
        .split('.')
        .map((it) => upperFirst(it))
        .join('')
      const setterKey = `set${pascalCase}`
      newObj[setterKey] = <T = never>(value: T) => this.set<T>(key, value)
    }
    return newObj
  },

  __registerKeys(...keys: string[]) {
    const merged = this.keys.concat(keys)
    const filteredDuplicates = new Set(merged)
    if (merged.length !== filteredDuplicates.size) {
      throw Error('There is registered keys conflict!')
    }
    this.keys = merged
  },
}

type RemoveNamespace<
  Namespace extends string,
  S extends string
> = S extends `liveflow.${Namespace}.${infer Rest}` ? Rest : S

type DotCaseToPascalCase<S extends string> = S extends `${infer FirstWord}.${infer Rest}`
  ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
    `${Capitalize<FirstWord>}${DotCaseToPascalCase<Rest>}`
  : Capitalize<S>

type PropGetters<Namespace extends string, TObj extends Record<string, string>> = {
  [TKey in string & keyof TObj as `get${DotCaseToPascalCase<
    RemoveNamespace<Namespace, TObj[TKey]>
  >}`]: <T>() => T | undefined
}

type PropSetters<Namespace extends string, TObj extends Record<string, string>> = {
  [TKey in string & keyof TObj as `set${DotCaseToPascalCase<
    RemoveNamespace<Namespace, TObj[TKey]>
  >}`]: <T>(value: T) => void
}

type LiveflowPrefixedKey<
  Prefix extends string,
  Key extends string
> = `${typeof LIVEFLOW_PREFIX}.${Prefix}.${Key}`

type LiveflowPrefixedKeyWithFamily<
  Prefix extends string,
  Key extends string,
  Family extends string
> = `${typeof LIVEFLOW_PREFIX}.${Prefix}.${Key}.${Family}`

interface BuildNamespacedKeyBuilder {
  <Prefix extends string>(prefix: Prefix): <Key extends string>(
    key: Key,
  ) => LiveflowPrefixedKey<Prefix, Key>
}

export const buildNamespacedKeyBuilder: BuildNamespacedKeyBuilder = (prefix) => (key) =>
  `${LIVEFLOW_PREFIX}.${prefix}.${key}` as LiveflowPrefixedKey<typeof prefix, typeof key>

export const buildAtomFamilyKeyBuilder = <
  Namespace extends string,
  Family extends string
>(
  namespace: Namespace,
  family: Family,
) => <Key extends string>(key: Key) =>
  `${LIVEFLOW_PREFIX}.${namespace}.${key}.${family}` as LiveflowPrefixedKeyWithFamily<
    Namespace,
    Key,
    Family
  >

export const buildFamilyGettersAndSetters = <T = never>(
  familyKeyBuilder: (key: string) => string,
) => ({
  getKey: (key: string) => familyKeyBuilder(key),
  get: (key: string) => {
    return PersistenceService.get<T>(familyKeyBuilder(key))
  },
  set: (key: string, value: T) => {
    PersistenceService.set<T>(familyKeyBuilder(key), value)
  },
})
