import {
  buildAtomFamilyKeyBuilder,
  buildFamilyGettersAndSetters,
  buildNamespacedKeyBuilder,
  PersistenceService,
} from 'packlets/services'
import type { AccountsSelected, AccountsSelectorState } from './atoms'
import {
  BALANCE_HISTORY_FAMILY,
  BANK_ACCOUNTS_SECTION_FAMILY,
  TOTAL_BALANCE_FAMILY,
} from './atomFamilies'

const BANK_DASHBOARD_NAMESPACE = 'bankDashboard'
const namespacedKeyBuilder = buildNamespacedKeyBuilder(BANK_DASHBOARD_NAMESPACE)

const CURRENCY_SELECT = 'currencySelect'

// AccountsSelector
const ACCOUNTS_SELECTED = 'accountsSelected'
const accountsSelectedAtomFamilyKeyBuilder = buildAtomFamilyKeyBuilder(
  BANK_DASHBOARD_NAMESPACE,
  ACCOUNTS_SELECTED,
)
const ACCOUNTS_SELECTED_STATE = 'accountsSelectorState'
const accountsSelectorStateAtomFamilyKeyBuilder = buildAtomFamilyKeyBuilder(
  BANK_DASHBOARD_NAMESPACE,
  ACCOUNTS_SELECTED_STATE,
)
// AccountRadioSelector
const RADIO_BUTTON_GROUP_SELECTED_ACCOUNT = 'radioButtonGroupAccountSelected'
const BALANCE_HISTORY_RANGE = 'balanceHistoryDateRange'

const keys = {
  CURRENCY_SELECT: namespacedKeyBuilder(CURRENCY_SELECT),
  BANK_ACCOUNTS_ACCOUNTS_SELECTED: accountsSelectedAtomFamilyKeyBuilder(
    BANK_ACCOUNTS_SECTION_FAMILY,
  ),
  BANK_ACCOUNTS_ACCOUNTS_SELECTED_STATE: accountsSelectorStateAtomFamilyKeyBuilder(
    BANK_ACCOUNTS_SECTION_FAMILY,
  ),
  TOTAL_BALANCE_ACCOUNTS_SELECTED: accountsSelectedAtomFamilyKeyBuilder(
    TOTAL_BALANCE_FAMILY,
  ),
  TOTAL_BALANCE_ACCOUNTS_SELECTED_STATE: accountsSelectorStateAtomFamilyKeyBuilder(
    TOTAL_BALANCE_FAMILY,
  ),
  BALANCE_HISTORY_ACCOUNTS_SELECTED_STATE: accountsSelectorStateAtomFamilyKeyBuilder(
    BALANCE_HISTORY_FAMILY,
  ),
  RADIO_BUTTON_GROUP_SELECTED_ACCOUNT: namespacedKeyBuilder(
    RADIO_BUTTON_GROUP_SELECTED_ACCOUNT,
  ),
  BALANCE_HISTORY_RANGE: namespacedKeyBuilder(BALANCE_HISTORY_RANGE),
}

export const BankDashboardPersistence = PersistenceService.buildPersistenceService(
  BANK_DASHBOARD_NAMESPACE,
  keys,
  {
    accountsSelected: buildFamilyGettersAndSetters<AccountsSelected>(
      accountsSelectedAtomFamilyKeyBuilder,
    ),
    accountsSelectorState: buildFamilyGettersAndSetters<AccountsSelectorState>(
      accountsSelectorStateAtomFamilyKeyBuilder,
    ),
  },
)
