import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  Stack,
  Text,
  Th,
} from '@chakra-ui/react'
import { isNotEmptyOrNullish, isNotNullish } from '@liveflow-io/utils-common'
import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import { CONSOLIDATED_PNL_EVENTS, TrackingService } from 'packlets/services'
import { useCompletedNativeAccountingIntegrations } from 'packlets/hooks'
import { downloadCsv } from 'packlets/utils'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import {
  ATOM_FAMILY,
  consolidatedEntitiesStateAtom,
  selectedCurrencyAtom,
  selectedRangeAtom,
} from './atoms'
import { DownloadIcon } from '@chakra-ui/icons'
import { useAtom } from 'jotai'
import {
  HasIntegrations,
  ProfitLossMonthRangePicker,
  ProfitLossShell,
  ProfitLossTable,
} from '../components'
import { csvButtonState } from '../atoms'
import { SELECT_CURRENCIES } from 'packlets/constants'
import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from 'packlets/queries'
import { useHandleProfitLossQueriesChange } from './hooks'
import { useHandleConnectedToInfoRendering } from '../hooks'
import { SelectEntityButton } from './SelectEntityButton'
import { ProfitLossConsolidatedEntitiesPersistence } from './persistence'

export const ConsolidatedEntitiesProfitLoss = () => {
  return (
    <ProfitLossShell
      heading="P&L - All entities"
      persistenceService={ProfitLossConsolidatedEntitiesPersistence}
    >
      <Suspense fallback={<Skeleton height="full" />}>
        <HasIntegrations>
          <ConsolidatedEntities />
        </HasIntegrations>
      </Suspense>
    </ProfitLossShell>
  )
}

const ConsolidatedEntities = () => {
  const csvData = useAtomValue(csvButtonState)

  const [nativeIntegrationsResult] = useCompletedNativeAccountingIntegrations()
  const nativeIntegrations = nativeIntegrationsResult.data?.integrations
  useHandleConnectedToInfoRendering(
    nativeIntegrations as
      | CommonIntegrations_CommonAccountingIntegrationPayloadFragment[]
      | undefined,
  )

  const [selectedRange, setRange] = useAtom(selectedRangeAtom)
  const [selectedCurrency, setCurrency] = useAtom(selectedCurrencyAtom)

  const [{ selectedEntities }] = useAtom(consolidatedEntitiesStateAtom)
  useHandleProfitLossQueriesChange(selectedEntities, selectedRange, selectedCurrency)

  const onCurrencyChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      TrackingService.track(CONSOLIDATED_PNL_EVENTS.CURRENCY_CHANGED)
      void setCurrency(e.target.value)
    },
    [setCurrency],
  )

  const csvHeaders = useMemo(() => {
    return [
      '',
      ...(selectedEntities?.map((it) => it.name) ?? []),
      isNotEmptyOrNullish(selectedEntities) && 'Consolidated',
    ].filter((it): it is string => isNotNullish(it))
  }, [selectedEntities])

  const setCsvButtonState = useUpdateAtom(csvButtonState)

  useEffect(() => {
    void setCsvButtonState((it) => ({
      ...it,
      headers: csvHeaders,
    }))
  }, [csvHeaders, setCsvButtonState])

  return (
    <Stack spacing={8}>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
          <Stack w={300}>
            <Text>Change period:</Text>
            <ProfitLossMonthRangePicker
              borderRadius={8}
              size="large"
              value={selectedRange}
              // @ts-expect-error
              onChange={([from, to]) => {
                TrackingService.track(CONSOLIDATED_PNL_EVENTS.DATE_FILTER_CHANGED)
                isNotNullish(from) &&
                  isNotNullish(to) &&
                  setRange([from.startOf('month'), to.endOf('month')])
              }}
            />
          </Stack>
          {nativeIntegrations && (
            <SelectEntityButton
              familyId={ATOM_FAMILY}
              integrations={nativeIntegrations}
            />
          )}
        </Stack>
        <Stack direction="row" alignItems="flex-end">
          <FormControl id="consolidated-currency" w={300}>
            <FormLabel>Consolidated currency:</FormLabel>
            <Select value={selectedCurrency} onChange={onCurrencyChange}>
              {SELECT_CURRENCIES.map((it) => {
                return (
                  <option key={it} value={it}>
                    {it}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <Button
            leftIcon={<DownloadIcon />}
            onClick={() => {
              downloadCsv({
                ...csvData,
                showLabels: true,
                filename: 'Profit and Loss',
              })
            }}
          >
            Export to CSV/Excel
          </Button>
        </Stack>
      </Stack>
      {isNotEmptyOrNullish(nativeIntegrations) && (
        <Suspense fallback={<Skeleton h="400px" />}>
          <ProfitLossTable
            header={
              <>
                {selectedEntities?.map((it) => (
                  <Th key={it.integrationId}>{it.name}</Th>
                ))}
                {isNotEmptyOrNullish(selectedEntities) && <Th>Consolidated</Th>}
              </>
            }
            familyId={ATOM_FAMILY}
          />
        </Suspense>
      )}
    </Stack>
  )
}
