import React from 'react'
import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import { UserPreferences_EditUserSettingsDocument } from './documents.generated'
import { useMe } from 'packlets/hooks'
import { useRef } from 'react'
import { useSetState, useUpdateEffect } from 'react-use'
import { Card } from '@liveflow-io/component-common'
import { Stack, StackDivider } from '@chakra-ui/react'
import { SettingSwitch } from './SettingSwitch'
import { CardHeading } from './Settings'

export const UserPreferences = () => {
  const [, editUserSettings] = useEnhancedMutation(
    UserPreferences_EditUserSettingsDocument,
  )
  const me = useMe()
  const toast = useToast()
  const initialValue = useRef(me.settings)

  // TODO: Replace with optimistic response
  const [userSettings, setUserSettings] = useSetState(me.settings)

  useUpdateEffect(() => {
    editUserSettings({
      input: userSettings,
    })
      .then((res) => {
        if (res.state === 'error' || res.state === 'partial') {
          toast({
            status: 'error',
            title: 'Oh no!',
            description: 'Something went wrong when changing settings',
          })
          // TODO: Replace with optimistic response
          userSettings.isReducedMotion !== initialValue.current.isReducedMotion &&
            setUserSettings(initialValue.current)
        } else {
          initialValue.current = { ...userSettings }
        }
        return res
      })
      .catch(console.error)
  }, [editUserSettings, toast, userSettings])

  return (
    <Card maxW="3xl" w={{ base: 'full', lg: 'lg' }}>
      <CardHeading title="User preferences">
        Modify look and feel of the application to your taste
      </CardHeading>
      <Stack mt={8} spacing="4" divider={<StackDivider />}>
        <SettingSwitch
          isChecked={userSettings.isReducedMotion}
          onChange={(e) => {
            setUserSettings({ isReducedMotion: e.target.value !== 'true' })
          }}
          id="isReducedMotion_checkbox"
          name="isReduceMotion"
          title="Use reduced motion"
          value={userSettings.isReducedMotion.toString()}
        >
          Disables or minimizes animations in the user interface. Prefers reduced motion
          in your OS settings, will override this.
        </SettingSwitch>
      </Stack>
    </Card>
  )
}
