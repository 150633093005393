import { useReducedMotion as useFramerReducedMotion } from 'framer-motion'
import type { CompanyIntegrationsInfo } from '../generated'
import { useContext } from 'react'
import type { MeProvider_UserFragment } from '../providers'
import { MeContext } from '../providers'

/**
 * Make sure to use only inside SecuredApp tree NEVER OUTSIDE, if outside make a request to a server
 */
export const useMe = (): MeProvider_UserFragment => {
  const { me } = useContext(MeContext)
  return me
}
/**
 * Make sure to use only inside SecuredApp tree NEVER OUTSIDE, if outside make a request to a server
 */
export const useReducedMotion = (): boolean => {
  const me = useMe()
  const isReduceMotionSystemSetting = useFramerReducedMotion()
  if (isReduceMotionSystemSetting) {
    return isReduceMotionSystemSetting
  }
  return me.settings.isReducedMotion
}

type UseCompanyIntegrationsInfo = Omit<CompanyIntegrationsInfo, '__typename'>

/**
 * Make sure to use only inside SecuredApp tree NEVER OUTSIDE, if outside make a request to a server
 */
export const useCompanyIntegrationsInfo = (): UseCompanyIntegrationsInfo => {
  const me = useMe()
  if (me.company) {
    return me.company.companyIntegrationsInfo
  }
  return {
    countOfAccountancyIntegrations: 0,
    countOfNativeAccountIntegrations: 0,
    hasAnyBankIntegration: false,
  }
}
