/* eslint-disable */
// ####################################+################################################
// ################# THIS IS GENERATED FILE DO NOT TOUCH!!!!!!!!!!!!####################
// ####################################-################################################
import type {
  UUID,
  DateTime,
  Time,
  JSON,
  Timestamp,
  Email,
  PhoneNumber,
  URL,
  Any,
  GraphQLDate,
} from 'packlets/graphql/scalars'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An RFC-3339 compliant Full Date Scalar */
  Date: GraphQLDate
  /** A type representing a formatted io.liveflow.types.Email */
  Email: Email
  /** A JSON scalar */
  JSON: JSON
  /** Returns timestamp */
  Timestamp: Timestamp
  /** A type representing a formatted java.util.UUID */
  UUID: UUID
}

export type Bank = {
  __typename?: 'Bank'
  accounts: Array<BankAccount>
  integration: CommonIntegrationPayload
  name: Scalars['String']
  totalBalance: Money
}

export type BankAccount = {
  __typename?: 'BankAccount'
  balance: Money
  exchangedBalance: Money
  id: Scalars['UUID']
  name?: Maybe<Scalars['String']>
  synchronisedDate: Scalars['Timestamp']
  type: BankAccountType
}

export enum BankAccountType {
  Brokerage = 'BROKERAGE',
  Credit = 'CREDIT',
  Depository = 'DEPOSITORY',
  Investment = 'INVESTMENT',
  Loan = 'LOAN',
  Other = 'OTHER',
}

export type BankDashboardBurnRateFiltersInput = {
  accountIds: Array<Scalars['UUID']>
  range: MonthRangeFilterInput
}

export type BankDashboardBurnRateInput = {
  currency: Scalars['String']
  filters: BankDashboardBurnRateFiltersInput
}

export type BankDashboardFiltersInput = {
  accountIds: Array<Scalars['UUID']>
  range: RangeFilterInput
}

export type BankDashboardInput = {
  currency: Scalars['String']
  filters: BankDashboardFiltersInput
}

export type BankDashboardRunwayFiltersInput = {
  accountIds: Array<Scalars['UUID']>
  periodInMonth: Scalars['Int']
}

export type BankDashboardRunwayInput = {
  currency: Scalars['String']
  filters: BankDashboardRunwayFiltersInput
}

export type BankIntegrationPayload = {
  balance: Scalars['Float']
  bank: Scalars['String']
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  name: Scalars['String']
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type BanksFiltersInput = {
  accountIds?: Maybe<Array<Scalars['UUID']>>
}

export type BanksInput = {
  currency: Scalars['String']
  filters: BanksFiltersInput
}

export type BurnRateDashboardSection = {
  __typename?: 'BurnRateDashboardSection'
  history: Array<MonthMoneyHistoryPoint>
  value: Money
}

export type CommonAccountingIntegrationPayload = IntegrationPayload & {
  __typename?: 'CommonAccountingIntegrationPayload'
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  name: Scalars['String']
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type CommonBankIntegrationPayload = BankIntegrationPayload &
  IntegrationPayload & {
    __typename?: 'CommonBankIntegrationPayload'
    balance: Scalars['Float']
    bank: Scalars['String']
    integrationId: Scalars['UUID']
    integrationSource: IntegrationSource
    name: Scalars['String']
    problems?: Maybe<Array<IntegrationProblem>>
    status: IntegrationStatus
  }

export type CommonIntegrationPayload = IntegrationPayload & {
  __typename?: 'CommonIntegrationPayload'
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  name: Scalars['String']
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type Company = {
  __typename?: 'Company'
  city?: Maybe<Scalars['String']>
  companyIntegrationsInfo: CompanyIntegrationsInfo
  country: Scalars['String']
  createdDate: Scalars['Timestamp']
  email: Scalars['String']
  id: Scalars['UUID']
  name: Scalars['String']
  postCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetLine1?: Maybe<Scalars['String']>
  streetLine2?: Maybe<Scalars['String']>
  updatedDate: Scalars['Timestamp']
}

export type CompanyInputValidationProblem = {
  __typename?: 'CompanyInputValidationProblem'
  country?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
}

export type CompanyIntegrationsInfo = {
  __typename?: 'CompanyIntegrationsInfo'
  countOfAccountancyIntegrations: Scalars['Int']
  countOfNativeAccountIntegrations: Scalars['Int']
  hasAnyBankIntegration: Scalars['Boolean']
}

export type CreateCompanyInput = {
  city?: Maybe<Scalars['String']>
  country: Scalars['String']
  name: Scalars['String']
  postCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetLine1?: Maybe<Scalars['String']>
  streetLine2?: Maybe<Scalars['String']>
}

export type CreateCompanyPayload = {
  __typename?: 'CreateCompanyPayload'
  problem?: Maybe<CompanyInputValidationProblem>
  result?: Maybe<CreateCompanyResult>
}

export type CreateCompanyResult = {
  __typename?: 'CreateCompanyResult'
  company?: Maybe<Company>
  message?: Maybe<Scalars['String']>
  user: User
}

export type CreateUserInput = {
  firstName: Scalars['String']
  inviteCode?: Maybe<Scalars['String']>
}

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  result: CreateUserResult
}

export type CreateUserResult = {
  __typename?: 'CreateUserResult'
  message: Scalars['String']
  user: User
}

export type DashboardSection = {
  __typename?: 'DashboardSection'
  history: Array<MoneyHistoryPoint>
  value: Money
}

export type DateRange = {
  __typename?: 'DateRange'
  from: Scalars['Date']
  to: Scalars['Date']
}

export type DeleteUserInput = {
  userId: Scalars['UUID']
}

export type DeleteUserInviteInput = {
  inviteId: Scalars['UUID']
}

export type EditCompanyInput = {
  city?: Maybe<Scalars['String']>
  country: Scalars['String']
  email: Scalars['String']
  name: Scalars['String']
  postCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  streetLine1?: Maybe<Scalars['String']>
  streetLine2?: Maybe<Scalars['String']>
}

export type EditCompanyPayload = {
  __typename?: 'EditCompanyPayload'
  problem?: Maybe<EditCompanyProblemsType>
  result?: Maybe<Company>
}

export type EditCompanyProblemsType = CompanyInputValidationProblem

export type IntegrationPayload = {
  integrationId: Scalars['UUID']
  integrationSource: IntegrationSource
  name: Scalars['String']
  problems?: Maybe<Array<IntegrationProblem>>
  status: IntegrationStatus
}

export type IntegrationPayloadUnion =
  | CommonAccountingIntegrationPayload
  | CommonBankIntegrationPayload
  | CommonIntegrationPayload
  | PlaidIntegrationPayload

export type IntegrationProblem = PlaidIntegrationProblem

export type IntegrationRenameInput = {
  name: Scalars['String']
}

export enum IntegrationSource {
  Codat = 'CODAT',
  NetSuite = 'NET_SUITE',
  Plaid = 'PLAID',
  QuickBooks = 'QUICK_BOOKS',
  Shopify = 'SHOPIFY',
  TrueLayer = 'TRUE_LAYER',
  Xero = 'XERO',
}

export enum IntegrationStatus {
  Completed = 'COMPLETED',
  DeletionInitiated = 'DELETION_INITIATED',
  Failed = 'FAILED',
  InitialDataLoaded = 'INITIAL_DATA_LOADED',
  Pending = 'PENDING',
  ReauthRequired = 'REAUTH_REQUIRED',
  Revoked = 'REVOKED',
}

export type InviteUserInput = {
  email: Scalars['Email']
}

export type InviteUserPayload = {
  __typename?: 'InviteUserPayload'
  inviteUrl?: Maybe<Scalars['String']>
  problem?: Maybe<InviteUserProblem>
}

export type InviteUserProblem = {
  __typename?: 'InviteUserProblem'
  problemCode: InviteUserProblemCode
}

export enum InviteUserProblemCode {
  UserInvited = 'USER_INVITED',
  UserOnboarded = 'USER_ONBOARDED',
}

export type InvitedUser = ResultUser & {
  __typename?: 'InvitedUser'
  email: Scalars['Email']
  expired: Scalars['Boolean']
  id: Scalars['UUID']
}

export type Money = {
  __typename?: 'Money'
  amount: Scalars['Float']
  currency: Scalars['String']
}

export type MoneyHistoryPoint = {
  __typename?: 'MoneyHistoryPoint'
  date: Scalars['Date']
  value: Money
}

export type MonthMoneyHistoryPoint = {
  __typename?: 'MonthMoneyHistoryPoint'
  date: Scalars['Date']
  value: Money
}

export type MonthRangeFilterInput = {
  from: Scalars['Date']
  to: Scalars['Date']
}

export type Mutation = {
  __typename?: 'Mutation'
  companyEdit: EditCompanyPayload
  deleteUser: Scalars['UUID']
  deleteUserInvite: Scalars['UUID']
  integrationDelete: IntegrationPayload
  integrationRename: IntegrationPayload
  /** Creates company for logged in user */
  onboardingCompanyCreate: CreateCompanyPayload
  /** Creates user for logged in user */
  onboardingUserCreate: CreateUserPayload
  /** Integrates bank into our backend with Plaid a.k.a /get_access_token from this guide: https://plaid.com/docs/quickstart/#how-it-works */
  plaidIntegrate: PlaidIntegrationPayload
  /** Generates Plaid Link token a.k.a /get_link_token from this guide: https://plaid.com/docs/quickstart/#how-it-works */
  plaidLinkTokenCreate: Scalars['String']
  plaidReAuthComplete: CommonIntegrationPayload
  plaidReAuthLinkTokenCreate: Scalars['String']
  quickBooksIntegrate: Scalars['String']
  quickBooksLinkCreate: Scalars['String']
  trueLayerIntegrate: TrueLayerIntegrationPayload
  /** create invite code */
  userAdministrationInviteUser: InviteUserPayload
  userSettingsEdit: User
  xeroIntegrate: Scalars['String']
  xeroLinkCreate: Scalars['String']
}

export type MutationCompanyEditArgs = {
  input: EditCompanyInput
}

export type MutationDeleteUserArgs = {
  input: DeleteUserInput
}

export type MutationDeleteUserInviteArgs = {
  input: DeleteUserInviteInput
}

export type MutationIntegrationDeleteArgs = {
  id: Scalars['UUID']
}

export type MutationIntegrationRenameArgs = {
  id: Scalars['UUID']
  input: IntegrationRenameInput
}

export type MutationOnboardingCompanyCreateArgs = {
  input: CreateCompanyInput
}

export type MutationOnboardingUserCreateArgs = {
  input: CreateUserInput
}

export type MutationPlaidIntegrateArgs = {
  input: PlaidIntegrateInput
}

export type MutationPlaidLinkTokenCreateArgs = {
  input: PlaidLinkTokenCreateInput
}

export type MutationPlaidReAuthCompleteArgs = {
  input: PlaidReAuthCompleteInput
}

export type MutationPlaidReAuthLinkTokenCreateArgs = {
  input: PlaidCreateReAuthenticationLinkInput
}

export type MutationQuickBooksIntegrateArgs = {
  input: QuickBooksIntegrateInput
}

export type MutationTrueLayerIntegrateArgs = {
  input: TrueLayerIntegrationInput
}

export type MutationUserAdministrationInviteUserArgs = {
  input: InviteUserInput
}

export type MutationUserSettingsEditArgs = {
  input: UserSettingsEditInput
}

export type MutationXeroIntegrateArgs = {
  input: XeroIntegrateInput
}

export type NewProfitLoss = {
  __typename?: 'NewProfitLoss'
  costOfSales: Array<ProfitLossItem>
  costOfSalesSum?: Maybe<Money>
  currencyCode: Scalars['String']
  grossMargin?: Maybe<Scalars['Float']>
  grossProfit?: Maybe<Money>
  income: Array<ProfitLossItem>
  incomeSum?: Maybe<Money>
  integrationIds: Array<Scalars['UUID']>
  netOtherIncome?: Maybe<Money>
  netProfit?: Maybe<Money>
  netProfitMargin?: Maybe<Scalars['Float']>
  operatingCost: Array<ProfitLossItem>
  operatingCostSum?: Maybe<Money>
  operatingProfit?: Maybe<Money>
  operatingProfitMargin?: Maybe<Scalars['Float']>
  otherExpenses: Array<ProfitLossItem>
  otherExpensesSum?: Maybe<Money>
  otherIncome: Array<ProfitLossItem>
  otherIncomeSum?: Maybe<Money>
  range: DateRange
}

export type NewProfitLossFiltersInput = {
  categoryIds?: Maybe<Array<Scalars['String']>>
  integrationIds?: Maybe<Array<Scalars['UUID']>>
}

export type NewProfitLossInput = {
  filter?: Maybe<NewProfitLossFiltersInput>
  range: RangeFilterInput
  selectedCurrency?: Maybe<Scalars['String']>
}

export type NumberHistoryPoint = {
  __typename?: 'NumberHistoryPoint'
  date: Scalars['Date']
  value: Scalars['Float']
}

export type OnboardedUser = ResultUser & {
  __typename?: 'OnboardedUser'
  avatarURL?: Maybe<Scalars['String']>
  email: Scalars['Email']
  id: Scalars['UUID']
  name?: Maybe<Scalars['String']>
}

export enum PlaidCountryCode {
  Ca = 'CA',
  Es = 'ES',
  Fr = 'FR',
  Gb = 'GB',
  Ie = 'IE',
  Nl = 'NL',
  Us = 'US',
}

export type PlaidCreateReAuthenticationLinkInput = {
  integrationId: Scalars['UUID']
}

export type PlaidIntegrateInput = {
  metadata: PlaidIntegrateInputMetadataInput
  publicToken: Scalars['String']
}

export type PlaidIntegrateInputAccountInput = {
  id?: Maybe<Scalars['String']>
  mask?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  subtype?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type PlaidIntegrateInputInstitutionInput = {
  institution_id: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type PlaidIntegrateInputMetadataInput = {
  account: PlaidIntegrateInputAccountInput
  account_id?: Maybe<Scalars['String']>
  accounts: Array<PlaidIntegrateInputAccountInput>
  institution?: Maybe<PlaidIntegrateInputInstitutionInput>
  link_session_id: Scalars['String']
  public_token: Scalars['String']
}

export type PlaidIntegrationPayload = BankIntegrationPayload &
  IntegrationPayload & {
    __typename?: 'PlaidIntegrationPayload'
    balance: Scalars['Float']
    bank: Scalars['String']
    integrationId: Scalars['UUID']
    integrationSource: IntegrationSource
    name: Scalars['String']
    problems?: Maybe<Array<PlaidIntegrationProblem>>
    status: IntegrationStatus
  }

export type PlaidIntegrationProblem = {
  __typename?: 'PlaidIntegrationProblem'
  institution: Scalars['JSON']
  message: Scalars['String']
}

export type PlaidLinkTokenCreateInput = {
  countryCode: PlaidCountryCode
}

export type PlaidReAuthCompleteInput = {
  publicToken: Scalars['String']
}

export type ProfitLossCategoriesFiltersInput = {
  integrationIds?: Maybe<Array<Scalars['UUID']>>
}

export type ProfitLossCategoriesInput = {
  filter?: Maybe<ProfitLossCategoriesFiltersInput>
}

export type ProfitLossItem = {
  __typename?: 'ProfitLossItem'
  name: Scalars['String']
  value: Money
}

export type Query = {
  __typename?: 'Query'
  balance: DashboardSection
  banks: Array<Bank>
  burnRate: BurnRateDashboardSection
  cashIn: DashboardSection
  cashOut: DashboardSection
  company: Company
  enabled: Scalars['Boolean']
  integrations: Array<IntegrationPayloadUnion>
  integrationsWithReAuthRequired: Array<CommonIntegrationPayload>
  me?: Maybe<User>
  newProfitLoss: NewProfitLoss
  profitLossCategories: Array<TrackingCategory>
  runway: RunwaySection
  subscription: Subscription
  userAdministrationUsers: Array<UserAdministrationUser>
}

export type QueryBalanceArgs = {
  input: BankDashboardInput
}

export type QueryBanksArgs = {
  input: BanksInput
}

export type QueryBurnRateArgs = {
  input: BankDashboardBurnRateInput
}

export type QueryCashInArgs = {
  input: BankDashboardInput
}

export type QueryCashOutArgs = {
  input: BankDashboardInput
}

export type QueryNewProfitLossArgs = {
  input: NewProfitLossInput
}

export type QueryProfitLossCategoriesArgs = {
  input?: Maybe<ProfitLossCategoriesInput>
}

export type QueryRunwayArgs = {
  input: BankDashboardRunwayInput
}

export type QuickBooksIntegrateInput = {
  code: Scalars['String']
  realmId: Scalars['String']
  state: Scalars['String']
}

export type RangeFilterInput = {
  from: Scalars['Date']
  to: Scalars['Date']
}

export type ResultUser = {
  email: Scalars['Email']
  id: Scalars['UUID']
}

export type RunwaySection = {
  __typename?: 'RunwaySection'
  forecast: Array<NumberHistoryPoint>
  months: Scalars['Float']
}

export type Subscription = {
  __typename?: 'Subscription'
  sessionId: Scalars['String']
}

export type TrackingCategory = {
  __typename?: 'TrackingCategory'
  id: Scalars['String']
  integrationId: Scalars['UUID']
  name: Scalars['String']
}

export type TrueLayerIntegrationInput = {
  authCode: Scalars['String']
}

export type TrueLayerIntegrationPayload = {
  __typename?: 'TrueLayerIntegrationPayload'
  status: IntegrationStatus
}

export type User = {
  __typename?: 'User'
  company?: Maybe<Company>
  createdDate: Scalars['Timestamp']
  email: Scalars['Email']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  idpUserId: Scalars['String']
  isOnboarded: Scalars['Boolean']
  settings: UserSettings
  updatedDate: Scalars['Timestamp']
}

export type UserAdministrationUser = InvitedUser | OnboardedUser

export type UserSettings = {
  __typename?: 'UserSettings'
  isReducedMotion: Scalars['Boolean']
}

export type UserSettingsEditInput = {
  isReducedMotion: Scalars['Boolean']
}

export type XeroIntegrateInput = {
  code: Scalars['String']
  state: Scalars['String']
}
