import type { Dayjs } from 'dayjs'
import { usePredefinedDateRanges } from '../hooks'
import { DateRangePicker } from 'packlets/components'
import React from 'react'
import { utcDayJs } from '@liveflow-io/utils-common'

const TWO_YEARS_RANGE = {
  startDate: utcDayJs().subtract(24, 'month').startOf('year'),
  endDate: utcDayJs(),
}
export const DashboardDatePicker = ({
  value,
  onChange,
}: {
  onChange: (value: [Dayjs, Dayjs]) => void
  value: [Dayjs, Dayjs]
}) => {
  const ranges = usePredefinedDateRanges(TWO_YEARS_RANGE)
  return (
    <DateRangePicker
      allowClear={false}
      value={value}
      size="large"
      borderRadius="4px"
      ranges={ranges as {}}
      // @ts-expect-error
      onChange={onChange}
    />
  )
}
