import { atom } from 'jotai'
import type React from 'react'

export const csvButtonState = atom<{
  headers: string[]
  data: object[]
}>({
  headers: [],
  data: [],
})
export const connectedToInfoRenderAtom = atom<React.ReactNode | null>(null)
