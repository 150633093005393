import {
  Badge,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import React, { useMemo } from 'react'
import type { Column } from 'react-table'
import { useTable } from 'react-table'
import type { UsersList_UserAdministrationUserFragment } from './documents.generated'
import type { ThemeTypings } from '@chakra-ui/styled-system'
import { impossibleState, isValidResult } from '@liveflow-io/utils-common'
import { UserCell } from './UserCell'
import { useQuery } from 'urql'
import { UsersList_UserDocument } from './documents.generated'
import { GenericEmpty, GenericError } from '@liveflow-io/component-common'
import { isEmpty } from 'lodash'
import { DeleteInviteOrUser, deleteInviteOrUserAtom } from './DeleteInviteOrUser'
import { useUpdateAtom } from 'jotai/utils'
import { useMe } from 'packlets/hooks'

const colorSchemeByStatus = (
  status: UsersList_UserAdministrationUserFragment['__typename'],
): ThemeTypings['colorSchemes'] => {
  switch (status) {
    case 'InvitedUser':
      return 'yellow'
    case 'OnboardedUser':
      return 'green'
    default:
      return impossibleState(status)
  }
}

export const UsersList = () => {
  const [usersListResult] = useQuery({
    query: UsersList_UserDocument,
  })
  const me = useMe()
  const setDeleteInviteUserAtom = useUpdateAtom(deleteInviteOrUserAtom)

  const columns = useMemo<Column<UsersList_UserAdministrationUserFragment>[]>(() => {
    return [
      {
        Header: 'User',
        accessor: (it) => it,
        id: 'user-info',
        Cell: ({ value }: { value: UsersList_UserAdministrationUserFragment }) => {
          return <UserCell data={value} />
        },
      },
      {
        accessor: (it) => it,
        Header: 'Status',
        Cell: ({ value }: { value: UsersList_UserAdministrationUserFragment }) => {
          const isExpired = value.__typename === 'InvitedUser' && value.expired
          return (
            <Badge
              colorScheme={isExpired ? 'red' : colorSchemeByStatus(value.__typename)}
            >
              {isExpired
                ? 'Expired'
                : value.__typename === 'InvitedUser'
                ? 'Invited'
                : 'Active'}
            </Badge>
          )
        },
      },
      {
        id: 'delete',
        accessor: (it) => it,
        Cell: ({ value }: { value: UsersList_UserAdministrationUserFragment }) => {
          if (value.id === me.id) {
            return null
          }
          return (
            <Button
              variant="link"
              colorScheme="red"
              onClick={() => {
                if (value.__typename === 'OnboardedUser') {
                  void setDeleteInviteUserAtom({
                    type: 'user',
                    id: value.id,
                  })
                } else {
                  void setDeleteInviteUserAtom({
                    type: 'invite',
                    id: value.id,
                  })
                }
              }}
            >
              Delete
            </Button>
          )
        },
      },
    ]
  }, [me.id, setDeleteInviteUserAtom])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<UsersList_UserAdministrationUserFragment>({
    columns,
    data: usersListResult.data?.userAdministrationUsers ?? [],
  })

  const grey = useColorModeValue('gray.50', 'gray.800')

  if (!isValidResult(usersListResult)) {
    return <GenericError />
  }

  if (isEmpty(usersListResult.data.userAdministrationUsers)) {
    return <GenericEmpty />
  }

  return (
    <>
      <DeleteInviteOrUser />
      <TableContainer>
        <Table borderWidth="1px" fontSize="sm" {...getTableProps()}>
          <Thead bg={grey}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th whiteSpace="nowrap" scope="col" {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, index, arr) => {
                    const isLast = arr.length - 1 === index
                    return (
                      <Td
                        whiteSpace="nowrap"
                        {...cell.getCellProps()}
                        textAlign={isLast ? 'right' : 'unset'}
                      >
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
