import { buildNamespacedKeyBuilder, PersistenceService } from 'packlets/services'

const PROFIT_LOSS_CONSOLIDATED_ENTITIES = 'profitLoss.consolidatedEntities'
const namespacedKeyBuilder = buildNamespacedKeyBuilder(PROFIT_LOSS_CONSOLIDATED_ENTITIES)

export const CURRENCY_SELECT = 'currencySelect'
export const CONSOLIDATED_ENTITIES_PAGE_STATE = 'pageState'
export const PERIOD_DATE_RANGE_SELECTOR = 'periodRangeSelector'

const keys = {
  CURRENCY_SELECT: namespacedKeyBuilder(CURRENCY_SELECT),
  CONSOLIDATED_DEPARTMENTS_PAGE_STATE: namespacedKeyBuilder(
    CONSOLIDATED_ENTITIES_PAGE_STATE,
  ),
  PERIOD_DATE_RANGE_SELECTOR: namespacedKeyBuilder(PERIOD_DATE_RANGE_SELECTOR),
}

export const ProfitLossConsolidatedEntitiesPersistence = PersistenceService.buildPersistenceService(
  PROFIT_LOSS_CONSOLIDATED_ENTITIES,
  keys,
)
