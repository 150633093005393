import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { SignInPage } from 'pages/SignIn'
import { AuthRedirect as AuthRedirectPage } from 'pages/AuthRedirect'
import { NotFound as NotFoundPage } from 'pages/NotFound'
import { ProtectedRoute } from 'packlets/components'
import { useListenToSystemTheme } from '@liveflow-io/hooks-common'
import { useCleanupRedirectBackTask } from 'packlets/hooks'
import { Box, Flex, Spinner } from '@chakra-ui/react'
import { Routes as RoutesConstants } from 'packlets/constants'
import {
  BankDashboardPage,
  ConsolidatedDepartmentsProfitLossPage,
  ConsolidatedEntitiesProfitLossPage,
  IntegrationsPage,
  InvitePage,
  NotEnabledPage,
  OnboardingPage,
  XeroRedirect,
  ProfitLossPerDepartmentPage,
  ProfitLossPerEntityPage,
  QuickBooksRedirect,
  SecuredApp,
  SettingsPage,
  TrueLayerRedirect,
} from './secured'

export const App = () => {
  useListenToSystemTheme()
  // useAppcues()
  useCleanupRedirectBackTask()

  return (
    <Box minHeight="100vh" h="full" position="relative">
      <React.Suspense
        fallback={
          <Flex justifyContent="center" alignItems="center" w="100vw" h="100vh">
            <Spinner size="xl" />
          </Flex>
        }
      >
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path={RoutesConstants.AUTH_REDIRECT} element={<AuthRedirectPage />} />
          <Route path={RoutesConstants.SIGN_IN} element={<SignInPage />} />
          <ProtectedRoute
            path={RoutesConstants.TRUELAYER_REDIRECT}
            element={<TrueLayerRedirect />}
          />
          <ProtectedRoute
            path={RoutesConstants.QUICKBOOKS_REDIRECT}
            element={<QuickBooksRedirect />}
          />
          <ProtectedRoute
            path={RoutesConstants.XERO_REDIRECT}
            element={<XeroRedirect />}
          />
          <ProtectedRoute
            path={RoutesConstants.NOT_ENABLED}
            element={<NotEnabledPage />}
          />
          <ProtectedRoute
            path={RoutesConstants.ONBOARDING}
            element={<OnboardingPage />}
          />
          <ProtectedRoute path="/" element={<SecuredApp />}>
            <ProtectedRoute
              path={RoutesConstants.BANK_DASHBOARD}
              element={<BankDashboardPage />}
            />
            <ProtectedRoute
              path={RoutesConstants.PROFIT_LOSS_PER_ENTITY}
              element={<ProfitLossPerEntityPage />}
            />
            <ProtectedRoute
              path={RoutesConstants.CONSOLIDATED_PROFIT_LOSS_BY_ENTITY}
              element={<ConsolidatedEntitiesProfitLossPage />}
            />
            <ProtectedRoute
              path={RoutesConstants.PROFIT_LOSS_PER_DEPARTMENT}
              element={<ProfitLossPerDepartmentPage />}
            />
            <ProtectedRoute
              path={RoutesConstants.CONSOLIDATED_PROFIT_LOSS_BY_DEPARTMENT}
              element={<ConsolidatedDepartmentsProfitLossPage />}
            />
            <ProtectedRoute
              path={RoutesConstants.INTEGRATIONS}
              element={<IntegrationsPage />}
            />
            <ProtectedRoute path={RoutesConstants.SETTINGS} element={<SettingsPage />} />
            <ProtectedRoute path={RoutesConstants.INVITE} element={<InvitePage />} />
          </ProtectedRoute>
        </Routes>
      </React.Suspense>
    </Box>
  )
}
