import React, { useCallback, useEffect, useMemo } from 'react'
import {
  HStack,
  Icon,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { CollapseArrow, MotionBox } from '@liveflow-io/component-common'
import { FaAngleDoubleUp } from 'react-icons/fa'
import { isEmptyOrNullish, renderMoneyOrNA } from '@liveflow-io/utils-common'
import {
  buildMultipleItemsKeysToCellsMapping,
  buildPnLCsvRows,
  renderPercentageOrNa,
} from '../utils'
import type { ProfitLossTable_ProfitLossFragment } from './documents.generated'
import { AnimatedRows } from './AnimatedTableComponents'
import { useProfitLossTableCollapseState } from '../hooks'
import { useUpdateAtom } from 'jotai/utils'
import { ProfitLossEngine, profitLossEngineAtom } from './ProfitLossEngine'
import { useAtom } from 'jotai'
import { csvButtonState } from '../atoms'
import { GenericEmpty } from '@liveflow-io/component-common'

export const ProfitLossTable = ({
  familyId,
  header,
}: {
  familyId: string
  header: React.ReactNode
}) => {
  const setCsvButtonState = useUpdateAtom(csvButtonState)
  const [profitLossEngineState] = useAtom(profitLossEngineAtom({ id: familyId }))
  const { queryResult, queries } = profitLossEngineState

  const [
    {
      showOtherExpenses,
      showCostOfSales,
      showOperatingCost,
      uncollapseAll,
      showOtherIncome,
      showIncome,
    },
    {
      onCollapseAll,
      onUncollapseAll,
      toggleShowCostOfSales,
      toggleShowIncome,
      toggleShowOperatingCost,
      toggleShowOtherExpenses,
      toggleShowOtherIncome,
    },
  ] = useProfitLossTableCollapseState()

  const red = useColorModeValue('red.500', 'red.300')
  const green = useColorModeValue('green.500', 'green.300')
  const blue = useColorModeValue('blue.500', 'blue.300')
  const grayBg = useColorModeValue('gray.50', 'gray.700')

  const getColor = useCallback(
    (num: number | undefined) => {
      // eslint-disable-next-line eqeqeq
      if (num == undefined) {
        return undefined
      }
      return num < 0 ? red : green
    },
    [red, green],
  )
  const allProfitLosses = useMemo(() => {
    const ordered: Partial<ProfitLossTable_ProfitLossFragment>[] = []
    const queriesOrder = Object.keys(queries)
    queriesOrder.forEach((key) => {
      ordered.push(queryResult?.[key]?.data?.newProfitLoss ?? {})
    })
    return ordered
  }, [queries, queryResult])

  const filledWithPlaceholdersCategoriesMapping = useMemo(() => {
    return buildMultipleItemsKeysToCellsMapping(allProfitLosses)
  }, [allProfitLosses])

  const csvData = useMemo(() => {
    if (!allProfitLosses.some((it) => isEmptyOrNullish(it))) {
      return buildPnLCsvRows(allProfitLosses, filledWithPlaceholdersCategoriesMapping)
    }
    return [{ 0: '' }]
  }, [allProfitLosses, filledWithPlaceholdersCategoriesMapping])

  useEffect(() => {
    void setCsvButtonState((it) => ({
      ...it,
      data: csvData,
    }))
  }, [csvData, setCsvButtonState])

  const { otherExpenses, costOfSales, income, operatingCost, otherIncome } =
    filledWithPlaceholdersCategoriesMapping ?? {}

  if (allProfitLosses.length === 0) {
    return <GenericEmpty />
  }

  return (
    <TableContainer>
      <ProfitLossEngine familyId={familyId} />
      <Table w="100%" sx={{ borderCollapse: 'unset', borderSpacing: 0 }} size="sm">
        <Thead>
          <Tr bg={grayBg}>
            <Th>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <MotionBox animate={{ rotate: uncollapseAll ? 0 : 180 }} w="fit-content">
                  <Icon
                    cursor="pointer"
                    as={FaAngleDoubleUp}
                    onClick={uncollapseAll ? onUncollapseAll : onCollapseAll}
                    title={uncollapseAll ? 'Expand all' : 'Collapse all'}
                    h={4}
                    w={4}
                    color={blue}
                  />
                </MotionBox>
              </Stack>
            </Th>
            {header}
          </Tr>
        </Thead>
        <Tbody>
          <Tr onClick={toggleShowIncome}>
            <Td>
              <HStack>
                <CollapseArrow open={showIncome} />
                <Text fontWeight="bold">Income</Text>
              </HStack>
            </Td>
            {allProfitLosses.map((pnl, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td fontWeight="bold" key={index}>
                {renderMoneyOrNA(pnl?.incomeSum)}
              </Td>
            ))}
          </Tr>
          {income && (
            <AnimatedRows isOpen={showIncome} categoriesToValues={income} color={green} />
          )}

          <Tr onClick={toggleShowCostOfSales}>
            <Td>
              <HStack>
                <CollapseArrow open={showCostOfSales} />
                <Text fontWeight="bold">Cost of Sales</Text>
              </HStack>
            </Td>
            {allProfitLosses.map(({ costOfSalesSum }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td fontWeight="bold" key={index} color={red}>
                {renderMoneyOrNA(costOfSalesSum)}
              </Td>
            ))}
          </Tr>
          {costOfSales && (
            <AnimatedRows
              isOpen={showCostOfSales}
              categoriesToValues={costOfSales}
              color={red}
            />
          )}

          <Tr bg={grayBg}>
            <Td>Gross Profit</Td>
            {allProfitLosses.map(({ grossProfit }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} color={getColor(grossProfit?.amount)}>
                {renderMoneyOrNA(grossProfit)}
              </Td>
            ))}
          </Tr>

          <Tr>
            <Td>Gross Profit Margin</Td>
            {allProfitLosses.map(({ grossMargin }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} color={getColor(grossMargin ?? 0)}>
                {renderPercentageOrNa(grossMargin)}
              </Td>
            ))}
          </Tr>

          <Tr onClick={toggleShowOperatingCost}>
            <Td>
              <HStack>
                <CollapseArrow open={showOperatingCost} />
                <Text fontWeight="bold">Operating costs</Text>
              </HStack>
            </Td>
            {allProfitLosses.map(({ operatingCostSum }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} fontWeight="bold" color={red}>
                {renderMoneyOrNA(operatingCostSum)}
              </Td>
            ))}
          </Tr>
          {operatingCost && (
            <AnimatedRows
              isOpen={showOperatingCost}
              categoriesToValues={operatingCost}
              color={red}
            />
          )}

          <Tr bg={grayBg}>
            <Td>Operating Profit</Td>
            {allProfitLosses.map(({ operatingProfit }, index) => (
              <Td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                fontWeight="bold"
                color={getColor(operatingProfit?.amount)}
              >
                {renderMoneyOrNA(operatingProfit)}
              </Td>
            ))}
          </Tr>

          <Tr>
            <Td>Operating Profit Margin</Td>
            {allProfitLosses.map(({ operatingProfitMargin }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} color={getColor(operatingProfitMargin ?? 0)}>
                {renderPercentageOrNa(operatingProfitMargin)}
              </Td>
            ))}
          </Tr>

          <Tr onClick={toggleShowOtherIncome}>
            <Td>
              <HStack>
                <CollapseArrow open={showOtherIncome} />
                <Text fontWeight="bold">Other Income</Text>
              </HStack>
            </Td>
            {allProfitLosses.map(({ otherIncomeSum }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} fontWeight="bold">
                {renderMoneyOrNA(otherIncomeSum)}
              </Td>
            ))}
          </Tr>
          {otherIncome && (
            <AnimatedRows isOpen={showOtherIncome} categoriesToValues={otherIncome} />
          )}
          <Tr onClick={toggleShowOtherExpenses}>
            <Td>
              <HStack>
                <CollapseArrow open={showOtherExpenses} />
                <Text fontWeight="bold">Other Expenses</Text>
              </HStack>
            </Td>
            {allProfitLosses.map(({ otherExpensesSum }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} fontWeight="bold">
                {renderMoneyOrNA(otherExpensesSum)}
              </Td>
            ))}
          </Tr>
          {otherExpenses && (
            <AnimatedRows isOpen={showOtherExpenses} categoriesToValues={otherExpenses} />
          )}
          <Tr>
            <Td>Net Other Income</Td>
            {allProfitLosses.map(({ netOtherIncome }, index) => (
              <Td
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                fontWeight="bold"
                color={getColor(netOtherIncome?.amount)}
              >
                {renderMoneyOrNA(netOtherIncome)}
              </Td>
            ))}
          </Tr>

          <Tr bg={grayBg}>
            <Td>Net Profit</Td>
            {allProfitLosses.map(({ netProfit }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} fontWeight="bold" color={getColor(netProfit?.amount)}>
                {renderMoneyOrNA(netProfit)}
              </Td>
            ))}
          </Tr>
          <Tr>
            <Td>Net Profit Margin</Td>
            {allProfitLosses.map(({ netProfitMargin }, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Td key={index} color={getColor(netProfitMargin ?? 0)}>
                {renderPercentageOrNa(netProfitMargin)}
              </Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  )
}
