import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  Stack,
  Th,
} from '@chakra-ui/react'
import {
  isEmptyOrNullish,
  isNotEmptyOrNullish,
  utcDayJs,
} from '@liveflow-io/utils-common'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { CONSOLIDATED_PNL_EVENTS, PNL_EVENTS, TrackingService } from 'packlets/services'
import { useCompletedNativeAccountingIntegrations, useDeepMemo } from 'packlets/hooks'
import { useDeepCompareEffect } from 'react-use'
import { downloadCsv } from 'packlets/utils'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import {
  firstDateRangeAtom,
  secondDateRangeAtom,
  selectedCurrencyAtom,
  selectedDepartmentIdAtom,
} from './atoms'
import { DownloadIcon } from '@chakra-ui/icons'
import { useQuery } from 'urql'
import { NewProfitLossPerDepartment_CategoriesDocument } from './documents.generated'
import type { Dayjs } from 'dayjs'
import {
  HasIntegrations,
  ProfitLossMonthRangePicker,
  ProfitLossShell,
  ProfitLossTable,
} from '../components'
import { csvButtonState } from '../atoms'
import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from 'packlets/queries'
import { useHandleConnectedToInfoRendering } from '../hooks'
import { useHandleProfitLossQueriesChange } from './hooks'
import { useAtom } from 'jotai'
import { ATOM_FAMILY } from './atomFamiles'
import { ProfitLossPerDepartmentPersistence } from './persistence'
import { SELECT_CURRENCIES } from 'packlets/constants'

type CategoriesPairs = { name: string; id: string }

export const ProfitLossPerDepartment = () => {
  return (
    <ProfitLossShell
      heading="P&L - By single department"
      persistenceService={ProfitLossPerDepartmentPersistence}
    >
      <Suspense fallback={<Skeleton height="full" />}>
        <HasIntegrations>
          <PerDepartment />
        </HasIntegrations>
      </Suspense>
    </ProfitLossShell>
  )
}

const PerDepartment = () => {
  const csvData = useAtomValue(csvButtonState)

  const [categoriesPairs, setCategoriesPairs] = useState<CategoriesPairs[]>([])
  const [selectedCategoryId, setSelectedCategoryId] = useAtom(selectedDepartmentIdAtom)
  const [selectedCurrency, setCurrency] = useAtom(selectedCurrencyAtom)

  const [categoryResult] = useQuery({
    query: NewProfitLossPerDepartment_CategoriesDocument,
  })
  const categories = categoryResult.data?.profitLossCategories

  const [nativeIntegrationsResult] = useCompletedNativeAccountingIntegrations()
  const nativeIntegrations = nativeIntegrationsResult.data?.integrations
  useHandleConnectedToInfoRendering(
    nativeIntegrations as
      | CommonIntegrations_CommonAccountingIntegrationPayloadFragment[]
      | undefined,
  )

  const integrationIds = useDeepMemo(() => {
    return nativeIntegrations?.map((it) => it.integrationId)
  }, [nativeIntegrations])

  useDeepCompareEffect(() => {
    if (isNotEmptyOrNullish(categories)) {
      const aCategoriesPairs = categories.map<CategoriesPairs>((it) => ({
        name: it.name,
        id: it.id,
      }))
      setCategoriesPairs(aCategoriesPairs)
      isEmptyOrNullish(selectedCategoryId) &&
        setSelectedCategoryId(aCategoriesPairs[0].id)
    }
  }, [categories])

  const onAccountSelect: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      TrackingService.track(PNL_EVENTS.SELECT_ACCOUNT)
      void setSelectedCategoryId(e.target.value)
    },
    [setSelectedCategoryId],
  )

  const onCurrencyChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      TrackingService.track(CONSOLIDATED_PNL_EVENTS.CURRENCY_CHANGED)
      void setCurrency(e.target.value)
    },
    [setCurrency],
  )

  const now = useMemo(() => utcDayJs(), [])

  const [firstDateRange, setFirstDateRange] = useAtom(firstDateRangeAtom)
  const [secondDateRange, setSecondDateRange] = useAtom(secondDateRangeAtom)

  const threeMonthsBeforeRecent = useMemo(() => now.subtract(2, 'month'), [now])
  const fourMonthsBeforeRecent = useMemo(() => now.subtract(3, 'month'), [now])

  useHandleProfitLossQueriesChange(
    selectedCategoryId,
    selectedCurrency,
    integrationIds,
    fourMonthsBeforeRecent,
    threeMonthsBeforeRecent,
    firstDateRange,
    secondDateRange,
  )

  const csvHeaders = useMemo(() => {
    return [
      '',
      fourMonthsBeforeRecent.format('MMM YYYY'),
      threeMonthsBeforeRecent.format('MMM YYYY'),
      `${firstDateRange[0].format('MMM YYYY')} - ${firstDateRange[1].format('MMM YYYY')}`,
      `${secondDateRange[0].format('MMM YYYY')} - ${secondDateRange[1].format(
        'MMM YYYY',
      )}`,
    ]
  }, [firstDateRange, secondDateRange, fourMonthsBeforeRecent, threeMonthsBeforeRecent])

  const setCsvButtonState = useUpdateAtom(csvButtonState)

  useEffect(() => {
    void setCsvButtonState((it) => ({
      ...it,
      headers: csvHeaders,
    }))
  }, [csvHeaders, setCsvButtonState])

  return (
    <Stack spacing={8}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={[2, 4, 8]}
      >
        {categoriesPairs.length > 1 ? (
          <FormControl id="category-selector" w={300}>
            <FormLabel>Selected department:</FormLabel>
            <Select value={selectedCategoryId} onChange={onAccountSelect}>
              {categoriesPairs.map((it) => (
                <option key={it.id} value={it.id}>
                  {it.name}
                </option>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div />
        )}
        <Stack direction="row" alignItems="flex-end">
          <FormControl id="consolidated-currency" w={300}>
            <FormLabel>Currency:</FormLabel>
            <Select value={selectedCurrency} onChange={onCurrencyChange}>
              {SELECT_CURRENCIES.map((it) => {
                return (
                  <option key={it} value={it}>
                    {it}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <Button
            leftIcon={<DownloadIcon />}
            onClick={() => {
              downloadCsv({
                ...csvData,
                showLabels: true,
                filename: 'Profit and Loss',
              })
            }}
          >
            Export to CSV/Excel
          </Button>
        </Stack>
      </Stack>
      <Suspense fallback={<Skeleton h="400px" />}>
        {isNotEmptyOrNullish(selectedCategoryId) && (
          <ProfitLossTable
            header={
              <>
                <Th>{fourMonthsBeforeRecent.format('MMMM YYYY')}</Th>
                <Th>{threeMonthsBeforeRecent.format('MMMM YYYY')}</Th>
                <Th>
                  <ProfitLossMonthRangePicker
                    value={firstDateRange}
                    // @ts-expect-error
                    onChange={([startDate, endDate]: [Dayjs, Dayjs]) => {
                      TrackingService.track(PNL_EVENTS.DATE_FILTER_CHANGED)
                      void setFirstDateRange([
                        startDate.startOf('month'),
                        endDate.endOf('month'),
                      ])
                    }}
                  />
                </Th>
                <Th>
                  <ProfitLossMonthRangePicker
                    value={secondDateRange}
                    // @ts-expect-error
                    onChange={([startDate, endDate]) => {
                      TrackingService.track(PNL_EVENTS.DATE_FILTER_CHANGED)
                      void setSecondDateRange([
                        startDate.startOf('month'),
                        endDate.endOf('month'),
                      ])
                    }}
                  />
                </Th>
              </>
            }
            familyId={ATOM_FAMILY}
          />
        )}
      </Suspense>
    </Stack>
  )
}
