import type { CommonIntegrations_IntegrationPayloadFragment } from 'packlets/queries'
import type { Dayjs } from 'dayjs'
import { useUpdateAtom } from 'jotai/utils'
import type { ProfitLossQueries } from '../components'
import { profitLossEngineAtom } from '../components'
import { ATOM_FAMILY } from './atoms'
import { useDeepCompareEffect } from 'react-use'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { dayJsRangeToStringRange } from 'packlets/utils'

export const useHandleProfitLossQueriesChange = (
  selectedEntities: CommonIntegrations_IntegrationPayloadFragment[],
  selectedRange: [Dayjs, Dayjs],
  selectedCurrency: string,
) => {
  const setProfitLossEngineState = useUpdateAtom(
    profitLossEngineAtom({ id: ATOM_FAMILY }),
  )

  useDeepCompareEffect(() => {
    if (isNotEmptyOrNullish(selectedEntities)) {
      const entitiesVariables = selectedEntities.reduce<ProfitLossQueries>(
        (acc, next) => {
          return {
            ...acc,
            [next.name]: {
              input: {
                selectedCurrency,
                filter: {
                  integrationIds: [next.integrationId],
                },
                range: dayJsRangeToStringRange(selectedRange),
              },
            },
          }
        },
        {} as ProfitLossQueries,
      )
      void setProfitLossEngineState((it) => ({
        ...it,
        queries: {
          ...entitiesVariables,
          consolidated: {
            input: {
              selectedCurrency,
              filter: {
                integrationIds: selectedEntities.map(
                  (integrations) => integrations.integrationId,
                ),
              },
              range: dayJsRangeToStringRange(selectedRange),
            },
          },
        },
      }))
    } else {
      void setProfitLossEngineState((it) => {
        return {
          ...it,
          queries: {},
          queryResult: {},
        }
      })
    }
  }, [selectedEntities, setProfitLossEngineState, selectedCurrency, selectedRange])
}
