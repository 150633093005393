import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import { RenameIntegration_IntegrationPayloadDocument } from './documents.generated'
import { useAtom } from 'jotai'
import { nameAtom, selectedEditNameIntegrationIdAtom } from './atoms'
import React, { useCallback, useRef } from 'react'
import {
  impossibleState,
  isEmptyOrNullish,
  isNotEmptyOrNullish,
} from '@liveflow-io/utils-common'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'

export const RenameIntegration = () => {
  const [, renameIntegration] = useEnhancedMutation(
    RenameIntegration_IntegrationPayloadDocument,
  )
  const toast = useToast()
  const [selectedEditNameIntegrationId, setSelectedEditNameIntegrationId] = useAtom(
    selectedEditNameIntegrationIdAtom,
  )

  const [name, setName] = useAtom(nameAtom)

  const onCloseEditNameModal = useCallback(() => {
    void setSelectedEditNameIntegrationId(null)
  }, [setSelectedEditNameIntegrationId])

  const onEditSubmit = useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault()
      if (
        isNotEmptyOrNullish(name) &&
        isNotEmptyOrNullish(selectedEditNameIntegrationId)
      ) {
        renameIntegration({
          input: {
            name,
          },
          id: selectedEditNameIntegrationId,
        })
          .then((res) => {
            switch (res.state) {
              case 'done':
              case 'partial':
                void setSelectedEditNameIntegrationId(null)
                toast({
                  description: 'Your integration has been renamed!',
                  title: 'Success!',
                })
                break
              case 'error':
                toast({
                  description: 'There was some problem renaming your integration',
                  status: 'error',
                  title: 'Something went wrong',
                })
                break
              default:
                impossibleState(res)
            }
            return res
          })
          .catch(console.error)
      }
    },
    [
      name,
      renameIntegration,
      selectedEditNameIntegrationId,
      setSelectedEditNameIntegrationId,
      toast,
    ],
  )

  const cancelRenameRef = useRef(null)

  return (
    <AlertDialog
      isOpen={isNotEmptyOrNullish(selectedEditNameIntegrationId)}
      onClose={onCloseEditNameModal}
      leastDestructiveRef={cancelRenameRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent as="form" onSubmit={onEditSubmit}>
        <AlertDialogHeader>
          Please decide on the name of the integration.
        </AlertDialogHeader>
        <AlertDialogBody>
          <Text>This will rename your integration.</Text>
          <Input
            value={name}
            onChange={(e) => {
              void setName(e.target.value)
            }}
          />
        </AlertDialogBody>

        <AlertDialogFooter as={Stack} spacing={2} direction="row">
          <Button colorScheme="blue" isDisabled={isEmptyOrNullish(name)} type="submit">
            Save
          </Button>
          <Button ref={cancelRenameRef} onClick={onCloseEditNameModal}>
            Close
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}
