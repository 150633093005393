import React, { useCallback, useMemo } from 'react'
import type { Dayjs } from 'dayjs'
import { generateRangesByUnit, utcDayJs } from '@liveflow-io/utils-common'
import type { MonthRangePickerProps } from 'packlets/components'
import { MonthRangePicker } from 'packlets/components'

export const ProfitLossMonthRangePicker = (props: MonthRangePickerProps) => {
  const minus2years = utcDayJs().subtract(2, 'years').startOf('year')
  const now = utcDayJs()
  const presets = useMemo(
    () =>
      Object.fromEntries(
        Object.entries({
          ...generateRangesByUnit({
            startDate: minus2years,
            endDate: now,
            unit: 'quarter',
          }),
          ...generateRangesByUnit({
            startDate: minus2years,
            endDate: now,
            unit: 'half',
          }),
          ...generateRangesByUnit({
            startDate: minus2years,
            endDate: now,
            unit: 'year',
          }),
        }).sort(),
      ),
    [minus2years, now],
  )

  const isNotAfterNow = useCallback(
    (it: Dayjs) => {
      return !it.isSameOrBefore(now)
    },
    [now],
  )

  const filteredPresetsByEarliestMonth = useMemo(
    () =>
      Object.entries(presets).reduce((acc, [key, [start, end]]) => {
        if (start.isSameOrAfter(minus2years)) {
          return { ...acc, [key]: [start, end] }
        }
        if (end.isBefore(minus2years)) {
          return acc
        }
        return { ...acc, [key]: [minus2years, end] }
      }, {}),
    [minus2years, presets],
  )

  return (
    <MonthRangePicker
      {...props}
      allowClear={false}
      ranges={filteredPresetsByEarliestMonth}
      disabledDate={isNotAfterNow}
    />
  )
}
