import {
  Button,
  Divider,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
  useClipboard,
  useDisclosure,
} from '@chakra-ui/react'
import * as React from 'react'
import { Suspense, useCallback, useState } from 'react'
import { Card, LabeledInput } from '@liveflow-io/component-common'
import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import { InviteUser_InvitePageDocument } from './documents.generated'
import { impossibleState, isNotNullish } from '@liveflow-io/utils-common'
import { BiClipboard, BiLink } from 'react-icons/bi'
import { INVITE_EVENTS, TrackingService } from 'packlets/services'
import { InviteUserProblemCode } from 'packlets/generated'
import { UsersList } from './UsersList'

const inviteUserSchema = object({
  email: string().nonempty().email(),
})

type InviteUserType = TypeOf<typeof inviteUserSchema>

export const Invite = () => {
  const toast = useToast()
  const [code, setCode] = useState<string>('')
  const { hasCopied, onCopy } = useClipboard(code)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { register, handleSubmit, errors, formState } = useForm<InviteUserType>({
    resolver: zodResolver(inviteUserSchema),
  })

  const [createInviteUser, inviteUser] = useEnhancedMutation(
    InviteUser_InvitePageDocument,
  )
  const onSubmit = useCallback(
    (input: InviteUserType) => {
      inviteUser({ input })
        .then((response) => {
          switch (response.state) {
            case 'done': {
              const { inviteUrl, problem } = response.data.userAdministrationInviteUser
              if (isNotNullish(inviteUrl)) {
                onOpen()
                TrackingService.track(INVITE_EVENTS.INVITE_CODE_GENERATED)
                setCode(inviteUrl)
                break
              }
              if (problem) {
                toast({
                  status: 'error',
                  title: 'Oops!',
                  description:
                    problem.problemCode === InviteUserProblemCode.UserOnboarded
                      ? 'This user is logged in to LiveFlow already. Try another email, please.'
                      : 'This user is invited to join LiveFlow already. Try another email, please.',
                })
              }
              break
            }
            case 'partial':
            case 'error': {
              toast({
                status: 'error',
                title: 'Something went terribly wrong!',
                description: JSON.stringify(response.error, null, 2),
              })
              break
            }
            default:
              impossibleState(response)
              break
          }
          return response
        })
        .catch(console.error)
    },
    [inviteUser, onOpen, toast],
  )

  const submitting = createInviteUser.state === 'fetching'
  return (
    <Stack spacing={8}>
      <Heading as="h1" size="lg">
        Invite teammates
      </Heading>
      <Divider />
      <Stack
        spacing={8}
        direction={{ base: 'column', lg: 'row' }}
        as="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack spacing={8} direction={{ base: 'column' }}>
          <Card>
            <Stack>
              <LabeledInput
                isRequired
                label="E-mail"
                placeholder="ceo@awesome.shop"
                ref={register}
                name="email"
                errorLabel={errors.email?.message}
                isDisabled={submitting}
                isInvalid={!!errors.email}
              />
            </Stack>
            <Button
              rightIcon={<BiLink />}
              w="full"
              mt={4}
              type="submit"
              colorScheme={
                Object.values(formState.touched).some((it) => it) ? 'blue' : undefined
              }
              isLoading={submitting}
              loadingText="Generating invite..."
            >
              Generate invite link
            </Button>
          </Card>
        </Stack>
      </Stack>
      <Heading size="md">Users</Heading>
      <Suspense fallback={<Skeleton h="400px" />}>
        <UsersList />
      </Suspense>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Here is your invite link!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Invitation e-mail has been successfully sent! If you wish you can also copy
              the link through the button below and share it with your colleague directly.
              Please note, this is a one-time link and can be used only for their first
              sign-up.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              isDisabled={hasCopied}
              onClick={() => {
                onCopy()
                onClose()
              }}
              rightIcon={<BiClipboard />}
            >
              Copy link to clipboard
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  )
}
