import type { UseRadioGroupProps } from '@chakra-ui/react'
import { Stack, useRadioGroup } from '@chakra-ui/react'
import * as React from 'react'
import { ButtonRadio } from './ButtonRadio'

interface ButtonRadioGroupProps extends UseRadioGroupProps {
  options: Array<{
    label: string
    value: string
    title: string
    rightSide?: string
    description?: string
    icon: React.ReactElement
  }>
}

export const ButtonRadioGroup = (props: ButtonRadioGroupProps) => {
  const { options, ...rest } = props
  const { getRadioProps, getRootProps } = useRadioGroup(rest)
  return (
    <Stack justify="center" spacing="2" {...getRootProps()}>
      {options.map((option) => (
        <ButtonRadio
          key={option.value}
          title={option.title}
          icon={option.icon}
          description={option.description}
          rightSide={option.rightSide}
          {...getRadioProps({ value: option.value })}
        />
      ))}
    </Stack>
  )
}
