import mixpanel from 'mixpanel-browser'
import { getEnvByHostname } from '@liveflow-io/utils-common'

const DEV_MIXPANEL_TOKEN = process.env.REACT_APP_DEV_MIXPANEL_TOKEN
const PROD_MIXPANEL_TOKEN = process.env.REACT_APP_PROD_MIXPANEL_TOKEN

const DEV_MIXPANEL_URL = process.env.REACT_APP_DEV_MIXPANEL_URL
const PROD_MIXPANEL_URL = process.env.REACT_APP_PROD_MIXPANEL_URL

const mixpanelInstance = mixpanel.init(
  getEnvByHostname() === 'production' ? PROD_MIXPANEL_TOKEN : DEV_MIXPANEL_TOKEN,
  {
    api_host: getEnvByHostname() === 'production' ? PROD_MIXPANEL_URL : DEV_MIXPANEL_URL,
  },
  'Liveflow App',
)

export const TrackingService = {
  identify(userId: string, details?: any) {
    window.Appcues?.identify(userId, details)
    mixpanelInstance.identify(userId)
    mixpanelInstance.people.set(details)
  },
  track(eventName: string, details?: any) {
    window.Appcues?.track(eventName, details)
    mixpanelInstance.track(eventName, details)
  },
  page() {
    window.Appcues?.page()
  },
}

export const SIGN_IN_EVENTS = {
  GOOGLE_AUTH_ATTEMPT: 'by_google_auth_attempt',
  BY_MAGIC_LINK_ATTEMPT: 'by_magic_link_attempt',
  BY_MAGIC_LINK_ERROR: 'by_magic_link_error',
  AUTH_SUCCESS: 'auth_success',
  AUTH_FAILED: 'auth_failed',
} as const

export const ONBOARDING_EVENTS = {
  ONBOARDING_STARTED: 'onboarding_started',
  USER_CREATED: 'onboarding_user_created',
  COMPANY_CREATED: 'onboarding_company_created',
  ONBOARDING_SUCCESS: 'onboarding_success',
} as const

export const SETTINGS_EVENTS = {
  COMPANY_INFO_CHANGED: 'company_info_changed',
} as const

export const DASHBOARD_EVENTS = {
  USED_DATE_FILTER: 'used_dashboard_date_filter',
} as const

export const INTEGRATION_EVENTS = {
  INTEGRATED_BANK_STARTED: 'integrated_bank_started',
  INTEGRATED_BANK_FAILED: 'integrated_bank_failed',
  INTEGRATED_BANK_SUCCESS: 'integrated_bank_success',
  INTEGRATED_ACCOUNTING_STARTED: 'integrated_accounting_started',
  INTEGRATED_ACCOUNTING_SUCCESS: 'integrated_accounting_success',
  INTEGRATED_ACCOUNTING_FAILED: 'integrated_accounting_failed',
} as const

export const CASH_FLOW_EVENTS = {
  ADDED_BUDGET: 'added_budget',
  DELETED_BUDGET: 'deleted_budget',
  EDITED_BUDGET: 'edited_budget',
} as const

export const PNL_EVENTS = {
  DATE_FILTER_CHANGED: 'pnl_date_filter_changed',
  SELECT_ACCOUNT: 'pnl_account_selected',
}

export const CONSOLIDATED_PNL_EVENTS = {
  DATE_FILTER_CHANGED: 'consolidated_pnl_date_filter_changed',
  CURRENCY_CHANGED: 'consolidated_pnl_currency_changed',
  ADDED_ENTITY: 'consolidated_pnl_added_entity',
  REMOVED_ENTITY: 'consolidated_pnl_added_entity',
}

export const INVITE_EVENTS = {
  INVITE_CODE_GENERATED: 'invite_code_generated',
  INVITE_CODE_USED: 'invite_code_used',
}
