import { isValidResult } from '@liveflow-io/utils-common'
import { useQuery } from 'urql'
import type { AccountSelector_BankFragment } from './documents.generated'
import { AccountSelector_BanksDocument } from './documents.generated'
import type { UseCheckboxGroupProps } from '@chakra-ui/react'
import { Heading } from '@chakra-ui/react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useCheckboxGroup,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { accountsSelectedAtomFamily, accountsSelectorStateAtomFamily } from './atoms'
import React, { useEffect, useMemo, useState } from 'react'
import { isEqual, upperFirst } from 'lodash'
import { Card, GenericError } from '@liveflow-io/component-common'
import { CheckboxButton } from 'packlets/components'
import { renderMoney } from '@liveflow-io/utils-common'

const AccountSelectorOptions = ({
  banks,
  value,
  onChange,
}: {
  banks: AccountSelector_BankFragment[]
  value: string[]
  onChange: UseCheckboxGroupProps['onChange']
}) => {
  const { getCheckboxProps } = useCheckboxGroup({
    defaultValue: value,
    onChange,
  })

  return (
    <ModalBody as={Stack} spacing={4}>
      {banks.map((bank) => {
        return (
          <Card key={bank.accounts[0].id} as={Stack}>
            <Heading mb={6} size="md">
              {bank.name}
            </Heading>
            {bank.accounts.map((account) => {
              return (
                <CheckboxButton
                  key={account.id}
                  {...getCheckboxProps({ value: account.id })}
                  icon={<div />}
                  title={account.name ?? upperFirst(account.type.toLowerCase())}
                  rightSide={renderMoney(account.balance)}
                />
              )
            })}
          </Card>
        )
      })}
    </ModalBody>
  )
}

export const AccountSelectorButton = ({ atomFamilyKey }: { atomFamilyKey: string }) => {
  const [banksResult] = useQuery({
    query: AccountSelector_BanksDocument,
  })

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [accountsSelected, setAccountsSelected] = useAtom(
    accountsSelectedAtomFamily(atomFamilyKey),
  )
  const [{ initialAccountIds, isInitialized }, setAccountsSelectorState] = useAtom(
    accountsSelectorStateAtomFamily(atomFamilyKey),
  )
  const [accountSelectorValue, setAccountSelectorValue] = useState<string[]>()

  const allAccountIds = useMemo(() => {
    return banksResult.data?.banks?.flatMap((it) => it.accounts).map((it) => it.id)
    // Hack to ensure memo fires ONLY on operation key change (meaning new result came)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banksResult.operation?.key])

  useEffect(() => {
    if (
      !isInitialized ||
      (allAccountIds != null && !isEqual(initialAccountIds, allAccountIds))
    ) {
      setAccountSelectorValue(allAccountIds)
      void setAccountsSelectorState({
        isInitialized: true,
        initialAccountIds: allAccountIds,
      })
      void setAccountsSelected(allAccountIds)
    } else {
      setAccountSelectorValue(accountsSelected)
    }
  }, [
    accountsSelected,
    allAccountIds,
    initialAccountIds,
    isInitialized,
    setAccountsSelected,
    setAccountsSelectorState,
  ])

  if (!isValidResult(banksResult)) return <GenericError />

  return (
    <>
      <Button variant="ghost" onClick={onOpen} colorScheme="blue">
        Select accounts
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select accounts</ModalHeader>
          <ModalCloseButton />
          {accountSelectorValue && banksResult.data.banks && (
            <AccountSelectorOptions
              banks={banksResult.data.banks}
              value={accountSelectorValue}
              onChange={(value) => setAccountSelectorValue(value as string[])}
            />
          )}
          <ModalFooter>
            <Button
              mr={3}
              colorScheme="blue"
              onClick={() => {
                onClose()
                void setAccountsSelected(accountSelectorValue)
              }}
            >
              Apply
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
