import React, { useEffect } from 'react'
import { Flex, Spinner } from '@chakra-ui/react'
import { useToast, useEnhancedMutation } from '@liveflow-io/hooks-common'
import { TrackingService, INTEGRATION_EVENTS } from 'packlets/services'
import { impossibleState } from '@liveflow-io/utils-common'
import { Routes } from 'packlets/constants'
import { TrueLayerRedirect_TrueLayerIntegrationPayloadDocument } from './documents.generated'
import { useNavigateBackToWhereFlowInitiated } from 'packlets/hooks'

export const TrueLayerRedirect = () => {
  const [, integrate] = useEnhancedMutation(
    TrueLayerRedirect_TrueLayerIntegrationPayloadDocument,
  )
  const navigateBackToWhereFlowInitiatedOrFallback = useNavigateBackToWhereFlowInitiated()

  const toast = useToast()
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const code = urlParams.get('code')
    if (code !== null) {
      integrate({
        code,
      })
        .then((result) => {
          switch (result.state) {
            case 'error':
            case 'partial':
              toast({
                status: 'error',
                title: 'Oh no, something went wrong!',
                description: 'Unfortunately bank is not integrated! Try again.',
              })
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_FAILED)
              navigateBackToWhereFlowInitiatedOrFallback(Routes.BANK_DASHBOARD)
              break
            case 'done':
              toast({
                title: 'TrueLayer integrated succesfully!',
                description: 'Your bank got integrated!',
              })
              TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_SUCCESS)
              navigateBackToWhereFlowInitiatedOrFallback(Routes.INTEGRATIONS)
              break
            default:
              impossibleState(result)
          }
          return result
        })
        .catch(console.error)
    }
  }, [navigateBackToWhereFlowInitiatedOrFallback, integrate, toast])

  return (
    <Flex justifyContent="center" alignItems="center" w="100vw" h="100vh">
      <Spinner size="xl" />
    </Flex>
  )
}
