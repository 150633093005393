import { isValidResult, renderMoney } from '@liveflow-io/utils-common'
import type {
  Account_BankAccountFragment,
  BanksCard_BankFragment,
} from './documents.generated'
import { BankSection_BanksDocument } from './documents.generated'
import React, { Suspense, useState } from 'react'
import { Box, Collapse, Heading, HStack, Skeleton, Stack } from '@chakra-ui/react'
import { Card, CollapseArrow, GenericError } from '@liveflow-io/component-common'
import { AccountSelectorButton } from './AccountsSelectorButton'
import { upperFirst } from 'lodash'
import { useAtomValue } from 'jotai/utils'
import { accountsSelectedAtomFamily, currencyAtom } from './atoms'
import { useQuery } from 'urql'
import { UnderscoredHeading } from './components/UnderscoredHeading'
import { TotalBalance } from './components/TotalBalance'
import { BANK_ACCOUNTS_SECTION_FAMILY, TOTAL_BALANCE_FAMILY } from './atomFamilies'

const BankAccounts = () => {
  const currency = useAtomValue(currencyAtom)
  const accountsIds = useAtomValue(accountsSelectedAtomFamily('BANK_ACCOUNTS'))
  const [banksResult] = useQuery({
    query: BankSection_BanksDocument,
    variables: {
      input: {
        filters: {
          accountIds: accountsIds ?? [],
        },
        currency,
      },
    },
  })

  if (!isValidResult(banksResult)) return <GenericError />
  return (
    <Box mt={4}>
      {banksResult.data.banks.map((bank) => {
        return <BankCard key={bank.name} bank={bank} />
      })}
    </Box>
  )
}

export const TotalBalanceSection = () => (
  <Box as={Card} w="full">
    <Box>
      <HStack justify="space-between" align="flex-start">
        <Box>
          <UnderscoredHeading size="md">Total cash available</UnderscoredHeading>
          <Suspense fallback={<Skeleton height="50px" />}>
            <TotalBalance atomFamilyKey={TOTAL_BALANCE_FAMILY} fontSize="3xl" mt={6} />
          </Suspense>
        </Box>
        <Suspense fallback={<Skeleton h="40px" w="145px" />}>
          <AccountSelectorButton atomFamilyKey={TOTAL_BALANCE_FAMILY} />
        </Suspense>
      </HStack>
    </Box>
  </Box>
)

export const BanksSection = () => {
  return (
    <Box as={Card} w="full">
      <HStack justifyContent="space-between">
        <UnderscoredHeading size="md">Balance by bank account</UnderscoredHeading>
        <Suspense fallback={<Skeleton h="40px" w="145px" />}>
          <AccountSelectorButton atomFamilyKey={BANK_ACCOUNTS_SECTION_FAMILY} />
        </Suspense>
      </HStack>

      <Suspense
        fallback={
          <Stack mt={4}>
            <Skeleton height="72px" />
            <Skeleton height="72px" />
          </Stack>
        }
      >
        <BankAccounts />
      </Suspense>
    </Box>
  )
}

export const Account = ({ account }: { account: Account_BankAccountFragment }) => {
  return (
    <Stack direction="row" justifyContent="space-between" pl={8}>
      <Heading size="md" fontWeight={300}>
        {upperFirst(account.type.toLowerCase())}
      </Heading>
      <Heading as="p" size="md" fontWeight={300}>
        {renderMoney(account.balance)}
      </Heading>
    </Stack>
  )
}

export const BankCard = ({ bank }: { bank: BanksCard_BankFragment }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => {
    setIsOpen((it) => !it)
    // This is hack to fix Tooltips positions on graphs (it should be recalculated with scroll event)
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('scroll'))
    }, 500)
  }
  return (
    <Stack as={Card} spacing={8}>
      <Stack direction="row" onClick={toggleOpen} justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <CollapseArrow open={isOpen} />
          <Heading as="h2" size="md">
            {bank.name}
          </Heading>
        </Stack>
        <Heading as="h2" size="md">
          {renderMoney(bank.totalBalance)}
        </Heading>
      </Stack>
      <Collapse in={isOpen}>
        <Stack spacing={8}>
          {bank.accounts.map((account) => (
            <Account key={account.id} account={account} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  )
}
