import type { Dayjs } from 'dayjs'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import type { ProfitLossQueries } from '../components'
import { profitLossEngineAtom } from '../components'
import { selectedDepartmentsAtom, selectedEntitiesAtom } from './atoms'
import { useDeepCompareEffect } from 'react-use'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { groupBy } from 'lodash'
import { dayJsRangeToStringRange } from 'packlets/utils'
import { ATOM_FAMILY } from './atomFamilies'

/**
 *  Applies changes to PnLEngine queries list that in return triggers refetches and columns appearing/disappearing
 */
export const useApplyProfitLossEngineQueryChanges = (
  selectedCurrency: string,
  selectedRange: [Dayjs, Dayjs],
) => {
  const setProfitLossEngineState = useUpdateAtom(
    profitLossEngineAtom({ id: ATOM_FAMILY }),
  )

  const selectedDepartments = useAtomValue(selectedDepartmentsAtom)
  const selectedEntities = useAtomValue(selectedEntitiesAtom)

  useDeepCompareEffect(() => {
    if (
      isNotEmptyOrNullish(selectedDepartments) &&
      isNotEmptyOrNullish(selectedEntities)
    ) {
      const depsIdsGroupedByName = groupBy(selectedDepartments, 'name')
      const integrationIds = selectedEntities.map((it) => it.integrationId)
      const depsVariables = Object.entries(
        depsIdsGroupedByName,
      ).reduce<ProfitLossQueries>((acc, [name, deps]) => {
        const categoryIds = deps.map((it) => it.id)
        return {
          ...acc,
          [name]: {
            input: {
              selectedCurrency,
              filter: {
                categoryIds,
                integrationIds,
              },
              range: dayJsRangeToStringRange(selectedRange),
            },
          },
        }
      }, {} as ProfitLossQueries)
      void setProfitLossEngineState((it) => ({
        ...it,
        queries: {
          ...depsVariables,
          consolidated: {
            input: {
              selectedCurrency,
              filter: {
                categoryIds: selectedDepartments.map((dept) => dept.id),
                integrationIds,
              },
              range: dayJsRangeToStringRange(selectedRange),
            },
          },
        },
      }))
    } else {
      void setProfitLossEngineState((it) => {
        return {
          ...it,
          queries: {},
          queryResult: {},
        }
      })
    }
  }, [
    setProfitLossEngineState,
    selectedDepartments,
    selectedEntities,
    selectedCurrency,
    selectedRange,
  ])
}
