import { Flex, Stack, Text } from '@chakra-ui/react'
import { Card } from '@liveflow-io/component-common'
import React from 'react'

export const PendingIntegrations = () => {
  return (
    <Flex alignItems="center" justifyContent="center" h="full" w="full">
      <Card as={Stack} spacing={4}>
        <Text>Pending integrations are in progress of downloading data...</Text>
      </Card>
    </Flex>
  )
}
