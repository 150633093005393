import type { ButtonProps } from '@chakra-ui/react'
import {
  Button,
  Stack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react'
import React from 'react'
import { FaPlus } from 'react-icons/fa'
import { PlaidButton } from '../PlaidButton'
import { INTEGRATION_EVENTS, TrackingService } from '../../services'
import { impossibleState, isProduction } from '@liveflow-io/utils-common'
import { saveNavigateBackLocation } from '../../hooks'
import { PlaidCountryCode } from '../../generated'

export const IntegrateBankButton = ({
  onClick,
  onSuccess,
  ...props
}: ButtonProps & { onSuccess?: VoidFunction }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <Button
        onClick={(...args) => {
          onOpen()
          onClick?.(...args)
        }}
        {...props}
        leftIcon={<FaPlus />}
      >
        Connect your bank
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg" trapFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Choose your bank origin</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            For integrating your US or Canadian bank we are using Plaid, for UK and
            European banks we are using TrueLayer
          </ModalBody>
          <ModalFooter justifyContent="stretch">
            <Stack spacing={2} justifyContent="space-between" width="100%">
              <PlaidButton
                countryCode={PlaidCountryCode.Us}
                colorScheme="blue"
                onPublicTokenSubmitted={(response) => {
                  switch (response.state) {
                    case 'error':
                      TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_FAILED)
                      break
                    case 'partial':
                      break
                    case 'done': {
                      onSuccess?.()
                      onClose()
                      TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_SUCCESS)
                      break
                    }
                    default:
                      impossibleState(response)
                  }
                }}
                onClick={() => {
                  TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_STARTED)
                }}
              />
              <PlaidButton
                countryCode={PlaidCountryCode.Ca}
                colorScheme="red"
                onPublicTokenSubmitted={(response) => {
                  switch (response.state) {
                    case 'error':
                      TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_FAILED)
                      break
                    case 'partial':
                      break
                    case 'done': {
                      onSuccess?.()
                      onClose()
                      TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_SUCCESS)
                      break
                    }
                    default:
                      impossibleState(response)
                  }
                }}
                onClick={() => {
                  TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_STARTED)
                }}
              />
              <Button
                colorScheme="green"
                isDisabled={isProduction()}
                onClick={() => {
                  TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_STARTED)
                  saveNavigateBackLocation()
                  window.location.assign(
                    `https://auth.truelayer-sandbox.com/?response_type=code&client_id=sandbox-liveflow-4c418d&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=${window.location.origin}/truelayerRedirect&providers=uk-ob-all%20uk-oauth-all%20uk-cs-mock`,
                  )
                }}
              >
                Connect your UK or European Bank
              </Button>
            </Stack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
