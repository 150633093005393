import { buildNamespacedKeyBuilder, PersistenceService } from 'packlets/services'

const PROFIT_LOSS_CONSOLIDATED_DEPARTMENTS = 'profitLoss.consolidatedDepartments'
const namespacedKeyBuilder = buildNamespacedKeyBuilder(
  PROFIT_LOSS_CONSOLIDATED_DEPARTMENTS,
)

const CURRENCY_SELECT = 'currencySelect'
const CONSOLIDATED_DEPARTMENTS_PAGE_STATE = 'pageState'
const PERIOD_DATE_RANGE_SELECTOR = 'periodRangeSelector'

const keys = {
  CURRENCY_SELECT: namespacedKeyBuilder(CURRENCY_SELECT),
  CONSOLIDATED_DEPARTMENTS_PAGE_STATE: namespacedKeyBuilder(
    CONSOLIDATED_DEPARTMENTS_PAGE_STATE,
  ),
  PERIOD_DATE_RANGE_SELECTOR: namespacedKeyBuilder(PERIOD_DATE_RANGE_SELECTOR),
}

export const ProfitLossConsolidatedDepartmentsPersistence = PersistenceService.buildPersistenceService(
  PROFIT_LOSS_CONSOLIDATED_DEPARTMENTS,
  keys,
)
