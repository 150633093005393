import type { CommonIntegrations_IntegrationPayloadUnionFragment } from 'packlets/queries'
import {
  Badge,
  Button,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import {
  nameAtom,
  selectedDisconnectIntegrationIdAtom,
  selectedEditNameIntegrationIdAtom,
} from './atoms'
import React, { useMemo } from 'react'
import type { Column } from 'react-table'
import { useTable } from 'react-table'
import { RenameIntegration } from './RenameIntegration'
import { DeleteIntegration } from './DeleteIntegration'
import { IntegrationSource, IntegrationStatus } from 'packlets/generated'
import type { ThemeTypings } from '@chakra-ui/styled-system'
import { impossibleState } from '@liveflow-io/utils-common'
import shopify from './shopify-logo.png'
import codat from './codat-logo.png'
import plaid from './plaid-logo.png'
import truelayer from './truelayer-logo.png'
import quickbook from './qb-logo.svg'
import xero from './xero-logo.svg'
import netsuite from './oracle-netsuite-logo.png'

const colorSchemeByStatus = (status: IntegrationStatus): ThemeTypings['colorSchemes'] => {
  switch (status) {
    case IntegrationStatus.Completed:
      return 'green'
    case IntegrationStatus.Revoked:
    case IntegrationStatus.DeletionInitiated:
    case IntegrationStatus.Pending:
      return 'yellow'
    case IntegrationStatus.Failed:
    case IntegrationStatus.ReauthRequired:
      return 'red'
    case IntegrationStatus.InitialDataLoaded:
      return 'blue'
    default:
      return impossibleState(status)
  }
}
const logoByIntegrationSource = (integrationType: IntegrationSource) => {
  switch (integrationType) {
    case IntegrationSource.NetSuite:
      return netsuite
    case IntegrationSource.Shopify:
      return shopify
    case IntegrationSource.Codat:
      return codat
    case IntegrationSource.Plaid:
      return plaid
    case IntegrationSource.TrueLayer:
      return truelayer
    case IntegrationSource.Xero:
      return xero
    case IntegrationSource.QuickBooks:
      return quickbook
    default:
      return impossibleState(integrationType)
  }
}
export const IntegrationsTable = ({
  integrations,
}: {
  integrations: CommonIntegrations_IntegrationPayloadUnionFragment[]
}) => {
  const grey = useColorModeValue('gray.50', 'gray.800')

  const [, setSelectedEditNameIntegrationId] = useAtom(selectedEditNameIntegrationIdAtom)
  const [, setSelectedDisconnectIntegrationId] = useAtom(
    selectedDisconnectIntegrationIdAtom,
  )
  const [, setName] = useAtom(nameAtom)

  const columns = useMemo<
    Column<CommonIntegrations_IntegrationPayloadUnionFragment>[]
  >(() => {
    return [
      {
        accessor: 'integrationSource',
        Header: 'Integration',
        Cell: (props) => {
          return <Image h="24px" src={logoByIntegrationSource(props.value)} />
        },
      },
      {
        accessor: 'status',
        Header: 'Status',
        Cell: (props) => {
          return (
            <Badge colorScheme={colorSchemeByStatus(props.value)}>{props.value}</Badge>
          )
        },
      },
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        Header: 'Bank name',
        accessor: (it) =>
          it.__typename === 'CommonBankIntegrationPayload' ? it.bank : '',
      },
      {
        id: 'edit',
        accessor: (it) => it,
        Cell: ({
          value,
        }: {
          value: CommonIntegrations_IntegrationPayloadUnionFragment
        }) => (
          <Button
            variant="link"
            colorScheme="blue"
            onClick={() => {
              void setSelectedEditNameIntegrationId(value.integrationId)
              void setName(value.name)
            }}
          >
            Edit
          </Button>
        ),
      },
      {
        id: 'disconnect',
        accessor: (it) => it,
        Cell: ({
          value,
        }: {
          value: CommonIntegrations_IntegrationPayloadUnionFragment
        }) => (
          <Button
            variant="link"
            colorScheme="red"
            onClick={() => {
              void setSelectedDisconnectIntegrationId(value.integrationId)
              void setName(value.name)
            }}
          >
            Disconnect
          </Button>
        ),
      },
    ]
  }, [setName, setSelectedDisconnectIntegrationId, setSelectedEditNameIntegrationId])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable<CommonIntegrations_IntegrationPayloadUnionFragment>({
    columns,
    data: integrations,
  })

  return (
    <>
      <RenameIntegration />
      <DeleteIntegration />
      <TableContainer>
        <Table borderWidth="1px" fontSize="sm" {...getTableProps()}>
          <Thead bg={grey}>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th whiteSpace="nowrap" scope="col" {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </Th>
                ))}
                <Th />
                <Th />
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td whiteSpace="nowrap" {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    )
                  })}
                  <Td whiteSpace="nowrap" textAlign="right" />
                  <Td whiteSpace="nowrap" textAlign="right" />
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  )
}
