import type { Money } from '@liveflow-io/utils-common'
import {
  formatNumber,
  isEmptyOrNullish,
  renderMoneyOrNA,
} from '@liveflow-io/utils-common'
import type { NewProfitLoss } from 'packlets/generated'

export type CategoriesToValuesOrNullPlaceholders = { [key: string]: (Money | null)[] }

export const mapCategoriesToValuesOrNullPlaceholders = (
  pnlKey: 'costOfSales' | 'income' | 'operatingCost' | 'otherExpenses' | 'otherIncome',
  profitLosses: Partial<NewProfitLoss>[],
) => {
  const categories = [
    ...new Set(profitLosses.flatMap((pnl) => pnl[pnlKey]?.map((it) => it.name))),
  ]
  return categories.reduce<CategoriesToValuesOrNullPlaceholders>((acc, categoryName) => {
    const keyToValues = profitLosses.map(
      (pnl) => pnl[pnlKey]?.find((pred) => pred?.name === categoryName)?.value ?? null,
    )
    if (isEmptyOrNullish(categoryName)) {
      return acc
    }
    return {
      ...acc,
      [categoryName]: keyToValues,
    }
  }, {})
}

export const buildMultipleItemsKeysToCellsMapping = (
  profitLosses: Partial<NewProfitLoss>[],
) => {
  if (profitLosses === null) {
    return null
  }
  const existingPnlsOnly = profitLosses
  const costOfSales = mapCategoriesToValuesOrNullPlaceholders(
    'costOfSales',
    existingPnlsOnly,
  )
  const income = mapCategoriesToValuesOrNullPlaceholders('income', existingPnlsOnly)
  const operatingCost = mapCategoriesToValuesOrNullPlaceholders(
    'operatingCost',
    existingPnlsOnly,
  )
  const otherExpenses = mapCategoriesToValuesOrNullPlaceholders(
    'otherExpenses',
    existingPnlsOnly,
  )
  const otherIncome = mapCategoriesToValuesOrNullPlaceholders(
    'otherIncome',
    existingPnlsOnly,
  )
  return {
    costOfSales,
    income,
    operatingCost,
    otherExpenses,
    otherIncome,
  }
}

export const renderPercentageOrNa = (it?: number | null) => {
  return it != null
    ? formatNumber(it, {
        style: 'percent',
        minimumFractionDigits: 2,
      })
    : 'N/A'
}

export const buildPnLCsvRows = (
  allProfitLosses: Partial<NewProfitLoss>[],
  filledWithPlaceholdersCategoriesMapping?: ReturnType<
    typeof buildMultipleItemsKeysToCellsMapping
  >,
) => {
  return [
    {
      0: 'Income',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it?.incomeSum), ...acc }),
        {},
      ),
    },
    ...Object.entries(filledWithPlaceholdersCategoriesMapping?.income ?? {}).map(
      ([category, values]) => {
        return {
          0: category,
          ...values?.reduce(
            (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it), ...acc }),
            {},
          ),
        }
      },
    ),
    {
      0: 'Cost of Sales',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderMoneyOrNA(it?.costOfSalesSum),
          ...acc,
        }),
        {},
      ),
    },
    ...Object.entries(filledWithPlaceholdersCategoriesMapping?.costOfSales ?? {}).map(
      ([category, values]) => {
        return {
          0: category,
          ...values?.reduce(
            (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it), ...acc }),
            {},
          ),
        }
      },
    ),
    {
      0: 'Gross Profit',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it?.grossProfit), ...acc }),
        {},
      ),
    },
    {
      0: 'Gross Profit Margin',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderPercentageOrNa(it?.grossMargin),
          ...acc,
        }),
        {},
      ),
    },
    {
      0: 'Operating costs',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderMoneyOrNA(it?.operatingCostSum),
          ...acc,
        }),
        {},
      ),
    },
    ...Object.entries(filledWithPlaceholdersCategoriesMapping?.operatingCost ?? {}).map(
      ([category, values]) => {
        return {
          0: category,
          ...values?.reduce(
            (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it), ...acc }),
            {},
          ),
        }
      },
    ),
    {
      0: 'Operating Profit',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderMoneyOrNA(it?.operatingProfit),
          ...acc,
        }),
        {},
      ),
    },
    {
      0: 'Operating Profit Margin',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderPercentageOrNa(it?.operatingProfitMargin),
          ...acc,
        }),
        {},
      ),
    },
    {
      0: 'Other Income',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderMoneyOrNA(it?.otherIncomeSum),
          ...acc,
        }),
        {},
      ),
    },
    ...Object.entries(filledWithPlaceholdersCategoriesMapping?.otherIncome ?? {}).map(
      ([category, values]) => {
        return {
          0: category,
          ...values?.reduce(
            (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it), ...acc }),
            {},
          ),
        }
      },
    ),
    {
      0: 'Other Expenses',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderMoneyOrNA(it?.otherExpensesSum),
          ...acc,
        }),
        {},
      ),
    },
    ...Object.entries(filledWithPlaceholdersCategoriesMapping?.otherExpenses ?? {}).map(
      ([category, values]) => {
        return {
          0: category,
          ...values?.reduce(
            (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it), ...acc }),
            {},
          ),
        }
      },
    ),
    {
      0: 'Net Other Income',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderMoneyOrNA(it?.netOtherIncome),
          ...acc,
        }),
        {},
      ),
    },
    {
      0: 'Net Profit',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({ [index + 1]: renderMoneyOrNA(it?.netProfit), ...acc }),
        {},
      ),
    },
    {
      0: 'Net Profit Margin',
      ...allProfitLosses?.reduce(
        (acc, it, index) => ({
          [index + 1]: renderPercentageOrNa(it?.netProfitMargin),
          ...acc,
        }),
        {},
      ),
    },
  ]
}
