import { Avatar, Box, Stack } from '@chakra-ui/react'

interface UserProps {
  data: {
    avatarURL?: string | null
    name?: string | null
    email: string
  }
}

export const UserCell = (props: UserProps) => {
  const { avatarURL, name, email } = props.data
  return (
    <Stack direction="row" spacing="4" align="center">
      <Avatar src={avatarURL ?? ''} name={name ?? email} />
      <Box>
        <Box fontSize="sm" fontWeight="medium">
          {name ?? '---'}
        </Box>
        <Box fontSize="sm" color="gray.500">
          {email}
        </Box>
      </Box>
    </Stack>
  )
}
