import { atomFamily } from 'jotai/utils'
import type {
  ProfitLossTable_ProfitLossQuery,
  ProfitLossTable_ProfitLossQueryVariables,
} from './documents.generated'
import { ProfitLossTable_ProfitLossDocument } from './documents.generated'
import { useQuery } from 'urql'
import type { SetStateAction } from 'react'
import { useEffect } from 'react'
import type { UseQueryState } from 'urql'
import { atom, useAtom } from 'jotai'

export interface ProfitLossQueries {
  [key: string]: ProfitLossTable_ProfitLossQueryVariables
}

export interface ProfitLossEngineState {
  id: string
  queries: ProfitLossQueries
  queryResult: {
    [key: string]: UseQueryState<
      ProfitLossTable_ProfitLossQuery,
      ProfitLossTable_ProfitLossQueryVariables
    >
  }
}

export interface ProfitLossFamilyInitialize {
  id: string
}

export const profitLossEngineAtom = atomFamily<
  ProfitLossFamilyInitialize,
  ProfitLossEngineState,
  SetStateAction<ProfitLossEngineState>
>(
  ({ id }) => {
    const anAtom = atom({ id, queries: {}, queryResult: {} })
    anAtom.onMount = (setAtom) => {
      return () => setAtom({ id, queries: {}, queryResult: {} })
    }
    return atom(
      (get) => get(anAtom),
      (_, set, update) => set(anAtom, update),
    )
  },
  (a, b) => a.id === b.id,
)

const ProfitLossDataFetcher = ({
  variables,
  familyId,
  id,
}: {
  id: string
  variables: ProfitLossTable_ProfitLossQueryVariables
  familyId: string
}) => {
  const [result] = useQuery({
    query: ProfitLossTable_ProfitLossDocument,
    variables,
  })

  const [, setProfitLossEngine] = useAtom(profitLossEngineAtom({ id: familyId }))

  useEffect(() => {
    void setProfitLossEngine((prev) => {
      return {
        ...prev,
        queryResult: {
          ...prev.queryResult,
          [id]: result,
        },
      }
    })
  }, [id, result, setProfitLossEngine])

  return null
}

export const ProfitLossEngine = ({ familyId }: { familyId: string }) => {
  const [profitLossEngineState] = useAtom(profitLossEngineAtom({ id: familyId }))
  const { queries } = profitLossEngineState
  return (
    <>
      {Object.entries(queries).map(([id, variables]) => {
        return (
          <ProfitLossDataFetcher
            key={id}
            id={id}
            familyId={familyId}
            variables={variables}
          />
        )
      })}
    </>
  )
}
