import type { CategoriesToValuesOrNullPlaceholders } from '../utils'
import { Td, Text, Tr } from '@chakra-ui/react'
import { renderMoneyOrNA } from '@liveflow-io/utils-common'
import { MotionBox } from '@liveflow-io/component-common'
import React, { useMemo } from 'react'
import { useReducedMotion } from 'packlets/hooks'

export const AnimatedRows = ({
  categoriesToValues,
  isOpen,
  color,
}: {
  categoriesToValues: CategoriesToValuesOrNullPlaceholders
  isOpen: boolean
  color?: string
}) => {
  return (
    <>
      {Object.entries(categoriesToValues).map(([category, values], index) => (
        <Tr
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <AnimatedCell isOpen={isOpen} index={index}>
            <PaddedAnimatedCellContent>{category}</PaddedAnimatedCellContent>
          </AnimatedCell>
          {values.map((value, anIndex) => (
            <AnimatedCell
              // eslint-disable-next-line react/no-array-index-key
              key={anIndex}
              color={color}
              isOpen={isOpen}
              index={index}
            >
              <AnimatedCellContent>{renderMoneyOrNA(value)}</AnimatedCellContent>
            </AnimatedCell>
          ))}
        </Tr>
      ))}
    </>
  )
}

const PaddedAnimatedCellContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text py={2} pl={10} pr={4}>
      {children}
    </Text>
  )
}

const AnimatedCellContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Text py={2} px={4}>
      {children}
    </Text>
  )
}

/**
 * Please DO NO EXTEND THIS ONE. If you need to change, change, but do not try to build
 * on top of this one anything except something for this feature. Copy paste and fine tune to your own use case
 */
export const AnimatedCell = ({
  index,
  isOpen,
  color,
  children,
}: {
  index: number
  isOpen: boolean
  color?: string
  children: React.ReactNode
}) => {
  const isMotionReduced = useReducedMotion()
  const pnlAnimationVariants = useMemo(
    () => ({
      open: (i: number) => ({
        height: 'auto',
        opacity: 1,
        x: 0,
        transition: {
          height: { duration: isMotionReduced ? 0 : 0.3 },
          opacity: {
            duration: isMotionReduced ? 0 : 0.1,
            delay: isMotionReduced ? 0 : (i + 1) * 0.075,
          },
          x: {
            ease: 'easeOut',
            duration: isMotionReduced ? 0 : 0.2,
            delay: isMotionReduced ? 0 : (i + 1) * 0.075,
          },
        },
      }),
      closed: {
        height: 0,
        opacity: 0,
        x: -50,
        transition: {
          height: { ease: 'easeOut', duration: isMotionReduced ? 0 : 0.4 },
          opacity: {
            duration: isMotionReduced ? 0 : 0.2,
          },
          x: {
            duration: isMotionReduced ? 0 : 0.5,
          },
        },
      },
    }),
    [isMotionReduced],
  )

  return (
    <Td p={0} borderBottomWidth={0} color={color}>
      <MotionBox
        overflow="hidden"
        h={0}
        opacity={0}
        custom={index}
        animate={isOpen ? 'open' : 'closed'}
        variants={pnlAnimationVariants}
      >
        {children}
      </MotionBox>
    </Td>
  )
}
