import type { Dayjs } from 'dayjs'
import { useAtom } from 'jotai'
import { profitLossEngineAtom } from '../components'
import { ATOM_FAMILY } from './atomFamiles'
import { useEffect } from 'react'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import type { DateRange } from 'packlets/atoms'

export const useHandleProfitLossQueriesChange = (
  selectedCategoryId: string,
  selectedCurrency: string,
  integrationIds: string[] | undefined,
  fourMonthsBeforeRecent: Dayjs,
  threeMonthsBeforeRecent: Dayjs,
  firstDateRange: DateRange,
  secondDateRange: DateRange,
) => {
  const [, setProfitLossEngineState] = useAtom(profitLossEngineAtom({ id: ATOM_FAMILY }))

  useEffect(() => {
    if (isNotEmptyOrNullish(selectedCategoryId) && isNotEmptyOrNullish(integrationIds)) {
      void setProfitLossEngineState((it) => ({
        ...it,
        queries: {
          inputFirst: {
            input: {
              selectedCurrency,
              filter: {
                integrationIds,
                categoryIds: [selectedCategoryId],
              },
              range: {
                from: fourMonthsBeforeRecent.startOf('month').toGraphQLDate(),
                to: fourMonthsBeforeRecent.endOf('month').toGraphQLDate(),
              },
            },
          },
          inputSecond: {
            input: {
              selectedCurrency,
              filter: {
                integrationIds,
                categoryIds: [selectedCategoryId],
              },
              range: {
                from: threeMonthsBeforeRecent.startOf('month').toGraphQLDate(),
                to: threeMonthsBeforeRecent.endOf('month').toGraphQLDate(),
              },
            },
          },
          inputThird: {
            input: {
              selectedCurrency,
              filter: {
                integrationIds,
                categoryIds: [selectedCategoryId],
              },
              range: {
                from: firstDateRange[0].toGraphQLDate(),
                to: firstDateRange[1].toGraphQLDate(),
              },
            },
          },
          inputFourth: {
            input: {
              selectedCurrency,
              filter: {
                integrationIds,
                categoryIds: [selectedCategoryId],
              },
              range: {
                from: secondDateRange[0].toGraphQLDate(),
                to: secondDateRange[1].toGraphQLDate(),
              },
            },
          },
        },
      }))
    }
  }, [
    firstDateRange,
    fourMonthsBeforeRecent,
    integrationIds,
    secondDateRange,
    selectedCategoryId,
    selectedCurrency,
    setProfitLossEngineState,
    threeMonthsBeforeRecent,
  ])
}
