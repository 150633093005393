import type { CommonIntegrations_IntegrationPayloadFragment } from 'packlets/queries'
import type { ConsolidatedDepartmentsProfitLoss_CategoriesFragment } from './documents.generated'
import {
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useCheckboxGroup,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { consolidatedDepartmentsStateAtom } from './atoms'
import { useDeepCompareEffect } from 'react-use'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { isDeepEqual } from 'react-use/lib/util'
import React, { useCallback } from 'react'
import { Card } from '@liveflow-io/component-common'
import { CheckboxButton } from 'packlets/components'

export const SelectDepartmentsButton = ({
  integrations,
  departments,
}: {
  integrations: CommonIntegrations_IntegrationPayloadFragment[]
  departments: ConsolidatedDepartmentsProfitLoss_CategoriesFragment[]
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [
    { departmentsNames, isInitialized, selectedDepartmentsNames, ...rest },
    dispatch,
  ] = useAtom(consolidatedDepartmentsStateAtom)
  useDeepCompareEffect(() => {
    if (
      !isInitialized &&
      isNotEmptyOrNullish(integrations) &&
      isNotEmptyOrNullish(departments)
    ) {
      void dispatch({
        type: 'init',
        payload: {
          integrations,
          departments,
        },
      })
      return
    }
    if (isInitialized && !isDeepEqual(integrations, rest.integrations)) {
      void dispatch({
        type: 'new-entities',
        newEntities: integrations,
      })
    }
    if (isInitialized && !isDeepEqual(departments, rest.departments)) {
      void dispatch({
        type: 'new-departments',
        newDepartments: departments,
      })
    }
  }, [integrations, departments])

  return (
    <>
      <Button variant="ghost" onClick={onOpen} colorScheme="blue">
        Select departments
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select departments</ModalHeader>
          <ModalCloseButton />
          {isNotEmptyOrNullish(rest.integrations) && (
            <CategoriesSelectorOptions
              integrations={rest.integrations}
              departmentsNames={departmentsNames}
            />
          )}
          <ModalFooter>
            <Button
              isDisabled={selectedDepartmentsNames.length === 0}
              mr={3}
              colorScheme="blue"
              onClick={() => {
                onClose()
                void dispatch({
                  type: 'submit-selection',
                })
              }}
            >
              Apply
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
const CategoriesSelectorOptions = ({
  departmentsNames,
  integrations,
}: {
  departmentsNames: string[]
  integrations: CommonIntegrations_IntegrationPayloadFragment[]
}) => {
  const [{ selectedDepartmentsNames, selectedEntityIds }, dispatch] = useAtom(
    consolidatedDepartmentsStateAtom,
  )

  const { getCheckboxProps: getIntegrationsCheckboxProps } = useCheckboxGroup({
    value: selectedEntityIds,
    onChange: useCallback(
      (value) => {
        void dispatch({
          type: 'update-entities-selected',
          selectedEntityIds: value as string[],
        })
      },
      [dispatch],
    ),
  })

  const { getCheckboxProps: getDepartmentNamesCheckboxProps } = useCheckboxGroup({
    value: selectedDepartmentsNames,
    onChange: useCallback(
      (value) => {
        void dispatch({
          type: 'update-departments-selection',
          selectedDepartmentsNames: value as string[],
        })
      },
      [dispatch],
    ),
  })

  return (
    <ModalBody as={Stack} spacing={4}>
      <Card as={Stack}>
        <Heading mb={6} size="md">
          Entities
        </Heading>
        {integrations.map((integration) => {
          return (
            <CheckboxButton
              key={integration.integrationId}
              {...getIntegrationsCheckboxProps({ value: integration.integrationId })}
              icon={<div />}
              title={integration.name}
            />
          )
        })}
      </Card>

      {isNotEmptyOrNullish(selectedEntityIds) && (
        <Card as={Stack}>
          <Heading mb={6} size="md">
            Departments
          </Heading>
          {departmentsNames.map((name) => {
            return (
              <CheckboxButton
                key={name}
                {...getDepartmentNamesCheckboxProps({ value: name })}
                icon={<div />}
                title={name}
              />
            )
          })}
        </Card>
      )}
    </ModalBody>
  )
}
