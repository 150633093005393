import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import { DeleteIntegration_IntegrationPayloadDocument } from './documents.generated'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { nameAtom, selectedDisconnectIntegrationIdAtom } from './atoms'
import React, { useCallback, useRef } from 'react'
import { impossibleState, isNotEmptyOrNullish } from '@liveflow-io/utils-common'

export const DeleteIntegration = () => {
  const [, deleteIntegration] = useEnhancedMutation(
    DeleteIntegration_IntegrationPayloadDocument,
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const [selectedDisconnectIntegrationId, setSelectedDisconnectIntegrationId] = useAtom(
    selectedDisconnectIntegrationIdAtom,
  )

  const [name, setName] = useAtom(nameAtom)

  const onCloseDisconnectModal = useCallback(() => {
    void setSelectedDisconnectIntegrationId(null)
  }, [setSelectedDisconnectIntegrationId])

  const onDisconnectIntegration = useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault()
      if (isNotEmptyOrNullish(selectedDisconnectIntegrationId)) {
        deleteIntegration({
          id: selectedDisconnectIntegrationId,
        })
          .then((res) => {
            switch (res.state) {
              case 'partial':
              case 'done':
                void setSelectedDisconnectIntegrationId(null)
                onOpen()
                break
              case 'error':
                toast({
                  description: 'There was some problem deleting your integration',
                  status: 'error',
                  title: 'Something went wrong',
                })
                break
              default:
                impossibleState(res)
            }
            return res
          })
          .catch(console.error)
      }
    },
    [
      deleteIntegration,
      onOpen,
      setSelectedDisconnectIntegrationId,
      selectedDisconnectIntegrationId,
      toast,
    ],
  )

  const onCloseDeleteConfirmation = useCallback(() => {
    onClose()
    void setName('')
  }, [setName, onClose])

  const cancelDeleteRef = useRef(null)
  const closeConfirmationRef = useRef(null)

  return (
    <>
      <AlertDialog
        isOpen={isNotEmptyOrNullish(selectedDisconnectIntegrationId)}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDisconnectModal}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>
            Are you sure you want to disconnect {name}?
          </AlertDialogHeader>
          <AlertDialogBody>
            Your data will not update any longer. You can always re-authenticate the
            integration.
          </AlertDialogBody>

          <AlertDialogFooter as={Stack} spacing={2} direction="row">
            <Button colorScheme="red" onClick={onDisconnectIntegration}>
              Yes
            </Button>
            <Button ref={cancelDeleteRef} onClick={onCloseDisconnectModal}>
              No
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={closeConfirmationRef}
        onClose={onCloseDeleteConfirmation}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Your {name} has been disconnected</AlertDialogHeader>
          <AlertDialogBody>
            You can always re-authenticate the integration to regain access
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={closeConfirmationRef} onClick={onCloseDeleteConfirmation}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
