import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react'
import {
  FixedThemeToggleButton,
  GenericError,
  GenericSpinner,
} from '@liveflow-io/component-common'
import { Navigate } from 'react-router-dom'
import { Routes } from 'packlets/constants'
import { useQuery } from 'urql'
import { NotEnabledPage_IsEnabledDocument } from './document.generated'
import React from 'react'
import { AuthService } from 'services'
import { FaDoorOpen } from 'react-icons/fa'

export const NotEnabled = () => {
  const [enabledResult] = useQuery({
    query: NotEnabledPage_IsEnabledDocument,
    requestPolicy: 'cache-and-network',
  })

  if (enabledResult.fetching) {
    return <GenericSpinner />
  }

  if (enabledResult.error) {
    return <GenericError />
  }

  if (enabledResult.data?.enabled) {
    return <Navigate to={Routes.BANK_DASHBOARD} />
  }

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      w="100vw"
      h="100vh"
    >
      <FixedThemeToggleButton />
      <Box position="fixed" right={4} top={4}>
        <Button pl={2} pr={2} onClick={AuthService.logout} fontSize="lg">
          <FaDoorOpen /> <Text ml={2}>Logout</Text>
        </Button>
      </Box>
      <Heading as="h1" size="xl">
        Access denied
      </Heading>
      <Text fontSize="2xl">
        If you wish to obtain access to our app, please ping us on{' '}
        <a href="mailto:support@liveflow.io">support@liveflow.io</a>
      </Text>
    </Flex>
  )
}
