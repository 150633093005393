import type { FlexProps } from '@chakra-ui/react'
import {
  Box,
  Flex,
  HStack,
  Img,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useMenuButton,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { HiSelector } from 'react-icons/hi'
import { AuthService } from 'services'
import type { Company } from '../../generated'

const AccountSwitcherButton = ({
  company,
  ...props
}: FlexProps & { company: Company }) => {
  const bg = useColorModeValue('gray.100', 'gray.700')
  const color = useColorModeValue('grey.900', 'white')
  const subtleColor = useColorModeValue('gray.700', 'gray.400')
  const activeBgColor = useColorModeValue('gray.200', 'gray.600')
  const buttonProps = useMenuButton(props)
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg={bg}
      px="3"
      color={color}
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: activeBgColor }}
      _focus={{ shadow: 'outline' }}
    >
      <HStack flex="1" spacing="3">
        <Img
          w="8"
          h="8"
          rounded="md"
          objectFit="cover"
          src={AuthService.getProfile()?.picture}
          alt="Chakra UI"
        />
        <Box textAlign="start">
          <Box fontWeight="semibold">{company.name}</Box>
          <Box fontSize="xs" color={subtleColor}>
            {AuthService.getProfile()?.name}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg" color={subtleColor}>
        <HiSelector />
      </Box>
    </Flex>
  )
}

export const AccountSwitcher = ({ company }: { company: Company }) => {
  const textColor = useColorModeValue('gray.600', 'gray.100')
  return (
    <Menu>
      <Box>
        <AccountSwitcherButton company={company} />
        <MenuList shadow="lg" py="4" color={textColor} px="3">
          <Text fontWeight="medium" mb="2">
            {AuthService.getProfile()?.email}
          </Text>
          <MenuDivider />
          <MenuItem rounded="md" onClick={AuthService.logout}>
            Logout
          </MenuItem>
        </MenuList>
      </Box>
    </Menu>
  )
}
