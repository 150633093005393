// Required to initialize AuthService instance
// eslint-disable-next-line no-restricted-imports
import 'services/AuthService'

import 'dayjs/plugin/utc'
import 'dayjs/plugin/isBetween'
import 'dayjs/plugin/isSameOrAfter'
import 'dayjs/plugin/isSameOrBefore'
import 'dayjs/plugin/weekday'
import 'dayjs/plugin/quarterOfYear'
import 'dayjs/plugin/advancedFormat'

import { getEnvByHostname } from '@liveflow-io/utils-common'
import { hotjar } from 'react-hotjar'
import { reportWebVitals } from './reportWebVitals'

import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ChakraProvider, GlobalStyle } from '@chakra-ui/react'
import { BrowserRouter as Router } from 'react-router-dom'
import { AuthProvider, UrqlProvider } from 'providers'
import { Provider as JotaiProvider } from 'jotai'
import { App } from './pages'
import { customTheme } from './theme'

hotjar.initialize(getEnvByHostname() === 'production' ? 2196732 : 2199700, 6)

ReactDOM.render(
  <StrictMode>
    <ChakraProvider theme={customTheme}>
      <GlobalStyle />
      <AuthProvider>
        <UrqlProvider>
          <Router>
            <JotaiProvider>
              <App />
            </JotaiProvider>
          </Router>
        </UrqlProvider>
      </AuthProvider>
    </ChakraProvider>
  </StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
