import type { Dayjs } from 'dayjs'
import { isNotEmptyOrNullish, utcDayJs } from '@liveflow-io/utils-common'
import { PersistenceService } from '../services'
import { atomWithReducer } from 'jotai/utils'
import { getFromParamsOrLocalStorage, getSearchParams } from '../utils'
import type { NonFunction } from 'jotai/core/types'

export const persistedJsonAtom = <T = unknown>(
  key: string,
  defaultState: NonFunction<T>,
) => {
  return atomWithReducer<T, T>(
    getFromParamsOrLocalStorage(key, defaultState),
    (_prev, update: T) => {
      PersistenceService.set(key, update)
      return update
    },
  )
}
export type DateRange = [Dayjs, Dayjs]

export const persistedDateRangeAtom = (key: string, defaultValue: DateRange) => {
  const initValue = () => {
    const urlParams = getSearchParams()
    if (urlParams.has(key)) {
      const stringDateRange = JSON.parse(urlParams.get(key) ?? '') as string[]
      return [
        utcDayJs(stringDateRange[0], 'YYYY-MM-DD'),
        utcDayJs(stringDateRange[1], 'YYYY-MM-DD'),
      ] as DateRange
    }
    const persisted = PersistenceService.get<string[]>(key)
    if (isNotEmptyOrNullish(persisted)) {
      return [
        utcDayJs(persisted[0], 'YYYY-MM-DD'),
        utcDayJs(persisted[1], 'YYYY-MM-DD'),
      ] as DateRange
    }
    return defaultValue
  }
  return atomWithReducer<DateRange, DateRange>(
    initValue(),
    (_prev, [start, end]: DateRange) => {
      PersistenceService.set(key, [start.formatYyyyMmDd(), end.formatYyyyMmDd()])
      return [start, end]
    },
  )
}
