import type { CombinedError } from 'urql'
import type { TypeOf } from 'zod'
import { object, string } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import React, { useCallback, useEffect, useState } from 'react'
import { impossibleState, isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { INVITE_EVENTS, ONBOARDING_EVENTS, TrackingService } from 'packlets/services'
import { Card, LabeledInput } from '@liveflow-io/component-common'
import { Box, Button, Center, Divider, Heading, Image, Stack } from '@chakra-ui/react'
import liveflowLogo from 'static/liveflow-logo-big.png'
import type { Onboarding_CreateUserPayloadFragment } from './documents.generated'
import { Onboarding_CreateUserPayloadDocument } from './documents.generated'

const userCreateSchema = object({
  firstName: string().nonempty(),
})

type CreateUserType = TypeOf<typeof userCreateSchema>
type UserFormStates =
  | { state: 'waiting-for-input' }
  | {
      state: 'server-error'
      error: CombinedError
    }
  | { state: 'creating-user'; input: CreateUserType }
  | { state: 'user-created'; result: Onboarding_CreateUserPayloadFragment }

export const UserForm = () => {
  const { register, handleSubmit, errors, setError } = useForm<CreateUserType>({
    resolver: zodResolver(userCreateSchema),
  })
  const [, createUser] = useEnhancedMutation(Onboarding_CreateUserPayloadDocument)
  const toast = useToast()

  const [payload, setState] = useState<UserFormStates>(() => ({
    state: 'waiting-for-input',
  }))

  const onSubmit = useCallback((input: CreateUserType) => {
    setState({ state: 'creating-user', input })
  }, [])

  useEffect(() => {
    const inviteCode = localStorage.getItem('inviteCode')
    switch (payload.state) {
      case 'creating-user':
        createUser({ input: { inviteCode, ...payload.input } })
          .then((response) => {
            switch (response.state) {
              case 'done':
                {
                  if (isNotEmptyOrNullish(inviteCode)) {
                    TrackingService.track(INVITE_EVENTS.INVITE_CODE_USED)
                    localStorage.removeItem('inviteCode')
                  }
                  const result = response.data.onboardingUserCreate
                  setState({ state: 'user-created', result })
                  TrackingService.identify(result.result.user.id, result.result.user)
                }
                break
              case 'partial':
              case 'error':
                setState({
                  state: 'server-error',
                  error: response.error,
                })
                break
              default:
                impossibleState(response)
            }
            return response
          })
          .catch(console.error)
        break

      case 'server-error':
        toast({
          title: 'Oh no! Something went wrong!',
          status: 'error',
          description: JSON.stringify(payload.error),
        })
        setState({ state: 'waiting-for-input' })
        break

      case 'user-created':
        TrackingService.track(ONBOARDING_EVENTS.USER_CREATED)
        break

      case 'waiting-for-input':
        break
      default:
        impossibleState(payload)
    }
  }, [createUser, payload, setError, toast])

  const submitting = payload.state === 'creating-user' || payload.state === 'user-created'

  return (
    <Card as="form" w={['100%', '30em']} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={4}>
        <Center my={4}>
          <Image src={liveflowLogo} width={150} alt="Liveflow logo" />
        </Center>
        <Divider />
        <Heading as="h1" size="md">
          👋🏼 Welcome - Great to see you here! Please share your first name.
        </Heading>
        <Box>
          <LabeledInput
            label="First name*"
            placeholder="John"
            ref={register}
            name="firstName"
            errorLabel={errors.firstName?.message}
            isDisabled={submitting}
            isInvalid={!!errors.firstName}
          />
        </Box>
      </Stack>
      <Button
        mt={8}
        type="submit"
        colorScheme="blue"
        isLoading={submitting}
        loadingText="Saving..."
      >
        Save
      </Button>
    </Card>
  )
}
