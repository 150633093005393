import type { CommonIntegrations_IntegrationPayloadFragment } from 'packlets/queries'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useCheckboxGroup,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { consolidatedEntitiesStateAtom } from './atoms'
import { useDeepCompareEffect } from 'react-use'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { isDeepEqual } from 'react-use/lib/util'
import React, { useCallback } from 'react'
import { CheckboxButton } from 'packlets/components'

export const SelectEntityButton = ({
  integrations,
}: {
  familyId: string
  integrations: CommonIntegrations_IntegrationPayloadFragment[]
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  const [{ isInitialized, ...rest }, dispatch] = useAtom(consolidatedEntitiesStateAtom)

  useDeepCompareEffect(() => {
    if (!isInitialized && isNotEmptyOrNullish(integrations)) {
      void dispatch({
        type: 'init',
        payload: {
          integrations,
        },
      })
      return
    }
    if (isInitialized && !isDeepEqual(integrations, rest.integrations)) {
      void dispatch({
        type: 'new-entities',
        newEntities: integrations,
      })
    }
  }, [integrations, rest.integrations])

  return (
    <>
      <Button variant="ghost" onClick={onOpen} colorScheme="blue">
        Select entities
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select entities</ModalHeader>
          <ModalCloseButton />
          {isNotEmptyOrNullish(rest.integrations) && (
            <EntitySelectorOptions integrations={rest.integrations} />
          )}
          <ModalFooter>
            <Button
              mr={3}
              colorScheme="blue"
              onClick={() => {
                onClose()
                void dispatch({
                  type: 'submit-selection',
                })
              }}
            >
              Apply
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
const EntitySelectorOptions = ({
  integrations,
}: {
  integrations: CommonIntegrations_IntegrationPayloadFragment[]
}) => {
  const [{ selectedEntityIds }, dispatch] = useAtom(consolidatedEntitiesStateAtom)

  const { getCheckboxProps } = useCheckboxGroup({
    value: selectedEntityIds,
    onChange: useCallback(
      (value) => {
        void dispatch({
          type: 'update-entities-selected',
          selectedEntityIds: value as string[],
        })
      },
      [dispatch],
    ),
  })

  return (
    <ModalBody as={Stack}>
      {integrations.map((integration) => {
        return (
          <CheckboxButton
            key={integration.integrationId}
            {...getCheckboxProps({ value: integration.integrationId })}
            icon={<div />}
            title={integration.name}
          />
        )
      })}
    </ModalBody>
  )
}
