import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { getSearchParams } from './location'
import { PersistenceService } from '../services'
import type { NonFunction } from 'jotai/core/types'

export const serializeToUrl = (key: string, value: any) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  urlSearchParams.set(key, JSON.stringify(value))
  window.history.replaceState(
    null,
    document.title,
    `${window.location.href.split('?')[0]}?${urlSearchParams.toString()}`,
  )
}

export const getFromParamsOrLocalStorage = <T>(
  key: string,
  defaultState: NonFunction<T>,
) => {
  const urlParams = getSearchParams()
  if (urlParams.has(key)) {
    return JSON.parse(urlParams.get(key) ?? '') as NonFunction<T>
  }
  const persisted = PersistenceService.get(key)
  if (isNotEmptyOrNullish(persisted)) {
    return persisted as NonFunction<T>
  }
  return defaultState
}

export const getValuesFromLocalStorageAndConvertToUrlParams = (
  localStorageKeys: string[],
  omit: string[] = [],
) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  localStorageKeys
    .filter((it) => !omit.includes(it))
    .forEach((localStorageKey) => {
      const value = localStorage.getItem(localStorageKey)
      isNotEmptyOrNullish(value) && urlSearchParams.set(localStorageKey, value)
    })
  return urlSearchParams.toString()
}
