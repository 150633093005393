import type { ButtonProps } from '@chakra-ui/react'
import { Button } from '@chakra-ui/react'
import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import { XeroButton_ConnectDocument } from './documents.generated'
import { FaPlus } from 'react-icons/fa'
import { INTEGRATION_EVENTS, TrackingService } from '../../services'
import { saveNavigateBackLocation } from '../../hooks'
import { impossibleState } from '@liveflow-io/utils-common'
import React from 'react'

export const XeroButton = ({ onClick, ...props }: ButtonProps) => {
  const [, xeroLinkCreate] = useEnhancedMutation(XeroButton_ConnectDocument)
  const toast = useToast()

  return (
    <Button
      leftIcon={<FaPlus />}
      {...props}
      onClick={(e) => {
        TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_ACCOUNTING_STARTED)
        saveNavigateBackLocation()
        onClick?.(e)
        xeroLinkCreate()
          .then((res) => {
            switch (res.state) {
              case 'done':
              case 'partial':
                window.location.assign(res.data.xeroLinkCreate)
                break
              case 'error':
                toast({
                  title: 'Oh! No!',
                  description:
                    'Something went wrong while generating redirect link for QuickBooks',
                  status: 'error',
                })
                break
              default:
                impossibleState(res)
            }
            return res
          })
          .catch(console.error)
      }}
    >
      Connect
    </Button>
  )
}
