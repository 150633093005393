import React from 'react'
import { useAtomValue } from 'jotai/utils'
import { connectedToInfoRenderAtom } from '../atoms'
import { Box, Button, Divider, Heading, HStack, Stack } from '@chakra-ui/react'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { useToast } from '@liveflow-io/hooks-common'
import { useCopyToClipboard } from 'react-use'
import {
  buildCurrentUrlWithParams,
  getValuesFromLocalStorageAndConvertToUrlParams,
} from 'packlets/utils'

export const ProfitLossShell: React.FC<{
  heading: string
  persistenceService: { keys: string[] }
}> = ({ children, heading, persistenceService }) => {
  const connectedToInfoRender = useAtomValue(connectedToInfoRenderAtom)
  const toast = useToast()
  const [, copy] = useCopyToClipboard()

  return (
    <Box h="full">
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        justify="space-between"
        align="center"
        spacing={8}
      >
        <HStack spacing={8}>
          <Heading as="h1" size="lg">
            {heading}
          </Heading>
        </HStack>
        <HStack spacing={8}>
          {isNotEmptyOrNullish(connectedToInfoRender) && (
            <Button
              onClick={() => {
                toast({
                  status: 'info',
                  title: 'Great, URL copied to clipboard!',
                  description:
                    'Feel free share it with your colleagues and please note they should be logged in.',
                })
                copy(
                  buildCurrentUrlWithParams(
                    getValuesFromLocalStorageAndConvertToUrlParams(
                      persistenceService.keys,
                    ),
                  ),
                )
              }}
            >
              Share this view
            </Button>
          )}
          {isNotEmptyOrNullish(connectedToInfoRender) && connectedToInfoRender}
        </HStack>
      </Stack>
      <Divider mt={8} />
      <Stack spacing={6} mt={10}>
        {children}
      </Stack>
    </Box>
  )
}
