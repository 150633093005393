import { useIntegrationsQuery } from '../queries'
import { IntegrationSource, IntegrationStatus } from '../generated'
import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from '../queries'

export const useAllAccountingIntegrations = () => {
  const [integrationsResult, refetch] = useIntegrationsQuery()
  if (!integrationsResult.data) {
    return [integrationsResult, refetch] as const
  }

  return [
    {
      ...integrationsResult,
      data: {
        integrations: integrationsResult.data.integrations.filter(
          (it): it is CommonIntegrations_CommonAccountingIntegrationPayloadFragment =>
            it.integrationSource === IntegrationSource.Codat,
        ),
      },
    },
    refetch,
  ] as const
}

export const useAllNativeAccountingIntegrations = () => {
  const [integrationsResult, refetch] = useIntegrationsQuery()
  if (!integrationsResult.data) {
    return [integrationsResult, refetch] as const
  }

  return [
    {
      ...integrationsResult,
      data: {
        integrations: integrationsResult.data.integrations.filter(
          (it): it is CommonIntegrations_CommonAccountingIntegrationPayloadFragment =>
            it.integrationSource === IntegrationSource.QuickBooks ||
            it.integrationSource === IntegrationSource.Xero,
        ),
      },
    },
    refetch,
  ] as const
}

export const useCompletedNativeAccountingIntegrations = () => {
  const [allAccountingIntegrationsResult, refetch] = useAllNativeAccountingIntegrations()
  if (!allAccountingIntegrationsResult.data) {
    return [allAccountingIntegrationsResult, refetch] as const
  }

  return [
    {
      ...allAccountingIntegrationsResult,
      data: {
        integrations: allAccountingIntegrationsResult.data.integrations.filter(
          (it) => it.status === IntegrationStatus.Completed,
        ),
      },
    },
    refetch,
  ] as const
}

export const usePendingNativeAccountingIntegrations = () => {
  const [allAccountingIntegrationsResult, refetch] = useAllNativeAccountingIntegrations()
  if (!allAccountingIntegrationsResult.data) {
    return [allAccountingIntegrationsResult, refetch] as const
  }

  return [
    {
      ...allAccountingIntegrationsResult,
      data: {
        integrations: allAccountingIntegrationsResult.data.integrations.filter(
          (it) => it.status === IntegrationStatus.Pending,
        ),
      },
    },
    refetch,
  ] as const
}

export const useCompletedAccountingIntegrations = () => {
  const [allAccountingIntegrationsResult, refetch] = useAllAccountingIntegrations()
  if (!allAccountingIntegrationsResult.data) {
    return [allAccountingIntegrationsResult, refetch] as const
  }

  return [
    {
      ...allAccountingIntegrationsResult,
      data: {
        integrations: allAccountingIntegrationsResult.data.integrations.filter(
          (it) => it.status === IntegrationStatus.Completed,
        ),
      },
    },
    refetch,
  ] as const
}

export const usePendingAccountingIntegrations = () => {
  const [allAccountingIntegrationsResult, refetch] = useAllAccountingIntegrations()
  if (!allAccountingIntegrationsResult.data) {
    return [allAccountingIntegrationsResult, refetch] as const
  }

  return [
    {
      ...allAccountingIntegrationsResult,
      data: {
        integrations: allAccountingIntegrationsResult.data.integrations.filter(
          (it) => it.status === IntegrationStatus.Pending,
        ),
      },
    },
    refetch,
  ] as const
}
