import React from 'react'
import type { HeadingProps } from '@chakra-ui/react'
import { Heading, useColorModeValue, useTheme } from '@chakra-ui/react'
import { get } from 'lodash'

export const UnderscoredHeading: React.FC<HeadingProps> = ({ children, ...rest }) => {
  const { colors } = useTheme()
  const blueColor = get(colors, useColorModeValue('blue.500', 'blue.300'))
  return (
    <Heading
      as="h1"
      size="lg"
      w="max-content"
      borderBottomColor={blueColor}
      borderBottomWidth="3px"
      paddingBottom={2}
      {...rest}
    >
      {children}
    </Heading>
  )
}
