import type { CommonIntegrations_IntegrationPayloadFragment } from 'packlets/queries'
import { atomWithReducer } from 'jotai/utils'
import produce from 'immer'
import { impossibleState, utcDayJs } from '@liveflow-io/utils-common'
import { persistedDateRangeAtom, persistedJsonAtom } from 'packlets/atoms'
import { getFromParamsOrLocalStorage } from 'packlets/utils'
import { ProfitLossConsolidatedEntitiesPersistence } from './persistence'

export const ATOM_FAMILY = 'Consolidated per entity'

type ConsolidatedEntitiesActions =
  | {
      type: 'init'
      payload: {
        integrations: CommonIntegrations_IntegrationPayloadFragment[]
      }
    }
  | {
      type: 'new-entities'
      newEntities: CommonIntegrations_IntegrationPayloadFragment[]
    }
  | { type: 'update-entities-selected'; selectedEntityIds: string[] }
  | { type: 'submit-selection' }

type ConsolidatedEntitiesState = {
  isInitialized: boolean
  selectedEntityIds: string[]
  selectedEntities: CommonIntegrations_IntegrationPayloadFragment[]
  integrations: CommonIntegrations_IntegrationPayloadFragment[]
}

const consolidatedEntitiesStateReducer = (
  state: ConsolidatedEntitiesState,
  action: ConsolidatedEntitiesActions,
) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
      }
    }
    case 'new-entities':
      return produce(state, (draft) => {
        draft.selectedEntities = []
        draft.selectedEntityIds = []
        draft.integrations = action.newEntities
      })
    case 'update-entities-selected':
      return produce(state, (draft) => {
        draft.selectedEntityIds = action.selectedEntityIds
      })
    case 'submit-selection':
      return produce(state, (draft) => {
        // Select entities based on selected ids
        draft.selectedEntities = draft.integrations.filter((integrations) =>
          draft.selectedEntityIds.includes(integrations.integrationId),
        )
      })
    default:
      return impossibleState(action)
  }
}

export const consolidatedEntitiesStateAtom = atomWithReducer(
  getFromParamsOrLocalStorage(
    ProfitLossConsolidatedEntitiesPersistence.CONSOLIDATED_DEPARTMENTS_PAGE_STATE,
    {
      isInitialized: false,
      selectedEntities: [],
      selectedEntityIds: [],
      integrations: [],
    } as ConsolidatedEntitiesState,
  ),
  (state, action: ConsolidatedEntitiesActions) => {
    const newState = consolidatedEntitiesStateReducer(state, action)
    ProfitLossConsolidatedEntitiesPersistence.setConsolidatedEntitiesPageState(newState)
    return newState
  },
)

export const selectedRangeAtom = persistedDateRangeAtom(
  ProfitLossConsolidatedEntitiesPersistence.PERIOD_DATE_RANGE_SELECTOR,
  [utcDayJs().startOf('month'), utcDayJs().endOf('month')],
)

export const selectedCurrencyAtom = persistedJsonAtom(
  ProfitLossConsolidatedEntitiesPersistence.CURRENCY_SELECT,
  'USD',
)
