import React, { useMemo } from 'react'
import { retryExchange } from '@urql/exchange-retry'
import { createClient, dedupExchange, errorExchange, fetchExchange, Provider } from 'urql'
import { devtoolsExchange } from '@urql/devtools'
import { refocusExchange } from '@urql/exchange-refocus'
import type { Cache } from '@urql/exchange-graphcache'
import { offlineExchange } from '@urql/exchange-graphcache'
import type { IntrospectionQuery } from 'graphql'
import type {
  CreateUserPayload,
  IntegrationPayload,
  InviteUserPayload,
  PlaidIntegrationPayload,
  Query,
  TrueLayerIntegrationPayload,
} from '../generated'
import { introspection } from '../generated'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { makeDefaultStorage } from '@urql/exchange-graphcache/default-storage'
import type { CombinedError, Operation } from '@urql/core'

const storage = makeDefaultStorage({
  idbName: 'graphcache-v3', // The name of the IndexedDB database
  maxAge: 1, // The maximum age of the persisted data in days
})

const dropAllQueriesWithField = (field: keyof Query, cache: Cache) => {
  const queries = cache.inspectFields('Query').filter((x) => x.fieldName === field)
  queries.forEach(({ fieldName, arguments: variables }) => {
    cache.invalidate('Query', fieldName, variables ?? undefined)
  })
}

type ErrorExchangeOptions = {
  onError: (error: CombinedError, operation: Operation) => void
}

export const buildUrqlProvider = ({
  useAccessToken,
  onError,
}: {
  useAccessToken: () => string | undefined
  onError: ErrorExchangeOptions['onError']
}): React.FC => ({ children }) => {
  const token = useAccessToken()
  const client = useMemo(() => {
    const options: Parameters<typeof retryExchange>[0] = {
      initialDelayMs: 1000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (err) => !!err.networkError,
    }

    return createClient({
      suspense: true,
      url: '/graphql',
      exchanges: [
        devtoolsExchange,
        dedupExchange,
        refocusExchange(),
        offlineExchange({
          storage,
          schema: (introspection as unknown) as IntrospectionQuery,
          keys: {
            Bank: () => null,
            BurnRateDashboardSection: () => null,
            DashboardSection: () => null,
            CommonAccountingIntegrationPayload: (data) =>
              data.__typename + data?.integrationId,
            CommonBankIntegrationPayload: (data) => data.__typename + data?.integrationId,
            CommonIntegrationPayload: (data) => data.__typename + data?.integrationId,
            CompanyIntegrationsInfo: () => null,
            IntegrationPayload: (data) => data.__typename + data?.integrationId,
            Money: () => null,
            MoneyHistoryPoint: () => null,
            MonthMoneyHistoryPoint: () => null,
            NumberHistoryPoint: () => null,
            PlaidIntegrationPayload: (data) => data.__typename + data?.integrationId,
            NewProfitLoss: () => null,
            ProfitLossItem: () => null,
            RunwaySection: () => null,
            UserSettings: () => null,
          },
          updates: {
            Mutation: {
              onboardingUserCreate: (data, args, cache) => {
                const onboardingUserCreate = data.onboardingUserCreate as CreateUserPayload | null
                if (onboardingUserCreate?.result) {
                  dropAllQueriesWithField('me', cache)
                  dropAllQueriesWithField('userAdministrationUsers', cache)
                }
              },
              userAdministrationInviteUser: (data, args, cache) => {
                const userAdministrationInviteUser = data.userAdministrationInviteUser as InviteUserPayload | null
                if (userAdministrationInviteUser) {
                  dropAllQueriesWithField('userAdministrationUsers', cache)
                }
              },
              deleteUser: (data, args, cache) => {
                const deleteUser = data.deleteUser as string | null
                if (isNotEmptyOrNullish(deleteUser)) {
                  dropAllQueriesWithField('userAdministrationUsers', cache)
                }
              },
              deleteUserInvite: (data, args, cache) => {
                const deleteUserInvite = data.deleteUserInvite as string | null
                if (isNotEmptyOrNullish(deleteUserInvite)) {
                  dropAllQueriesWithField('userAdministrationUsers', cache)
                }
              },
              integrationRename: (data, args, cache) => {
                const integrationRename = data.integrationRename as IntegrationPayload | null
                if (integrationRename) {
                  dropAllQueriesWithField('integrations', cache)
                  dropAllQueriesWithField('newProfitLoss', cache)
                  dropAllQueriesWithField('profitLossCategories', cache)
                }
              },
              integrationDelete: (data, args, cache) => {
                const integrationDelete = data.integrationDelete as IntegrationPayload | null
                if (integrationDelete) {
                  dropAllQueriesWithField('integrations', cache)
                  dropAllQueriesWithField('newProfitLoss', cache)
                  dropAllQueriesWithField('profitLossCategories', cache)
                }
              },
              plaidIntegrate: (data, args, cache) => {
                const plaidIntegrate = data.plaidIntegrate as PlaidIntegrationPayload | null
                if (plaidIntegrate) {
                  dropAllQueriesWithField('integrations', cache)
                  dropAllQueriesWithField('banks', cache)
                  dropAllQueriesWithField('balance', cache)
                  dropAllQueriesWithField('burnRate', cache)
                  dropAllQueriesWithField('cashIn', cache)
                  dropAllQueriesWithField('cashOut', cache)
                  dropAllQueriesWithField('runway', cache)
                }
              },
              trueLayerIntegrate: (data, args, cache) => {
                const trueLayerIntegrate = data.trueLayerIntegrate as TrueLayerIntegrationPayload | null
                if (trueLayerIntegrate) {
                  dropAllQueriesWithField('integrations', cache)
                  dropAllQueriesWithField('banks', cache)
                  dropAllQueriesWithField('balance', cache)
                  dropAllQueriesWithField('burnRate', cache)
                  dropAllQueriesWithField('cashIn', cache)
                  dropAllQueriesWithField('cashOut', cache)
                  dropAllQueriesWithField('runway', cache)
                }
              },
              xeroIntegrate: (data, args, cache) => {
                const xeroIntegrate = data.xeroIntegrate as string | null
                if (isNotEmptyOrNullish(xeroIntegrate)) {
                  dropAllQueriesWithField('integrations', cache)
                  dropAllQueriesWithField('newProfitLoss', cache)
                  dropAllQueriesWithField('profitLossCategories', cache)
                }
              },
              quickBooksIntegrate: (data, args, cache) => {
                const quickBooksIntegrate = data.quickBooksIntegrate as string | null
                if (isNotEmptyOrNullish(quickBooksIntegrate)) {
                  dropAllQueriesWithField('integrations', cache)
                  dropAllQueriesWithField('newProfitLoss', cache)
                  dropAllQueriesWithField('profitLossCategories', cache)
                }
              },
            },
          },
        }),
        retryExchange(options),
        errorExchange({
          onError,
        }),
        fetchExchange,
      ],
      fetchOptions: () => {
        return {
          headers: { authorization: isNotEmptyOrNullish(token) ? `Bearer ${token}` : '' },
        }
      },
    })
  }, [token])

  return <Provider value={client}>{children}</Provider>
}
