import React, { useEffect } from 'react'
import { CurrentUserService, TrackingService } from '../services'
import type { MeProvider_MeQuery, MeProvider_UserFragment } from './documents.generated'
import type { UseQueryState } from 'urql'

export const MeContext = React.createContext<{
  me: MeProvider_UserFragment
  enabled: boolean
}>({
  enabled: false,
  // @ts-expect-error
  me: {},
})

/**
 * This shall be used ONLY inside SecuredApp!
 * It's done so we do not take data from URQL
 * cache way too often as `me` used almost
 * everywhere but never needs to be refetched mostly.
 */
export const MeProvider: React.FC<{
  result: UseQueryState<MeProvider_MeQuery>
}> = ({ result, children }) => {
  useEffect(() => {
    if (result.data?.me) {
      CurrentUserService.setUserInfo(result.data.me)
      TrackingService.identify(result.data.me.id, result.data.me)
    }
    // Hack below ensures we do not re-trigger effect if data is not changed properly (key will change then)
    // Graphcache + URQL doesn't guarantee reference equality between re-renders
    // eslint-disable-next-line
  }, [result.operation?.key])

  return (
    <MeContext.Provider
      // We're sure that this one is actually will be not null 100% as this Context shall
      // be used ONLY inside SecuredApp that will only render MeProvider when user isOnboarded
      // hence has user and company is there
      // @ts-expect-error
      value={{ me: result.data.me, enabled: result.data.enabled || false }}
    >
      {children}
    </MeContext.Provider>
  )
}
