import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import { useTimeoutFn } from 'react-use'

export const LOCATION_BEFORE_REDIRECT_KEY = '__locationBeforeRedirect'

export const saveNavigateBackLocation = () => {
  localStorage.setItem(LOCATION_BEFORE_REDIRECT_KEY, window.location.pathname)
}

export const useNavigateBackToWhereFlowInitiated = () => {
  const navigate = useNavigate()
  return useCallback(
    (fallback: string) => {
      const locationBeforeRedirect = localStorage.getItem(LOCATION_BEFORE_REDIRECT_KEY)
      if (isNotEmptyOrNullish(locationBeforeRedirect)) {
        localStorage.removeItem(LOCATION_BEFORE_REDIRECT_KEY)
        navigate(locationBeforeRedirect)
      } else {
        navigate(fallback)
      }
    },
    [navigate],
  )
}

export const useCleanupRedirectBackTask = () => {
  useTimeoutFn(() => {
    localStorage.removeItem(LOCATION_BEFORE_REDIRECT_KEY)
  }, 10000)
}
