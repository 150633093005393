import { isNotEmptyOrNullish, isValidResult } from '@liveflow-io/utils-common'
import { useAtom } from 'jotai'
import {
  accountRadioButtonGroupSelectedAtom,
  balanceHistoryDateRangeAtom,
  currencyAtom,
} from './atoms'
import { Box, Skeleton, Stack } from '@chakra-ui/react'
import { Card, GenericError } from '@liveflow-io/component-common'
import { UnderscoredHeading } from './components/UnderscoredHeading'
import { DashboardDatePicker } from './components/DashboardDatePicker'
import React, { Suspense } from 'react'
import type { RangeFilterInput } from 'packlets/generated'
import { useAtomValue } from 'jotai/utils'
import { useQuery } from 'urql'
import { BalanceGraph_BalanceSectionDocument } from './documents.generated'
import { DashboardGraph } from './DashboardGraph'
import { AccountRadioButtonGroup } from './AcccountsRadioButtonGroup'
import { dayJsRangeToStringRange } from 'packlets/utils'

export const BalanceHistorySection = () => {
  const [dateRange, setDateRange] = useAtom(balanceHistoryDateRangeAtom)

  return (
    <Stack as={Card} w="full" h="435px">
      <Stack direction="row" align="center" justify="space-between" spacing={8}>
        <UnderscoredHeading size="md">
          Historical cash trend by account
        </UnderscoredHeading>
        <Stack
          justify="right"
          align={{ base: 'flex-end', lg: 'unset' }}
          direction={{ base: 'column', lg: 'row' }}
        >
          <Suspense fallback={<Skeleton h="40px" w="145px" />}>
            <AccountRadioButtonGroup />
          </Suspense>
          <DashboardDatePicker value={dateRange} onChange={(it) => setDateRange(it)} />
        </Stack>
      </Stack>
      <Suspense fallback={<Skeleton height="320px" mt={4} />}>
        <BalanceGraph range={dayJsRangeToStringRange(dateRange)} />
      </Suspense>
    </Stack>
  )
}
const BalanceGraph = ({ range }: { range: RangeFilterInput }) => {
  const accountId = useAtomValue(accountRadioButtonGroupSelectedAtom)
  const currency = useAtomValue(currencyAtom)
  const [balanceResult] = useQuery({
    query: BalanceGraph_BalanceSectionDocument,
    variables: {
      input: {
        currency,
        filters: {
          range,
          accountIds: isNotEmptyOrNullish(accountId) ? [accountId] : [],
        },
      },
    },
  })

  if (!isValidResult(balanceResult)) return <GenericError />
  return (
    <Box w="full">
      <DashboardGraph data={balanceResult.data.balance.history} />
    </Box>
  )
}
