import {
  buildNamespacedKeyBuilder,
  buildAtomFamilyKeyBuilder,
  buildFamilyGettersAndSetters,
  PersistenceService,
} from 'packlets/services'
import { DATE_RANGE_FAMILY } from './atomFamiles'

const PROFIT_LOSS_PER_ENTITY = 'profitLoss.perEntity'
const namespacedKeyBuilder = buildNamespacedKeyBuilder(PROFIT_LOSS_PER_ENTITY)

export const ENTITY_SELECT = 'entitySelect'
export const FIRST_DATE_RANGE = 'firstDateRange'
export const SECOND_DATE_RANGE = 'secondDateRange'

const dateRangeFamilyKeyBuilder = buildAtomFamilyKeyBuilder(
  PROFIT_LOSS_PER_ENTITY,
  DATE_RANGE_FAMILY,
)

const keys = {
  ENTITY_SELECT: namespacedKeyBuilder(ENTITY_SELECT),
  FIRST_DATE_RANGE_SELECTOR: dateRangeFamilyKeyBuilder(FIRST_DATE_RANGE),
  SECOND_DATE_RANGE_SELECTOR: dateRangeFamilyKeyBuilder(SECOND_DATE_RANGE),
}

export const ProfitLossPerEntityPersistence = PersistenceService.buildPersistenceService(
  PROFIT_LOSS_PER_ENTITY,
  keys,
  {
    periodDateRange: buildFamilyGettersAndSetters(dateRangeFamilyKeyBuilder),
  },
)
