export const Routes = {
  AUTH_REDIRECT: '/authRedirect',
  TRUELAYER_REDIRECT: '/truelayerRedirect',
  QUICKBOOKS_REDIRECT: '/quickbooksRedirect',
  XERO_REDIRECT: '/xeroRedirect',
  SIGN_IN: '/signIn',
  ONBOARDING: '/onboarding',
  BANK_DASHBOARD: '/bankDashboard',
  INTEGRATIONS: '/integrations',
  INVITE: '/invite',
  SETTINGS: '/settings',
  NOT_ENABLED: '/notEnabled',
  PROFIT_LOSS_PER_ENTITY: '/profitLoss/perEntity',
  CONSOLIDATED_PROFIT_LOSS_BY_ENTITY: '/profitLoss/consolidatedByEntity',
  PROFIT_LOSS_PER_DEPARTMENT: '/profitLoss/perDepartment',
  CONSOLIDATED_PROFIT_LOSS_BY_DEPARTMENT: '/profitLoss/consolidatedByDepartment',
} as const
