import React from 'react'
import { Card } from '@liveflow-io/component-common'
import { Flex, Heading, Image, Stack, Text } from '@chakra-ui/react'

export const IntegrationCard = ({
  logo,
  button,
  description,
  heading,
}: {
  logo: string
  button: React.ReactNode
  description: string
  heading: string
}) => (
  <Card
    w="250px"
    as={Stack}
    alignItems="center"
    spacing={4}
    justifyContent="space-between"
  >
    <Flex alignItems="center" justifyContent="center" h="full">
      <Image src={logo} maxHeight="75px" />
    </Flex>
    <Stack alignItems="center">
      <Heading as="h1" size="md">
        {heading}
      </Heading>
      <Text textAlign="center">{description}</Text>
      {button}
    </Stack>
  </Card>
)
