import type { UseCheckboxProps, BoxProps } from '@chakra-ui/react'
import {
  Box,
  HStack,
  Text,
  useCheckbox,
  useColorModeValue,
  useId,
} from '@chakra-ui/react'
import * as React from 'react'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'

const CheckboxBox = (props: BoxProps) => (
  <Box
    borderWidth="2px"
    px="4"
    py="3"
    borderRadius="md"
    cursor="pointer"
    transition="all 0.2s"
    _focus={{ shadow: 'outline' }}
    _checked={{
      bg: useColorModeValue('gray.50', 'whiteAlpha.100'),
      borderColor: useColorModeValue('blue.500', 'blue.300'),
    }}
    {...props}
  />
)

interface CheckboxButtonProps extends UseCheckboxProps {
  icon: React.ReactElement
  title: string
  description?: string
  rightSide?: string
}

export const CheckboxButton = (props: CheckboxButtonProps) => {
  const { icon, title, description, rightSide, ...rest } = props
  const { getCheckboxProps, getInputProps, getLabelProps, state } = useCheckbox(rest)
  const id = useId()

  return (
    <label {...getLabelProps()}>
      <input {...getInputProps()} aria-labelledby={id} />
      <CheckboxBox {...getCheckboxProps()} id={id}>
        <HStack spacing="4">
          <Box
            data-checked={state.isChecked ? '' : undefined}
            fontSize="2xl"
            _checked={{
              color: useColorModeValue('blue.500', 'blue.300'),
            }}
            color={useColorModeValue('gray.300', 'whiteAlpha.400')}
          >
            {state.isChecked ? <MdCheckBox /> : <MdCheckBoxOutlineBlank />}
          </Box>
          <Box fontSize="3xl">{icon}</Box>
          <Box flex="1">
            <Text fontWeight="bold">{title}</Text>
            {isNotEmptyOrNullish(description) && <Text fontSize="sm">{description}</Text>}
          </Box>
          <Box fontWeight="bold" color={useColorModeValue('blue.600', 'blue.400')}>
            {rightSide}
          </Box>
        </HStack>
      </CheckboxBox>
    </label>
  )
}
