import { useRef } from 'react'
import { isDeepEqual } from 'react-use/lib/util'

export function useDeepMemo<TKey, TValue>(memoFn: () => TValue, key: TKey): TValue {
  const ref = useRef<{ key: TKey; value: TValue }>()

  if (!ref.current || !isDeepEqual(key, ref.current.key)) {
    ref.current = { key, value: memoFn() }
  }

  return ref.current.value
}
