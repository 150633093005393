import { atom } from 'jotai'
import type React from 'react'
import { utcDayJs } from '@liveflow-io/utils-common'
import { atomFamily, useAtomValue } from 'jotai/utils'
import { useMemo } from 'react'
import { BankDashboardPersistence } from './persistence'
import { persistedDateRangeAtom, persistedJsonAtom } from 'packlets/atoms'
import { getFromParamsOrLocalStorage } from 'packlets/utils'

// To defer rendering of last updated info to after it's fetched
export const lastUpdatedAtom = atom<React.ReactNode>(null)
// To defer rendering of currency selector
export const currencySelectorAtom = atom<React.ReactNode>(null)

export const currencyAtom = persistedJsonAtom(
  BankDashboardPersistence.CURRENCY_SELECT,
  'USD',
)

export type AccountsSelected = string[] | undefined

export const accountsSelectedAtomFamily = atomFamily<
  string,
  AccountsSelected,
  AccountsSelected
>(
  (familyName) =>
    atom(
      getFromParamsOrLocalStorage<string[] | undefined>(
        BankDashboardPersistence.accountsSelected.getKey(familyName),
        undefined,
      ),
      (_, set, update) => {
        BankDashboardPersistence.accountsSelected.set(familyName, update)
        set(accountsSelectedAtomFamily(familyName), update)
      },
    ),
  (a, b) => a === b,
)

export type AccountsSelectorState = {
  isInitialized: boolean
  initialAccountIds?: string[]
}

export const accountsSelectorStateAtomFamily = atomFamily<
  string,
  AccountsSelectorState,
  AccountsSelectorState
>(
  (familyName) =>
    atom(
      getFromParamsOrLocalStorage<AccountsSelectorState>(
        BankDashboardPersistence.accountsSelectorState.getKey(familyName),
        {
          isInitialized: false,
        },
      ),
      (_, set, update) => {
        BankDashboardPersistence.accountsSelectorState.set(familyName, update)
        set(accountsSelectorStateAtomFamily(familyName), update)
      },
    ),
  (a, b) => a === b,
)

export const balanceHistoryDateRangeAtom = persistedDateRangeAtom(
  BankDashboardPersistence.BALANCE_HISTORY_RANGE,
  [utcDayJs().subtract(3, 'month'), utcDayJs()],
)

export const useSerializeDateRangeAtom = (
  anAtom: ReturnType<typeof persistedDateRangeAtom>,
) => {
  const [start, end] = useAtomValue(anAtom)
  return useMemo(() => JSON.stringify([start.formatYyyyMmDd(), end.formatYyyyMmDd()]), [
    start,
    end,
  ])
}

export const accountRadioButtonGroupSelectedAtom = persistedJsonAtom<string | undefined>(
  BankDashboardPersistence.RADIO_BUTTON_GROUP_SELECTED_ACCOUNT,
  undefined,
)
