import { Box, useColorModeValue, Text, Stack, Flex, Spinner } from '@chakra-ui/react'

import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { GenericError } from '@liveflow-io/component-common'
import { isNotEmptyOrNullish, isValidResult } from '@liveflow-io/utils-common'
import { Routes } from 'packlets/constants'
import React, { Suspense } from 'react'
import { Navigation } from 'packlets/components'
import type { Company } from 'packlets/generated'
import { MeProvider } from 'packlets/providers'
import { SecuredApp_UserDocument } from './documents.generated'
import { useQuery } from 'urql'

export const SecuredApp = () => {
  const [meResult] = useQuery({
    query: SecuredApp_UserDocument,
    requestPolicy: 'cache-and-network',
  })
  const bg = useColorModeValue('white', 'gray.800')
  const location = useLocation()

  if (!isValidResult(meResult)) return <GenericError />

  if (location.pathname === '/') {
    return <Navigate to={Routes.BANK_DASHBOARD} />
  }

  const user = meResult.data.me
  const company = user?.company
  const isOnboarded = user?.isOnboarded
  const isEnabled = meResult.data.enabled
  if (isOnboarded && isEnabled && company && isNotEmptyOrNullish(user?.email)) {
    return (
      <MeProvider result={meResult}>
        <>
          <Box>
            <Navigation company={company as Company} />
          </Box>
          <Suspense
            fallback={
              <Flex
                justifyContent="center"
                alignItems="center"
                w="100vw"
                h="100vh"
                borderLeft={['0px', '0px', '256px solid']}
              >
                <Spinner size="xl" />
              </Flex>
            }
          >
            <Stack
              bg={bg}
              p="6"
              borderLeft={['0px', '0px', '256px solid']}
              h="full"
              justifyContent="space-between"
              spacing="8"
              minHeight={['full', 'full', '100vh']}
            >
              <Outlet />
              <Text fontSize="xs" color="gray.400" textAlign="center" mt={[2, 4]}>
                We encrypt your data at rest and in transit. We never modify or share your
                valuable financial data internally or externally due to our native
                database encryption and secure authentication. LiveFlow is secured with
                HTTPS, which uses TLS and certificates to ensure that your data is
                encrypted when transmitted between our services.
              </Text>
            </Stack>
          </Suspense>
        </>
      </MeProvider>
    )
  }
  if (isEnabled && !isOnboarded) {
    return <Navigate to={Routes.ONBOARDING} />
  }
  return <Navigate to={Routes.NOT_ENABLED} />
}
