import { impossibleState, isValidResult } from '@liveflow-io/utils-common'
import {
  Divider,
  Heading,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { GenericError } from '@liveflow-io/component-common'
import type { PlaidButtonProps } from 'packlets/components'
import { PlaidButton, QuickBooksButton, XeroButton } from 'packlets/components'
import { INTEGRATION_EVENTS, TrackingService } from 'packlets/services'
import React, { Suspense, useCallback } from 'react'
import { useIntegrationsQuery } from 'packlets/queries'
import { PlaidCountryCode } from 'packlets/generated'
import xero from './xero-logo.svg'
import qb from './qb-logo.svg'
import bank from './bank-icon.svg'
import bankWhite from './white-bank-logo.svg'
import { IntegrationsTable } from './IntegrationsTable'
import { IntegrationCard } from './IntegrationCard'

export const IntegrationsPage = () => {
  const onPublicTokenSubmitted = useCallback<
    NonNullable<PlaidButtonProps['onPublicTokenSubmitted']>
  >((response) => {
    switch (response.state) {
      case 'error':
        TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_FAILED)
        break
      case 'partial':
        break
      case 'done': {
        TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_SUCCESS)
        break
      }
      default:
        impossibleState(response)
    }
  }, [])

  const onBankIntegrationStarted = useCallback(() => {
    TrackingService.track(INTEGRATION_EVENTS.INTEGRATED_BANK_STARTED)
  }, [])

  const bankLogo = useColorModeValue(bank, bankWhite)

  return (
    <Stack spacing={[4, 8]}>
      <Heading as="h1" size="lg">
        Integrations
      </Heading>
      <Divider />
      <Heading as="h2" size="md">
        Accounting integrations
      </Heading>
      <Stack direction={{ md: 'row' }} spacing={4}>
        <IntegrationCard
          heading="Quickbooks Online"
          description="Connect to Quickbooks Online to integrate your accounting data."
          logo={qb}
          button={<QuickBooksButton colorScheme="blue" w="full" mx={2} />}
        />
        <IntegrationCard
          heading="Xero"
          description="Connect to Xero to integrate your accounting data."
          logo={xero}
          button={<XeroButton colorScheme="blue" w="full" mx={2} />}
        />
      </Stack>
      <Heading as="h2" size="md">
        Banking integrations
      </Heading>
      <Stack direction={{ md: 'row' }} spacing={4}>
        <IntegrationCard
          heading="US Banks"
          description="Connect securely to +12,000 US Banks via. Plaid"
          logo={bankLogo}
          button={
            <PlaidButton
              buttonText="Connect"
              countryCode={PlaidCountryCode.Us}
              colorScheme="blue"
              w="full"
              mx={2}
              onPublicTokenSubmitted={onPublicTokenSubmitted}
              onClick={onBankIntegrationStarted}
            />
          }
        />
        <IntegrationCard
          heading="Canadian Banks"
          description="Connect securely to Major Candaian Banks via. Plaid"
          logo={bankLogo}
          button={
            <PlaidButton
              buttonText="Connect"
              countryCode={PlaidCountryCode.Ca}
              colorScheme="blue"
              w="full"
              mx={2}
              onPublicTokenSubmitted={onPublicTokenSubmitted}
              onClick={onBankIntegrationStarted}
            />
          }
        />
      </Stack>
      <Heading as="h1" size="md">
        My integrations
      </Heading>
      <Suspense fallback={<Skeleton height="200px" />}>
        <IntegrationsList />
      </Suspense>
    </Stack>
  )
}

const IntegrationsList = () => {
  const [integrationsResult] = useIntegrationsQuery()
  if (!isValidResult(integrationsResult)) return <GenericError />
  const { integrations } = integrationsResult.data
  return integrations.length !== 0 ? (
    <IntegrationsTable integrations={integrations} />
  ) : (
    <Text>No installed integrations</Text>
  )
}
