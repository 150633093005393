import type { Dayjs } from 'dayjs'
import { useMemo } from 'react'
import { generateRangesByUnit } from '@liveflow-io/utils-common'

export const usePredefinedDateRanges = ({
  startDate,
  endDate,
}: {
  startDate: Dayjs
  endDate: Dayjs
}) => {
  return useMemo(
    () =>
      Object.fromEntries(
        Object.entries({
          ...generateRangesByUnit({
            startDate,
            endDate,
            unit: 'quarter',
          }),
          ...generateRangesByUnit({
            startDate,
            endDate,
            unit: 'half',
          }),
          ...generateRangesByUnit({
            startDate,
            endDate,
            unit: 'year',
          }),
        }).sort(),
      ),
    [startDate, endDate],
  )
}
