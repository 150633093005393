import { Flex, Stack, Text } from '@chakra-ui/react'
import { Card } from '@liveflow-io/component-common'
import { QuickBooksButton } from 'packlets/components'
import React from 'react'

export const NoAccountancyIntegrations = () => {
  return (
    <Flex align="center" justify="center" h="full" w="full">
      <Card as={Stack} spacing={4}>
        <Text>
          It seems that you dont have any accountancy connected. Please add them using
          button below
        </Text>
        <QuickBooksButton colorScheme="blue" />
      </Card>
    </Flex>
  )
}
