import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Spinner } from '@chakra-ui/react'
import { uniq } from 'lodash'
import { useToast } from '@liveflow-io/hooks-common'
import { TrackingService, SIGN_IN_EVENTS } from 'packlets/services'
import { Routes } from 'packlets/constants'
import { AuthService } from 'services/AuthService'

export const AuthRedirect = () => {
  const navigate = useNavigate()
  const toast = useToast()
  useEffect(() => {
    AuthService.parseHash(
      {},
      {
        onError: (error) => {
          navigate(Routes.SIGN_IN)
          const errorMessage = uniq(
            [
              error?.description,
              error?.error_description,
              error?.errorDescription,
            ].filter(Boolean),
          ).join(' | ')
          if (errorMessage === '`state` does not match.') {
            return
          }
          toast({
            title: 'Oh no! Something went wrong!',
            description: errorMessage,
            status: 'error',
          })
          TrackingService.track(SIGN_IN_EVENTS.AUTH_FAILED, error)
        },
        onSuccess: () => {
          TrackingService.track(SIGN_IN_EVENTS.AUTH_SUCCESS)
          navigate(Routes.BANK_DASHBOARD)
        },
      },
    )
  })

  return (
    <Flex justifyContent="center" alignItems="center" w="100vw" h="100vh">
      <Spinner size="xl" />
    </Flex>
  )
}
