import type { Dayjs } from 'dayjs'
import type { ProfitLossQueries } from '../components'
import { profitLossEngineAtom } from '../components'
import { useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'
import { ATOM_FAMILY } from './atomFamiles'
import { isNotEmptyOrNullish } from '@liveflow-io/utils-common'
import type { DateRange } from 'packlets/atoms'

export function useHandleProiftLossQueryChange(
  selectedIntegrationId: string,
  fourMonthsBeforeRecent: Dayjs,
  threeMonthsBeforeRecent: Dayjs,
  firstDateRange: DateRange,
  secondDateRange: DateRange,
) {
  const queries: ProfitLossQueries = useMemo(
    () => ({
      inputFirst: {
        input: {
          filter: {
            integrationIds: [selectedIntegrationId],
          },
          range: {
            from: fourMonthsBeforeRecent.startOf('month').toGraphQLDate(),
            to: fourMonthsBeforeRecent.endOf('month').toGraphQLDate(),
          },
        },
      },
      inputSecond: {
        input: {
          filter: {
            integrationIds: [selectedIntegrationId],
          },
          range: {
            from: threeMonthsBeforeRecent.startOf('month').toGraphQLDate(),
            to: threeMonthsBeforeRecent.endOf('month').toGraphQLDate(),
          },
        },
      },
      inputThird: {
        input: {
          filter: {
            integrationIds: [selectedIntegrationId],
          },
          range: {
            from: firstDateRange[0].toGraphQLDate(),
            to: firstDateRange[1].toGraphQLDate(),
          },
        },
      },
      inputFourth: {
        input: {
          filter: {
            integrationIds: [selectedIntegrationId],
          },
          range: {
            from: secondDateRange[0].toGraphQLDate(),
            to: secondDateRange[1].toGraphQLDate(),
          },
        },
      },
    }),
    [
      selectedIntegrationId,
      fourMonthsBeforeRecent,
      threeMonthsBeforeRecent,
      firstDateRange,
      secondDateRange,
    ],
  )

  const [, setProfitLossEngineState] = useAtom(profitLossEngineAtom({ id: ATOM_FAMILY }))

  useEffect(() => {
    isNotEmptyOrNullish(selectedIntegrationId) &&
      setProfitLossEngineState((it) => ({
        ...it,
        queries,
      }))
  }, [selectedIntegrationId, queries, setProfitLossEngineState])
}
