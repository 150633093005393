import React, { Suspense, useEffect } from 'react'
import { BankDashboardPage2_CompanyIntegrationsInfoDocument } from './documents.generated'
import { Card, GenericError } from '@liveflow-io/component-common'
import {
  Box,
  Divider,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Select,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { IntegrateBankButton } from 'packlets/components'
import { useQuery } from 'urql'
import { useInterval, useCopyToClipboard } from 'react-use'
import { useUpdateAtom } from 'jotai/utils'
import { SELECT_CURRENCIES } from 'packlets/constants'
import { currencyAtom, currencySelectorAtom, lastUpdatedAtom } from './atoms'
import { BanksSection, TotalBalanceSection } from './BanksSection'
import { BalanceHistorySection } from './BalanceHistorySection'
import { isNotNullish, utcDayJs, isValidResult } from '@liveflow-io/utils-common'
import { FaRegCheckCircle } from 'react-icons/fa'
import { BankDashboardPersistence } from './persistence'
import { useToast } from '@liveflow-io/hooks-common'
import {
  buildCurrentUrlWithParams,
  getValuesFromLocalStorageAndConvertToUrlParams,
} from 'packlets/utils'

export const BankDashboardPage = () => {
  return (
    <BankDashboardShell>
      <Suspense fallback={<Skeleton height="full" />}>
        <HasBanks />
      </Suspense>
    </BankDashboardShell>
  )
}

export const HasBanks = () => {
  const [meResult, refetchMe] = useQuery({
    query: BankDashboardPage2_CompanyIntegrationsInfoDocument,
  })

  useInterval(() => {
    refetchMe({
      requestPolicy: 'network-only',
    })
  }, 5000)

  if (!isValidResult(meResult)) return <GenericError />

  if (meResult.data?.me?.company?.companyIntegrationsInfo.hasAnyBankIntegration)
    return <BankDashboard />

  return <BankDashboardNoBankIntegrated />
}

const BankDashboardShell: React.FC = ({ children }) => {
  const [lastUpdated, setLastUpdated] = useAtom(lastUpdatedAtom)
  const [currencySelector, setCurrencySelector] = useAtom(currencySelectorAtom)
  const toast = useToast()

  useEffect(() => {
    return () => {
      void setCurrencySelector(null)
      void setLastUpdated(null)
    }
  }, [setCurrencySelector, setLastUpdated])

  const [, copy] = useCopyToClipboard()

  return (
    <Box h="full">
      <Flex direction={{ base: 'column', lg: 'row' }} justifyContent="space-between">
        <Stack direction="row" spacing={12} width="full" alignItems="center">
          <Heading as="h1" size="lg">
            Bank dashboard
          </Heading>
          {isNotNullish(currencySelector) && (
            <Button
              onClick={() => {
                toast({
                  status: 'info',
                  title: 'Great, URL copied to clipboard!',
                  description:
                    'Feel free share it with your colleagues and please note they should be logged in.',
                })
                copy(
                  buildCurrentUrlWithParams(
                    getValuesFromLocalStorageAndConvertToUrlParams(
                      BankDashboardPersistence.keys,
                    ),
                  ),
                )
              }}
            >
              Share this view
            </Button>
          )}
          <Stack direction="row" spacing={1} alignItems="center">
            {isNotNullish(currencySelector) && currencySelector}
          </Stack>
        </Stack>
        {lastUpdated}
      </Flex>
      <Divider mt={8} />
      <Stack spacing={6} mt={10}>
        {children}
      </Stack>
    </Box>
  )
}

const BankDashboardNoBankIntegrated = () => {
  return (
    <Flex alignItems="center" justifyContent="center" h="full" w="full">
      <Card as={Stack} spacing={4}>
        <Text>
          It seems that you dont have any banks integrated. Please add them using button
          below
        </Text>
        <IntegrateBankButton colorScheme="blue" />
      </Card>
    </Flex>
  )
}

const BankDashboard = () => {
  const setCurrencySelector = useUpdateAtom(currencySelectorAtom)
  const setLastUpdatedAtom = useUpdateAtom(lastUpdatedAtom)
  const [selectedCurrency, setCurrency] = useAtom(currencyAtom)
  useEffect(() => {
    void setCurrencySelector(() => (
      <FormControl
        id="dashboard-currency"
        as={Stack}
        direction="row"
        w="auto"
        value={selectedCurrency}
        alignItems="center"
      >
        <FormLabel m={0}>Dashboard currency:</FormLabel>
        <Select
          w="auto"
          defaultValue={selectedCurrency}
          onChange={(e) => {
            void setCurrency(e.target.value)
          }}
        >
          {SELECT_CURRENCIES.map((it) => {
            return (
              <option key={it} value={it}>
                {it}
              </option>
            )
          })}
        </Select>
      </FormControl>
    ))
  }, [selectedCurrency, setCurrency, setCurrencySelector])
  const greenColor = useColorModeValue('green.500', 'green.300')

  useEffect(() => {
    void setLastUpdatedAtom(() => (
      <Text flex="none" m="auto">
        <strong>
          <Icon as={FaRegCheckCircle} pb={1} color={greenColor} /> Last updated:
        </strong>{' '}
        {utcDayJs().formatYyyyMmDd()}
      </Text>
    ))
  }, [greenColor, setLastUpdatedAtom])
  return (
    <Stack spacing={8}>
      <Stack
        direction={{ base: 'column', '2xl': 'row' }}
        spacing={8}
        alignItems="flex-start"
      >
        <Stack spacing={8} w="full">
          <TotalBalanceSection />
          <BanksSection />
        </Stack>
        <BalanceHistorySection />
      </Stack>
    </Stack>
  )
}
