import { isValidResult } from '@liveflow-io/utils-common'
import type { TextProps } from '@chakra-ui/react'
import { Text } from '@chakra-ui/react'
import { useAtomValue } from 'jotai/utils'
import { accountsSelectedAtomFamily, currencyAtom } from '../atoms'
import { useQuery } from 'urql'
import { TotalBalance_BalanceDocument } from '../documents.generated'
import { GenericError } from '@liveflow-io/component-common'
import { renderMoney } from '@liveflow-io/utils-common'
import type { Money } from 'packlets/generated'
import React from 'react'

type TotalBalanceProps = TextProps & { atomFamilyKey: string }

export const TotalBalance = ({ atomFamilyKey, ...props }: TotalBalanceProps) => {
  const currency = useAtomValue(currencyAtom)
  const accountsIds = useAtomValue(accountsSelectedAtomFamily(atomFamilyKey))
  const [totalSelectedBalanceResult] = useQuery({
    query: TotalBalance_BalanceDocument,
    variables: {
      input: {
        currency,
        filters: {
          accountIds: accountsIds ?? [],
        },
      },
    },
  })
  if (!isValidResult(totalSelectedBalanceResult)) return <GenericError />

  return (
    <Text {...props}>
      {renderMoney(
        totalSelectedBalanceResult.data.banks.reduce<Money>(
          (acc, it) => {
            acc.amount += it.totalBalance.amount
            acc.currency = it.totalBalance.currency
            return acc
          },
          { amount: 0, currency: 'USD' },
        ),
      )}
    </Text>
  )
}
