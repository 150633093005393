import type { UrqlOperationResultStates } from '@liveflow-io/hooks-common'
import { useEnhancedMutation, useToast } from '@liveflow-io/hooks-common'
import {
  DeleteUser_InvitePageDocument,
  DeleteUserInvite_InvitePageDocument,
} from './documents.generated'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { atom, useAtom } from 'jotai'
import React, { useCallback, useRef } from 'react'
import { impossibleState, isNotEmptyOrNullish } from '@liveflow-io/utils-common'

export const deleteInviteOrUserAtom = atom<{
  type?: 'user' | 'invite'
  id?: string
}>({})

export const DeleteInviteOrUser = () => {
  const [, deleteUser] = useEnhancedMutation(DeleteUser_InvitePageDocument)
  const [, deleteUserInvite] = useEnhancedMutation(DeleteUserInvite_InvitePageDocument)

  const {
    isOpen: isConfirmationOpen,
    onOpen: onOpenConfirmation,
    onClose: onCloseConfirmation,
  } = useDisclosure()
  const toast = useToast()

  const [{ id, type }, setState] = useAtom(deleteInviteOrUserAtom)

  const onCloseDeleteModal = useCallback(() => {
    void setState({})
  }, [setState])

  const onDelete = useCallback<React.FormEventHandler>(
    (e) => {
      e.preventDefault()
      if (isNotEmptyOrNullish(id) && isNotEmptyOrNullish(type)) {
        const promise =
          type === 'user'
            ? deleteUser({
                input: {
                  userId: id,
                },
              })
            : deleteUserInvite({
                input: {
                  inviteId: id,
                },
              })

        promise
          .then((res: UrqlOperationResultStates<any, any>) => {
            switch (res.state) {
              case 'partial':
              case 'done':
                void setState({
                  type,
                })
                onOpenConfirmation()
                break
              case 'error':
                toast({
                  description: `There was some problem deleting your ${type}`,
                  status: 'error',
                  title: 'Something went wrong',
                })
                break
              default:
                impossibleState(res)
            }
            return res
          })
          .catch(console.error)
      }
    },
    [setState, deleteUserInvite, deleteUser, type, id, onOpenConfirmation, toast],
  )

  const onCloseDeleteConfirmation = useCallback(() => {
    onCloseConfirmation()
    void setState({})
  }, [setState, onCloseConfirmation])

  const cancelDeleteRef = useRef(null)
  const closeConfirmationRef = useRef(null)

  return (
    <>
      <AlertDialog
        isOpen={isNotEmptyOrNullish(id) && isNotEmptyOrNullish(type)}
        leastDestructiveRef={cancelDeleteRef}
        onClose={onCloseDeleteModal}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Delete {type}?</AlertDialogHeader>
          <AlertDialogBody>Are you sure you want to delete this {type}?</AlertDialogBody>

          <AlertDialogFooter as={Stack} spacing={2} direction="row">
            <Button colorScheme="red" onClick={onDelete}>
              Yes
            </Button>
            <Button ref={cancelDeleteRef} onClick={onCloseDeleteModal}>
              No
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog
        isOpen={isConfirmationOpen}
        leastDestructiveRef={closeConfirmationRef}
        onClose={onCloseDeleteConfirmation}
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Your {type} has been deleted</AlertDialogHeader>
          <AlertDialogBody>You can always re-invite them</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={closeConfirmationRef} onClick={onCloseDeleteConfirmation}>
              Close
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}
