import { isValidResult } from '@liveflow-io/utils-common'
import React, { useCallback, useEffect, useState } from 'react'
import { Box, Button, Grid, SlideFade, Text } from '@chakra-ui/react'
import { FixedThemeToggleButton, GenericError } from '@liveflow-io/component-common'
import { FaDoorOpen } from 'react-icons/fa'
import { impossibleState } from '@liveflow-io/utils-common'
import { ONBOARDING_EVENTS, TrackingService } from 'packlets/services'
import { Navigate } from 'react-router-dom'
import { Routes } from 'packlets/constants'
import { AuthService } from 'services/AuthService'
import { CompanyForm } from './CompanyForm'
import { UserForm } from './UserForm'
import type {
  OnboardingPage_CompanyFragment,
  OnboardingPage_UserFragment,
} from './documents.generated'
import { OnboardingPage_UserDocument } from './documents.generated'
import { useQuery } from 'urql'

export const OnboardingPage = () => {
  const [meResult] = useQuery({
    query: OnboardingPage_UserDocument,
    requestPolicy: 'network-only',
  })
  if (!isValidResult(meResult)) {
    return <GenericError />
  }
  const me = meResult.data.me
  if (me?.isOnboarded) {
    return <Navigate to={Routes.BANK_DASHBOARD} />
  }
  return <Onboarding me={meResult.data.me} />
}

type OnboardingStates =
  | { state: 'user-step' }
  | { state: 'company-step'; user: OnboardingPage_UserFragment }
  | { state: 'user-onboarded'; company: OnboardingPage_CompanyFragment }

const Onboarding = ({ me }: { me?: OnboardingPage_UserFragment | null }) => {
  useEffect(() => {
    TrackingService.track(ONBOARDING_EVENTS.ONBOARDING_STARTED)
  }, [])

  const calculateCurrentState = useCallback((): OnboardingStates => {
    switch (me) {
      case undefined:
      case null:
        return { state: 'user-step' }
      default: {
        switch (me.company) {
          case undefined:
          case null:
            return { state: 'company-step', user: me }
          default:
            return {
              company: me.company,
              state: 'user-onboarded',
            }
        }
      }
    }
  }, [me])

  const [onboardingState, setOnboardingState] = useState<OnboardingStates>(
    calculateCurrentState(),
  )

  useEffect(() => {
    setOnboardingState(calculateCurrentState())
  }, [calculateCurrentState])

  switch (onboardingState.state) {
    case 'user-onboarded':
      return null
    case 'user-step':
    case 'company-step':
      return (
        <Grid w="100vw" h="100vh" alignItems="center" justifyItems="center">
          <FixedThemeToggleButton />
          <Box position="fixed" right={4} top={4}>
            <Button pl={2} pr={2} onClick={AuthService.logout} fontSize="lg">
              <FaDoorOpen /> <Text ml={2}>Logout</Text>
            </Button>
          </Box>
          <SlideFade in>
            {onboardingState.state === 'company-step' ? <CompanyForm /> : <UserForm />}
          </SlideFade>
        </Grid>
      )
    default:
      return impossibleState(onboardingState)
  }
}
