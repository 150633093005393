import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  Stack,
  Th,
} from '@chakra-ui/react'
import {
  isEmptyOrNullish,
  isNotEmptyOrNullish,
  utcDayJs,
} from '@liveflow-io/utils-common'
import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react'
import { PNL_EVENTS, TrackingService } from 'packlets/services'
import { useCompletedNativeAccountingIntegrations } from 'packlets/hooks'
import { useDeepCompareEffect } from 'react-use'
import { downloadCsv } from 'packlets/utils'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { secondDateRangeAtom, firstDateRangeAtom, selectedEntityAtom } from './atoms'
import { DownloadIcon } from '@chakra-ui/icons'
import type { Dayjs } from 'dayjs'
import {
  HasIntegrations,
  ProfitLossMonthRangePicker,
  ProfitLossShell,
  ProfitLossTable,
} from '../components'
import { csvButtonState } from '../atoms'
import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from 'packlets/queries'
import { useHandleConnectedToInfoRendering } from '../hooks'
import { useHandleProiftLossQueryChange } from './hooks'
import { useAtom } from 'jotai'
import { ATOM_FAMILY } from './atomFamiles'
import { ProfitLossPerEntityPersistence } from './persistence'

type AccountingIntegrationPair = { name: string; integrationId: string }

export const ProfitLossPerEntity = () => {
  return (
    <ProfitLossShell
      heading="P&L - By single entity"
      persistenceService={ProfitLossPerEntityPersistence}
    >
      <Suspense fallback={<Skeleton height="full" />}>
        <HasIntegrations>
          <PerEntity />
        </HasIntegrations>
      </Suspense>
    </ProfitLossShell>
  )
}

const PerEntity = () => {
  const csvData = useAtomValue(csvButtonState)
  const [accountingIntegrationPairs, setAccountingIntegrationPairs] = useState<
    AccountingIntegrationPair[]
  >([])
  const [selectedIntegrationId, setSelectedIntegrationId] = useAtom(selectedEntityAtom)

  const [nativeIntegrationsResult] = useCompletedNativeAccountingIntegrations()
  const nativeIntegrations = nativeIntegrationsResult.data?.integrations
  useHandleConnectedToInfoRendering(
    nativeIntegrations as
      | CommonIntegrations_CommonAccountingIntegrationPayloadFragment[]
      | undefined,
  )

  useDeepCompareEffect(() => {
    if (isNotEmptyOrNullish(nativeIntegrations)) {
      const anAccountingIntegrationPairs = nativeIntegrations.map<AccountingIntegrationPair>(
        (it) => ({
          name: it.name ?? '',
          integrationId: it?.integrationId ?? '',
        }),
      )
      setAccountingIntegrationPairs(anAccountingIntegrationPairs)
      isEmptyOrNullish(selectedIntegrationId) &&
        setSelectedIntegrationId(anAccountingIntegrationPairs[0].integrationId)
    }
  }, [nativeIntegrations])

  const onAccountSelect: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      TrackingService.track(PNL_EVENTS.SELECT_ACCOUNT)
      void setSelectedIntegrationId(e.target.value)
    },
    [setSelectedIntegrationId],
  )

  const now = useMemo(() => utcDayJs(), [])

  const [firstDateRange, setFirstDateRange] = useAtom(firstDateRangeAtom)
  const [secondDateRange, setSecondDateRange] = useAtom(secondDateRangeAtom)

  const threeMonthsBeforeRecent = useMemo(() => now.subtract(2, 'month'), [now])
  const fourMonthsBeforeRecent = useMemo(() => now.subtract(3, 'month'), [now])

  useHandleProiftLossQueryChange(
    selectedIntegrationId,
    fourMonthsBeforeRecent,
    threeMonthsBeforeRecent,
    firstDateRange,
    secondDateRange,
  )

  const csvHeaders = useMemo(() => {
    return [
      '',
      fourMonthsBeforeRecent.format('MMM YYYY'),
      threeMonthsBeforeRecent.format('MMM YYYY'),
      `${firstDateRange[0].format('MMM YYYY')} - ${firstDateRange[1].format('MMM YYYY')}`,
      `${secondDateRange[0].format('MMM YYYY')} - ${secondDateRange[1].format(
        'MMM YYYY',
      )}`,
    ]
  }, [firstDateRange, secondDateRange, fourMonthsBeforeRecent, threeMonthsBeforeRecent])

  const setCsvButtonState = useUpdateAtom(csvButtonState)

  useEffect(() => {
    void setCsvButtonState((it) => ({
      ...it,
      headers: csvHeaders,
    }))
  }, [csvHeaders, setCsvButtonState])

  return (
    <Stack spacing={8}>
      <Stack
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
        spacing={[2, 4, 8]}
      >
        {accountingIntegrationPairs.length > 1 ? (
          <FormControl id="account-selector" w={300}>
            <FormLabel>Selected account:</FormLabel>
            <Select value={selectedIntegrationId} onChange={onAccountSelect}>
              {accountingIntegrationPairs.map((it) => (
                <option key={it.integrationId} value={it.integrationId}>
                  {it.name}
                </option>
              ))}
            </Select>
          </FormControl>
        ) : (
          <div />
        )}
        <Button
          leftIcon={<DownloadIcon />}
          onClick={() => {
            downloadCsv({
              ...csvData,
              showLabels: true,
              filename: 'Profit and Loss',
            })
          }}
        >
          Export to CSV/Excel
        </Button>
      </Stack>
      <Suspense fallback={<Skeleton h="400px" />}>
        {isNotEmptyOrNullish(selectedIntegrationId) && (
          <ProfitLossTable
            header={
              <>
                <Th>{fourMonthsBeforeRecent.format('MMMM YYYY')}</Th>
                <Th>{threeMonthsBeforeRecent.format('MMMM YYYY')}</Th>
                <Th>
                  <ProfitLossMonthRangePicker
                    value={firstDateRange}
                    // @ts-expect-error
                    onChange={([startDate, endDate]: [Dayjs, Dayjs]) => {
                      TrackingService.track(PNL_EVENTS.DATE_FILTER_CHANGED)
                      void setFirstDateRange([
                        startDate.startOf('month'),
                        endDate.endOf('month'),
                      ])
                    }}
                  />
                </Th>
                <Th>
                  <ProfitLossMonthRangePicker
                    value={secondDateRange}
                    // @ts-expect-error
                    onChange={([startDate, endDate]) => {
                      TrackingService.track(PNL_EVENTS.DATE_FILTER_CHANGED)
                      void setSecondDateRange([
                        startDate.startOf('month'),
                        endDate.endOf('month'),
                      ])
                    }}
                  />
                </Th>
              </>
            }
            familyId={ATOM_FAMILY}
          />
        )}
      </Suspense>
    </Stack>
  )
}
