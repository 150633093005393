import {
  Button,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  Stack,
  Text,
  Th,
} from '@chakra-ui/react'
import { isNotEmptyOrNullish, isNotNullish } from '@liveflow-io/utils-common'
import React, { Suspense, useCallback, useEffect, useMemo } from 'react'
import { CONSOLIDATED_PNL_EVENTS, TrackingService } from 'packlets/services'
import { useAllNativeAccountingIntegrations } from 'packlets/hooks'
import { downloadCsv } from 'packlets/utils'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import {
  consolidatedDepartmentsStateAtom,
  selectedCurrencyAtom,
  selectedRangeAtom,
} from './atoms'
import { DownloadIcon } from '@chakra-ui/icons'
import { useQuery } from 'urql'
import { ConsolidatedDepartmentsProfitLoss_CategoriesDocument } from './documents.generated'
import { useAtom } from 'jotai'
import { csvButtonState } from '../atoms'
import { SELECT_CURRENCIES } from 'packlets/constants'
import { groupBy } from 'lodash'
import {
  HasIntegrations,
  ProfitLossMonthRangePicker,
  ProfitLossShell,
  ProfitLossTable,
} from '../components'
import { SelectDepartmentsButton } from './SelectDepartmentsButton'
import { useApplyProfitLossEngineQueryChanges } from './hooks'
import { ATOM_FAMILY } from './atomFamilies'
import { useHandleConnectedToInfoRendering } from '../hooks'
import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from 'packlets/queries'
import { ProfitLossConsolidatedDepartmentsPersistence } from './persistence'

export const ConsolidatedDepartmentsProfitLoss = () => {
  return (
    <ProfitLossShell
      heading="P&L - All departments"
      persistenceService={ProfitLossConsolidatedDepartmentsPersistence}
    >
      <Suspense fallback={<Skeleton height="full" />}>
        <HasIntegrations>
          <ConsolidatedDepartments />
        </HasIntegrations>
      </Suspense>
    </ProfitLossShell>
  )
}

const ConsolidatedDepartments = () => {
  const csvData = useAtomValue(csvButtonState)

  const [nativeIntegrationsResult] = useAllNativeAccountingIntegrations()
  const nativeIntegrations = nativeIntegrationsResult.data?.integrations

  const [categoriesResult] = useQuery({
    query: ConsolidatedDepartmentsProfitLoss_CategoriesDocument,
  })
  const departments = categoriesResult.data?.profitLossCategories

  useHandleConnectedToInfoRendering(
    nativeIntegrations as
      | CommonIntegrations_CommonAccountingIntegrationPayloadFragment[]
      | undefined,
  )

  const [selectedCurrency, setCurrency] = useAtom(selectedCurrencyAtom)
  const [selectedRange, setRange] = useAtom(selectedRangeAtom)

  const onCurrencyChange: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (e) => {
      TrackingService.track(CONSOLIDATED_PNL_EVENTS.CURRENCY_CHANGED)
      void setCurrency(e.target.value)
    },
    [setCurrency],
  )

  const [{ selectedDepartments, selectedEntities }] = useAtom(
    consolidatedDepartmentsStateAtom,
  )
  useApplyProfitLossEngineQueryChanges(selectedCurrency, selectedRange)

  const csvHeaders = useMemo(() => {
    const depsIdsGroupedByName = groupBy(selectedDepartments, 'name')
    return [
      '',
      ...Object.keys(depsIdsGroupedByName),
      isNotEmptyOrNullish(selectedEntities) && 'Consolidated',
    ].filter((it): it is string => isNotNullish(it))
  }, [selectedDepartments, selectedEntities])

  const setCsvButtonState = useUpdateAtom(csvButtonState)

  useEffect(() => {
    void setCsvButtonState((it) => ({
      ...it,
      headers: csvHeaders,
    }))
  }, [csvHeaders, setCsvButtonState])

  return (
    <Stack spacing={8}>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
        <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
          <Stack w={300}>
            <Text>Change period:</Text>
            <ProfitLossMonthRangePicker
              borderRadius={8}
              size="large"
              value={selectedRange}
              // @ts-expect-error
              onChange={([from, to]) => {
                TrackingService.track(CONSOLIDATED_PNL_EVENTS.DATE_FILTER_CHANGED)
                isNotNullish(from) &&
                  isNotNullish(to) &&
                  setRange([from.startOf('month'), to.endOf('month')])
              }}
            />
          </Stack>
          {isNotEmptyOrNullish(nativeIntegrations) &&
            isNotEmptyOrNullish(departments) && (
              <SelectDepartmentsButton
                integrations={nativeIntegrations}
                departments={departments}
              />
            )}
        </Stack>
        <Stack direction="row" alignItems="flex-end">
          <FormControl id="consolidated-currency" w={300}>
            <FormLabel>Currency:</FormLabel>
            <Select value={selectedCurrency} onChange={onCurrencyChange}>
              {SELECT_CURRENCIES.map((it) => {
                return (
                  <option key={it} value={it}>
                    {it}
                  </option>
                )
              })}
            </Select>
          </FormControl>
          <Button
            leftIcon={<DownloadIcon />}
            onClick={() => {
              downloadCsv({
                ...csvData,
                showLabels: true,
                filename: 'Profit and Loss',
              })
            }}
          >
            Export to CSV/Excel
          </Button>
        </Stack>
      </Stack>
      {isNotEmptyOrNullish(nativeIntegrations) && (
        <Suspense fallback={<Skeleton h="400px" />}>
          <ProfitLossTable
            header={
              <>
                {Object.keys(groupBy(selectedDepartments, 'name'))?.map((it) => (
                  <Th key={it}>{it}</Th>
                ))}
                {isNotEmptyOrNullish(selectedEntities) && <Th>Total</Th>}
              </>
            }
            familyId={ATOM_FAMILY}
          />
        </Suspense>
      )}
    </Stack>
  )
}
