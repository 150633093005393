import {
  buildNamespacedKeyBuilder,
  buildAtomFamilyKeyBuilder,
  buildFamilyGettersAndSetters,
  PersistenceService,
} from 'packlets/services'
import { DATE_RANGE_FAMILY } from './atomFamiles'

const PROFIT_LOSS_PER_DEPARTMENT = 'profitLoss.perDepartment'
const namespacedKeyBuilder = buildNamespacedKeyBuilder(PROFIT_LOSS_PER_DEPARTMENT)

export const CURRENCY_SELECT = 'сurrencySelect'
export const DEPARTMENT_SELECT = 'departmentSelect'
export const FIRST_DATE_RANGE = 'firstDateRange'
export const SECOND_DATE_RANGE = 'secondDateRange'

const dateRangeFamilyKeyBuilder = buildAtomFamilyKeyBuilder(
  PROFIT_LOSS_PER_DEPARTMENT,
  DATE_RANGE_FAMILY,
)

const keys = {
  CURRENCY_SELECT: namespacedKeyBuilder(CURRENCY_SELECT),
  DEPARTMENT_SELECT: namespacedKeyBuilder(DEPARTMENT_SELECT),
  FIRST_DATE_RANGE_SELECTOR: dateRangeFamilyKeyBuilder(FIRST_DATE_RANGE),
  SECOND_DATE_RANGE_SELECTOR: dateRangeFamilyKeyBuilder(SECOND_DATE_RANGE),
}

export const ProfitLossPerDepartmentPersistence = PersistenceService.buildPersistenceService(
  PROFIT_LOSS_PER_DEPARTMENT,
  keys,
  {
    periodDateRange: buildFamilyGettersAndSetters(dateRangeFamilyKeyBuilder),
  },
)
