import type { CommonIntegrations_IntegrationPayloadFragment } from 'packlets/queries'
import type { ConsolidatedDepartmentsProfitLoss_CategoriesFragment } from './documents.generated'
import { atomWithReducer, selectAtom } from 'jotai/utils'
import produce from 'immer'
import { uniq } from 'lodash'
import { impossibleState, utcDayJs } from '@liveflow-io/utils-common'
import { isDeepEqual } from 'react-use/lib/util'
import { getFromParamsOrLocalStorage } from 'packlets/utils'
import { ProfitLossConsolidatedDepartmentsPersistence } from './persistence'
import { persistedDateRangeAtom, persistedJsonAtom } from 'packlets/atoms'

type ConsolidatedDepartmentsActions =
  | {
      type: 'init'
      payload: {
        integrations: CommonIntegrations_IntegrationPayloadFragment[]
        departments: ConsolidatedDepartmentsProfitLoss_CategoriesFragment[]
      }
    }
  | {
      type: 'new-entities'
      newEntities: CommonIntegrations_IntegrationPayloadFragment[]
    }
  | {
      type: 'new-departments'
      newDepartments: ConsolidatedDepartmentsProfitLoss_CategoriesFragment[]
    }
  | { type: 'update-departments-selection'; selectedDepartmentsNames: string[] }
  | { type: 'update-entities-selected'; selectedEntityIds: string[] }
  | { type: 'submit-selection' }

type ConsolidatedDepartmentsState = {
  /** To understand whether whole state should be initialized from scratch  */
  isInitialized: boolean
  /** What entities are now selected (applied on submit, more global)  */
  selectedEntities: CommonIntegrations_IntegrationPayloadFragment[]
  /** What departments are now selected (applied on submit, more global)  */
  selectedDepartments: ConsolidatedDepartmentsProfitLoss_CategoriesFragment[]
  /** What department names are now selected  */
  selectedDepartmentsNames: string[]
  /** What integrations are now selected  */
  selectedEntityIds: string[]
  /** Data for init and certain calculations  */
  departments: ConsolidatedDepartmentsProfitLoss_CategoriesFragment[]
  /** Options to render + certain calculations */
  integrations: CommonIntegrations_IntegrationPayloadFragment[]
  /** Options to render   */
  departmentsNames: string[]
}

const consolidatedDepartmentsStateReducer = (
  state: ConsolidatedDepartmentsState,
  action: ConsolidatedDepartmentsActions,
) => {
  switch (action.type) {
    case 'init': {
      return {
        ...state,
        ...action.payload,
        isInitialized: true,
      }
    }
    case 'new-entities':
      return produce(state, (draft) => {
        draft.selectedEntities = []
        draft.selectedDepartments = []
        draft.selectedDepartmentsNames = []
        draft.departmentsNames = []
        draft.selectedEntityIds = []
        draft.integrations = action.newEntities
      })
    case 'new-departments':
      return produce(state, (draft) => {
        draft.selectedDepartments = []
        draft.selectedEntityIds = []
        draft.selectedDepartmentsNames = []
        // render only departments of selected entities
        draft.departmentsNames = uniq(
          action.newDepartments
            .filter((it) => draft.selectedEntityIds.includes(it.integrationId))
            .map((it) => it.name),
        )
        draft.departments = action.newDepartments
      })
    case 'update-departments-selection':
      return produce(state, (draft) => {
        draft.selectedDepartmentsNames = action.selectedDepartmentsNames
      })
    case 'update-entities-selected':
      return produce(state, (draft) => {
        draft.selectedEntityIds = action.selectedEntityIds
        // Change options to render based on entities selected
        const deptsOptionsToRender = uniq(
          draft.departments
            .filter((it) => action.selectedEntityIds.includes(it.integrationId))
            .map((it) => it.name),
        )
        draft.departmentsNames = deptsOptionsToRender
        // Remove all selectedNames that are no longer in the departmentsNames list
        draft.selectedDepartmentsNames = draft.selectedDepartmentsNames.filter((name) =>
          deptsOptionsToRender.includes(name),
        )
      })
    case 'submit-selection':
      return produce(state, (draft) => {
        // Select departments by selected department name
        draft.selectedDepartments = draft.departments.filter((department) =>
          draft.selectedDepartmentsNames.includes(department.name),
        )
        // Select entities based on selected ids
        draft.selectedEntities = draft.integrations.filter((integrations) =>
          draft.selectedEntityIds.includes(integrations.integrationId),
        )
      })
    default:
      return impossibleState(action)
  }
}

export const consolidatedDepartmentsStateAtom = atomWithReducer(
  getFromParamsOrLocalStorage(
    ProfitLossConsolidatedDepartmentsPersistence.CONSOLIDATED_DEPARTMENTS_PAGE_STATE,
    {
      isInitialized: false,
      selectedEntities: [],
      selectedDepartments: [],
      selectedEntityIds: [],
      selectedDepartmentsNames: [],
      integrations: [],
      departments: [],
      departmentsNames: [],
    } as ConsolidatedDepartmentsState,
  ),
  (state, action: ConsolidatedDepartmentsActions) => {
    const newState = consolidatedDepartmentsStateReducer(state, action)
    ProfitLossConsolidatedDepartmentsPersistence.setConsolidatedDepartmentsPageState(
      newState,
    )
    return newState
  },
)

export const selectedEntitiesAtom = selectAtom(
  consolidatedDepartmentsStateAtom,
  (state) => state.selectedEntities,
  isDeepEqual,
)

export const selectedDepartmentsAtom = selectAtom(
  consolidatedDepartmentsStateAtom,
  (state) => state.selectedDepartments,
  isDeepEqual,
)

export const selectedRangeAtom = persistedDateRangeAtom(
  ProfitLossConsolidatedDepartmentsPersistence.PERIOD_DATE_RANGE_SELECTOR,
  [utcDayJs().startOf('month'), utcDayJs().endOf('month')],
)

export const selectedCurrencyAtom = persistedJsonAtom(
  ProfitLossConsolidatedDepartmentsPersistence.CURRENCY_SELECT,
  'USD',
)
