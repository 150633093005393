import type { CommonIntegrations_CommonAccountingIntegrationPayloadFragment } from 'packlets/queries'
import { useUpdateAtom } from 'jotai/utils'
import { connectedToInfoRenderAtom } from '../atoms'
import { useDeepCompareEffect } from 'react-use'
import { isNotEmptyOrNullish, utcDayJs } from '@liveflow-io/utils-common'
import { Text } from '@chakra-ui/react'
import { IntegrationSource } from 'packlets/generated'
import { lowerCase, upperFirst } from 'lodash'
import React from 'react'

export const useHandleConnectedToInfoRendering = (
  nativeIntegrations?: CommonIntegrations_CommonAccountingIntegrationPayloadFragment[],
) => {
  const setConnectedToInfoRender = useUpdateAtom(connectedToInfoRenderAtom)
  useDeepCompareEffect(() => {
    if (isNotEmptyOrNullish(nativeIntegrations)) {
      void setConnectedToInfoRender(() => {
        return (
          <Text>
            Connected to{' '}
            {[
              ...new Set(
                nativeIntegrations.map((it) =>
                  it.integrationSource === IntegrationSource.QuickBooks
                    ? 'Quickbooks'
                    : upperFirst(lowerCase(it.integrationSource)),
                ),
              ),
            ].join(' and ')}{' '}
            - <strong>Last updated:</strong> {utcDayJs().format('MMMM D, YYYY')}
          </Text>
        )
      })
    }
  }, [nativeIntegrations])
}
