import {
  isNotEmptyOrNullish,
  isValidResult,
  renderMoney,
} from '@liveflow-io/utils-common'
import { useQuery } from 'urql'
import type { AccountSelector_BankFragment } from './documents.generated'
import { AccountSelector_BanksDocument } from './documents.generated'
import type { UseRadioGroupProps } from '@chakra-ui/react'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import { useAtom } from 'jotai'
import {
  accountRadioButtonGroupSelectedAtom,
  accountsSelectorStateAtomFamily,
} from './atoms'
import React, { useEffect, useMemo, useState } from 'react'
import { isEqual, upperFirst } from 'lodash'
import { GenericError } from '@liveflow-io/component-common'
import { ButtonRadioGroup } from 'packlets/components'
import { BALANCE_HISTORY_FAMILY } from './atomFamilies'

const AccountSelectorGroup = ({
  banks,
  value,
  onChange,
}: {
  banks: AccountSelector_BankFragment[]
  value: string
  onChange: UseRadioGroupProps['onChange']
}) => {
  return (
    <ModalBody as={Stack}>
      <ButtonRadioGroup
        value={value}
        onChange={onChange}
        options={banks.flatMap((bank) => {
          return bank.accounts.map((account) => {
            return {
              value: account.id,
              icon: <div />,
              label: `${bank.name} - ${upperFirst(account.type.toLowerCase())}`,
              title: `${bank.name} - ${upperFirst(account.type.toLowerCase())}`,
              rightSide: renderMoney(account.balance),
            }
          })
        })}
      />
    </ModalBody>
  )
}

export const AccountRadioButtonGroup = () => {
  const [banksResult] = useQuery({
    query: AccountSelector_BanksDocument,
  })

  const { isOpen, onClose, onOpen } = useDisclosure()
  const [accountSelected, setAccountSelected] = useAtom(
    accountRadioButtonGroupSelectedAtom,
  )
  const [{ initialAccountIds, isInitialized }, setAccountsSelectorState] = useAtom(
    accountsSelectorStateAtomFamily(BALANCE_HISTORY_FAMILY),
  )
  const [accountSelectorValue, setAccountSelectorValue] = useState<string>()

  const allAccountIds = useMemo(() => {
    return banksResult.data?.banks?.flatMap((it) => it.accounts).map((it) => it.id)
    // Hack to ensure memo fires ONLY on operation key change (meaning new result came)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banksResult.operation?.key])

  useEffect(() => {
    if (
      !isInitialized ||
      (allAccountIds != null && !isEqual(initialAccountIds, allAccountIds))
    ) {
      setAccountSelectorValue(allAccountIds?.[0])
      void setAccountsSelectorState({
        isInitialized: true,
        initialAccountIds: allAccountIds,
      })
      void setAccountSelected(allAccountIds?.[0])
    } else {
      setAccountSelectorValue(accountSelected)
    }
  }, [
    accountSelected,
    allAccountIds,
    initialAccountIds,
    isInitialized,
    setAccountSelected,
    setAccountsSelectorState,
  ])

  if (!isValidResult(banksResult)) return <GenericError />

  return (
    <>
      <Button variant="ghost" onClick={onOpen} colorScheme="blue">
        Select accounts
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select accounts</ModalHeader>
          <ModalCloseButton />
          {isNotEmptyOrNullish(accountSelectorValue) && banksResult.data.banks && (
            <AccountSelectorGroup
              banks={banksResult.data.banks}
              value={accountSelectorValue}
              onChange={(value) => setAccountSelectorValue(value)}
            />
          )}
          <ModalFooter>
            <Button
              mr={3}
              colorScheme="blue"
              onClick={() => {
                onClose()
                void setAccountSelected(accountSelectorValue)
              }}
            >
              Apply
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
