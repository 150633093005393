export const SELECT_CURRENCIES = [
  'USD',
  'GBP',
  'EUR',
  'CHF',
  'CAD',
  'NZD',
  'AUD',
  'JPY',
  'CNY',
  'SEK',
  'KRW',
  'SGD',
  'NOK',
  'MXN',
  'PLN',
  'RUB',
]
